import Checkbox from '@components/shared/checkbox/Checkbox';
import { IClientBounceField, IClientBounceFieldOption } from '@shared/helpers/converters/bouncefield.ts';
import { IClientDocType, IClientDocTypeSubType } from '@shared/helpers/converters/doctype.ts';
import { IClientFieldType } from '@shared/helpers/converters/fieldtype.ts';
import { IRawMasterdataTable } from '@shared/helpers/converters/masterdata.ts';
import { IClientMetadata } from '@shared/helpers/converters/metadata.ts';
import { IClientTag } from '@shared/helpers/converters/tag.ts';
import { adminSlice } from '@shared/store/adminSlice';
import { useDispatch } from '@shared/store/store';
import s from '@shared/styles/component/admin/admin-item-row.module.scss';
import { ReactComponent as CopyIcon } from '@svg/copy-icon.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React from 'react';

interface Props {
  title: string;
  docType?: IClientDocType;
  subType?: IClientDocTypeSubType;
  entityType?: IClientFieldType;
  metadataType?: IClientMetadata;
  actionType?: IClientBounceField;
  tagType?: IClientTag;
  masterdataTable?: IRawMasterdataTable;
  actionTypeOption?: IClientBounceFieldOption;
  handleDelete?: () => void;
  handleNav: () => void;
  handleCopy?: () => void;
  isChecked?: boolean;
  setIsChecked?: (newState: boolean) => void;
  animationSettings?: {
    enabled: boolean;
    delay: number;
  };
}

const AdminItemRow: React.FC<Props> = ({
  title,
  docType,
  subType,
  entityType,
  metadataType,
  actionType,
  actionTypeOption,
  masterdataTable,
  tagType,
  handleDelete,
  handleNav,
  handleCopy,
  isChecked,
  setIsChecked,
  animationSettings,
}) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (docType) {
      dispatch(adminSlice.actions.setActiveDocTypeId(docType.id));
    } else if (entityType) {
      dispatch(adminSlice.actions.setActiveEntityTypeId(entityType.id));
    } else if (metadataType) {
      dispatch(adminSlice.actions.setActiveMetadataTypeId(metadataType.id));
    } else if (subType) {
      dispatch(adminSlice.actions.setActiveSubTypeId(subType.id));
    } else if (actionType) {
      dispatch(adminSlice.actions.setActiveActionTypeId(actionType.id));
    } else if (actionTypeOption) {
      dispatch(adminSlice.actions.setActiveActionTypeOption(actionTypeOption));
    } else if (tagType) {
      dispatch(adminSlice.actions.setActiveTagTypeId(tagType.id));
    } else if (masterdataTable) {
      dispatch(adminSlice.actions.setActiveMasterdataId(masterdataTable.id));
    }
    handleNav();
  };

  return (
    <div
      data-testid="admin-item-row"
      className={s.wrapper}
      style={animationSettings ? { animationDelay: `${animationSettings.delay}ms` } : null}
    >
      <div onClick={handleClick} className={s.container}>
        {setIsChecked ? (
          <div
            className={s.checkbox}
            onClick={(e) => {
              e.stopPropagation();
              setIsChecked(!isChecked);
            }}
          >
            <Checkbox
              checked={isChecked}
              onClick={(e) => {
                e.preventDefault();
                setIsChecked(!isChecked);
                e.stopPropagation();
              }}
            />
          </div>
        ) : (
          <div className={s.checkbox} />
        )}
        <div className={s.title}>
          <span>{title}</span>

          {tagType && <div className={s.color_dot} style={{ background: tagType.color }} />}
        </div>
      </div>
      <div className={s.actions}>
        {handleCopy && (
          <button type={'button'} className={s.action} onClick={handleCopy}>
            <CopyIcon />
          </button>
        )}

        {(entityType || docType || subType || metadataType || actionType || actionTypeOption || tagType) &&
          handleDelete && (
            <button
              data-testid={'admin-item-row-delete'}
              className={clsx(s.action, s.trash)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete();
              }}
            >
              <TrashIcon />
            </button>
          )}
      </div>
    </div>
  );
};

export default AdminItemRow;
