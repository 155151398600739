import { endpointRawToClient } from '@shared/helpers/converters/endpoint.ts';
import { webhookRawToClient } from '@shared/helpers/converters/webhook.ts';
import { sleep } from '@shared/helpers/helpers.ts';
import { useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin.module.scss';
import { ReactComponent as ConnectorBrioIcon } from '@svg/connector-brio.svg';
import { ReactComponent as ConnectorCCSIcon } from '@svg/connector-ccs.svg';
import { ReactComponent as ConnectorM365Icon } from '@svg/connector-ms365.svg';
import { ReactComponent as ConnectorRestIcon } from '@svg/connector-rest.svg';
import { ReactComponent as ConnectorFTPIcon } from '@svg/connector-sftp.svg';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router';

const AdminWebhooks: React.FC = () => {
  const webhooks = useSelector((state) => state.admin.webhooks);
  const endpoints = useSelector((state) => state.admin.endpoints);
  const webhookServerSideValues = useSelector((state) => state.admin.webhookServerSideValues);
  const connectors = useSelector((state) => state.admin.connectors);
  const webhookRef = useRef();
  const { webhookId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Mapping connector types to their icons
  const connectorIcons: { [key: string]: JSX.Element } = {
    sftp: <ConnectorFTPIcon />,
    http: <ConnectorRestIcon />,
    ms365: <ConnectorM365Icon />,
    portimabrio: <ConnectorBrioIcon />,
    ccs: <ConnectorCCSIcon />,
  };

  const convertedWebhooks = useMemo(() => {
    if (!webhooks || !endpoints) return [];
    const convertedEndpoints = endpoints.map((endpoint) =>
      endpointRawToClient(endpoint, connectors, webhookServerSideValues),
    );
    const converted = webhooks
      .map((wh) => webhookRawToClient(wh, convertedEndpoints))
      .sort((a, b) => a.endpoint.connector.type.localeCompare(b.endpoint.connector.type));
    return converted;
  }, [webhooks, endpoints, connectors, webhookServerSideValues]);

  useEffect(() => {
    if (convertedWebhooks?.length > 0 && webhookId !== 'new') {
      if (webhookRef.current) {
        const activeWebhookItem = webhookRef.current as HTMLDivElement;
        sleep(1).then(() => {
          const sidebar = activeWebhookItem.closest('nav') as HTMLElement;
          if (sidebar) {
            sidebar.scrollTo({
              top: activeWebhookItem.offsetTop - sidebar.offsetTop,
              behavior: 'smooth',
            });
          }
        });
      }
    }
  }, [webhooks, webhookId]);

  useEffect(() => {
    if (!webhookId && webhooks) {
      if (convertedWebhooks.length > 0) {
        navigate(`${webhooks[0].id}`);
      } else {
        navigate('new');
      }
    }
  }, [convertedWebhooks, webhookId, navigate]);

  return (
    <div className={s.subcontent}>
      <div className={s.subsidebar}>
        <div className={s.header}>Webhooks</div>
        <NavLink to={'new'} className={s.add}>
          + {t('admin:webhooks.add')}
        </NavLink>
        <nav className={clsx(s.section)}>
          {(!convertedWebhooks || convertedWebhooks.length === 0) && (
            <Skeleton
              count={5}
              height={20}
              width={'calc(100% - 32px)'}
              style={{ marginBottom: 28, marginLeft: 16 }}
            />
          )}
          {convertedWebhooks?.map((webhook, i) => {
            const type = webhook.endpoint.connector.type;
            let ret = (
              <NavLink
                ref={webhookId === webhook.id ? webhookRef : null}
                key={webhook.id ?? 'new'}
                to={`${webhook.id}`}
                className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
              >
                {webhook.name}
                {connectorIcons[type]}
              </NavLink>
            );
            if (i !== 0 && type !== convertedWebhooks[i - 1].endpoint.connector.type) {
              ret = (
                <>
                  <div style={{ width: '100%', height: 1, background: '#eeeeee', margin: '6px 0' }} /> {ret}
                </>
              );
            }
            return ret;
          })}
        </nav>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminWebhooks;
