import StyledSelect, { DropdownOption } from '@components/shared/dropdown/StyledSelect.tsx';
import { IClientDocType } from '@shared/helpers/converters/doctype.ts';
import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React from 'react';

interface Props {
  data: {
    docType: string;
    data: {
      agenda_reason: string;
      agenda_create: string;
    };
  };
  inboxDocTypes: IClientDocType[];
  handleDelete: () => void;
  onChange: (data: { agenda_reason: string; agenda_create: string }) => void;
  metadataTypes: DropdownOption[];
}

const AdminCCSDoctypeRow: React.FC<Props> = ({
  data: { docType, data },
  inboxDocTypes,
  handleDelete,
  onChange,
  metadataTypes,
}) => {
  const currentDoctype = inboxDocTypes?.find((dt) => dt.id === docType);

  return (
    <div className={clsx(sr.wrapper, sr.wrapper__no_anim)}>
      <div data-testid={'sortable-field'} className={clsx(sr.container, sr.container__no_hover)}>
        <span className={sr.title}>{currentDoctype?.name}</span>
      </div>

      <div className={sr.actions}>
        <div style={{ minWidth: 200 }}>
          <div style={{ minWidth: 200 }}>
            <StyledSelect
              onChange={(v: any) => {
                onChange({ ...data, agenda_reason: v.value });
              }}
              options={metadataTypes}
              value={metadataTypes.find((o) => o.value === data.agenda_reason)}
              style={{ minHeight: 43, border: '1px solid #EEEEEE', marginTop: 3 }}
            />
          </div>
        </div>
        <div style={{ minWidth: 200 }}>
          <div style={{ minWidth: 200 }}>
            <StyledSelect
              onChange={(v: any) => {
                onChange({ ...data, agenda_create: v.value });
              }}
              options={metadataTypes}
              value={metadataTypes.find((o) => o.value === data.agenda_create)}
              style={{ minHeight: 43, border: '1px solid #EEEEEE', marginTop: 3 }}
            />
          </div>
        </div>

        <button
          data-testid={'admin-item-row-delete'}
          className={clsx(sr.action, sr.trash)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete();
          }}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default AdminCCSDoctypeRow;
