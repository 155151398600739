import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { IClientFieldType } from '@shared/helpers/converters/fieldtype.ts';
import { DocumentEntity, EntityTable } from '@shared/models/document';
import { UrlParams } from '@shared/models/generic';
import { addDocumentEntity, labelerSlice } from '@shared/store/labelerSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler-grid-panel.module.scss';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';
import { ReactComponent as HeadersOffIcon } from '@svg/headers-off-icon.svg';
import { ReactComponent as HeadersOnIcon } from '@svg/headers-on-icon.svg';
import { ReactComponent as ToolGridIcon } from '@svg/tool-grid-icon.svg';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import DocumentLabelerTableTypeSelector from './DocumentLabelerTableTypeSelector';

interface Props {
  updateEntity: (updatedEntity: DocumentEntity) => void;
  tableEntity?: DocumentEntity;
}

export type TableTypeOptions = Record<string, { type: IClientFieldType; mandatory: boolean }>;
const DocumentLabelerTablePanel: React.FC<Props> = ({ tableEntity, updateEntity }) => {
  const allowedEntityTypes = useSelector((state) => state.document.allowedEntityTypes);
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const selectedEntityTypeId = useSelector((state) => state.labeler.selectedEntityTypeId);
  const selectedCategoryId = useSelector((state) => state.labeler.selectedCategoryId);
  const tableEditActive = useSelector((state) => state.labeler.tableEditActive);
  const docTypeCategories = useSelector((state) => state.document.docTypeCategories);
  const activeEntityPair = useSelector((state) => state.labeler.activeEntityPair);
  const editingTableEntity = useSelector((state) => state.labeler.editingTableEntity);
  const tempEntity = useSelector((state) => state.labeler.tempEntity);

  const { inboxId }: UrlParams = useParams();
  const columnsRef = useRef(null);
  const sliderRef = useRef(null);
  const dispatch = useDispatch();

  const [currentEntityTable, setCurrentEntityTable] = useState<EntityTable>(
    tableEntity?.value as EntityTable,
  );

  useEffect(() => {
    if (editingTableEntity) {
      setCurrentEntityTable(editingTableEntity.value as EntityTable);
    } else if (tempEntity) {
      setCurrentEntityTable(tempEntity.value as EntityTable);
    } else if (tableEntity) {
      setCurrentEntityTable(tableEntity.value as EntityTable);
    } else {
      setCurrentEntityTable(null);
    }
  }, [editingTableEntity, tableEntity, tempEntity]);

  // const [table, setTable] = useState<EntityTable>();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [tableOptions, setTableOptions] = useState<TableTypeOptions>({});
  const [hasHeaders, setHasHeaders] = useState(true);
  const [panelHeight, setPanelHeight] = useState(400);

  const handleSliderMouseDown = (e: React.PointerEvent<HTMLDivElement>) => {
    const slider = sliderRef.current as HTMLDivElement;
    slider.onpointermove = handleSliderMouseMove;
    slider.setPointerCapture(e.pointerId);
  };
  const handleSliderMouseUp = (e: React.PointerEvent<HTMLDivElement>) => {
    const slider = sliderRef.current as HTMLDivElement;
    slider.onpointermove = null;
    slider.releasePointerCapture(e.pointerId);
  };
  const handleSliderMouseMove = (e) => {
    setPanelHeight(window.innerHeight - e.clientY);
  };
  const handleInput = (value: string, colIndex: string, rowIndex: string) => {
    const workableTable = cloneDeep(currentEntityTable);
    workableTable.columns[colIndex].cells[rowIndex].value = value;
    setCurrentEntityTable(workableTable);
  };

  //TODO : Handle table stuiff

  const handleSaveChanges = () => {
    updateEntity({ ...tableEntity, value: currentEntityTable });
    dispatch(labelerSlice.actions.setEditingTableEntity(null));
    dispatch(labelerSlice.actions.setActiveEntityPair(null));
  };
  const handleCreateNew = () => {
    const entity: DocumentEntity = { ...tableEntity, type: selectedEntityTypeId, value: currentEntityTable };

    if (selectedCategoryId) {
      const cat = docTypeCategories.find((dtc) => dtc.id === selectedCategoryId);
      if (cat) entity.categoryId = cat.id;
    }

    dispatch(addDocumentEntity(inboxId, entity));
    dispatch(labelerSlice.actions.setTempEntity(null));
  };

  useEffect(() => {
    if (tableEntity && allowedEntityTypes) {
      const typeId = tableEntity?.type === 'temp' ? selectedEntityTypeId : tableEntity.type;
      const et = allowedEntityTypes.find((e) => e.id === typeId);

      if (et?.tableDefinition) {
        const options = {};
        et.tableDefinition.entityTypes.forEach((option) => {
          const mapped = entityTypes.find((et) => et.id === option.id);
          if (mapped) {
            options[mapped.id] = { type: mapped, mandatory: option.mandatory };
          }
        });
        setTableOptions(options);
      }
    }
  }, [tableEntity, allowedEntityTypes, entityTypes, selectedEntityTypeId]);

  useEffect(() => {
    if (currentEntityTable?.columns) {
      const tableArr = Object.values(currentEntityTable.columns);
      const types = [...new Set(tableArr.map((e) => e.type))];
      setSelectedTypes(types);
    }
  }, [currentEntityTable]);

  useEffect(() => {
    if (tableEntity) {
      const tableValue = tableEntity.value as EntityTable;
      if (tableValue?.columns) {
        if (tableValue?.hasHeaders != null) {
          setHasHeaders(tableValue?.hasHeaders);
        }
      }
    }
  }, [tableEntity]);

  useEffect(() => {
    if (!activeEntityPair && !tempEntity) {
      dispatch(labelerSlice.actions.setTableEditActive(false));
    }
  }, [activeEntityPair, dispatch, tempEntity]);

  const mandatoryFields = useMemo(() => {
    // Check all table.columns and it's type and compare to tableOptions with mandatory = true
    const missing: IClientFieldType[] = [];
    if (tableOptions && currentEntityTable?.columns) {
      Object.values(tableOptions).forEach((opt) => {
        if (opt.mandatory) {
          const exists = Object.values(currentEntityTable.columns).find((c) => c.type === opt.type.id);
          if (!exists) missing.push(opt.type);
        }
      });
    }
    return missing;
  }, [currentEntityTable, tableOptions]);
  const hasMandatoryFieldsMissing = mandatoryFields.length > 0;

  return (
    <>
      {currentEntityTable && (
        <div
          ref={sliderRef}
          className={s.handle}
          style={{ bottom: panelHeight - 5 }}
          onPointerDown={handleSliderMouseDown}
          onPointerUp={handleSliderMouseUp}
        />
      )}
      <div
        className={s.wrapper}
        style={{
          minHeight: currentEntityTable ? panelHeight : 0,
          maxHeight: currentEntityTable ? panelHeight : 0,
        }}
      >
        <div className={s.bar}>
          <div className={s.buttons}>
            <button
              className={s.button}
              onClick={() => dispatch(labelerSlice.actions.setTableEditActive(!tableEditActive))}
            >
              <ToolGridIcon />
              {tableEditActive ? 'Confirm Changes' : 'Edit Table Structure'}
            </button>
            {!tableEditActive && (
              <>
                <button
                  className={s.button}
                  onClick={() => {
                    const ent = tableEntity.value as EntityTable;
                    const newEntity = {
                      ...tableEntity,
                      value: { ...ent, hasHeaders: !hasHeaders },
                    };
                    updateEntity(newEntity);
                    setHasHeaders(!hasHeaders);
                  }}
                >
                  {hasHeaders ? <HeadersOffIcon /> : <HeadersOnIcon />}
                  {hasHeaders ? 'Show 1st row' : 'Hide 1st row'}
                </button>
                {hasMandatoryFieldsMissing && (
                  <Tooltip
                    position={'top'}
                    alignment={'center'}
                    tooltipStyle={{ width: '100%' }}
                    content={
                      <div>
                        <b>Missing mandatory column(s):</b>
                        <div>
                          {mandatoryFields.map((field) => {
                            return <div key={field.name}>{field.name}</div>;
                          })}
                        </div>
                      </div>
                    }
                  >
                    <button className={clsx(s.button, s.disabled)}>
                      <CheckmarkIcon /> {tempEntity ? 'Create Table' : 'Save Changes'}
                    </button>
                  </Tooltip>
                )}
                {!hasMandatoryFieldsMissing && (
                  <button className={s.button} onClick={tempEntity ? handleCreateNew : handleSaveChanges}>
                    <CheckmarkIcon /> {tempEntity ? 'Create Table' : 'Save Changes'}
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        {currentEntityTable?.columns && (
          <div
            className={s.table}
            style={{ maxWidth: Object.entries(currentEntityTable.columns).length * 250 }}
          >
            <>
              <div className={s.headers}>
                {Object.entries(currentEntityTable.columns).map(([k, v]) => {
                  let selectedType = null;
                  if (v.type !== 'temp') {
                    selectedType = tableOptions[v.type]?.type;
                  }
                  const isHidden = currentEntityTable.hiddenIndexes?.col[k];
                  if (isHidden) return null;
                  return (
                    <div key={v.type + k} className={s.header}>
                      <DocumentLabelerTableTypeSelector
                        tableTypeOptions={tableOptions}
                        selectedType={selectedType}
                        selectedTypes={selectedTypes}
                        onChange={(type) => {
                          const workableTable = cloneDeep(currentEntityTable) as EntityTable;
                          const cols = Object.values(workableTable.columns);
                          const colWithTypeIndex = cols.findIndex((e) => e.type === type.id);
                          if (colWithTypeIndex !== -1) {
                            workableTable.columns[colWithTypeIndex] = {
                              ...workableTable.columns[colWithTypeIndex],
                              type: null,
                            };
                          }
                          workableTable.columns[k] = {
                            ...currentEntityTable.columns[k],
                            type: type.id,
                          };
                          setCurrentEntityTable(workableTable);
                          // set(workableTable);
                          // onChange(workableTable);
                          // handleConfirm(workableTable);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={s.columns} ref={columnsRef}>
                {Object.entries(currentEntityTable.columns).map(([ci, column]) => {
                  const columnEntries = Object.entries(column.cells);
                  const isHidden = currentEntityTable.hiddenIndexes?.col[ci];
                  if (isHidden) return null;
                  return (
                    <div className={s.column} style={{ height: 35 * columnEntries.length }} key={`C${ci}`}>
                      {columnEntries?.map(([ri, cell]) => {
                        const isHidden = currentEntityTable.hiddenIndexes?.row[ri];
                        if (isHidden) return null;
                        return (
                          <CSSTransition
                            key={`R${ri}C${ci}${cell.valueLocations[0].x1}${cell.valueLocations[0].y1}`}
                            timeout={300}
                            classNames="labeler-table"
                            unmountOnExit
                            in={!(hasHeaders && ri === '0')}
                          >
                            <input
                              onFocus={() => {
                                dispatch(
                                  labelerSlice.actions.setActiveCell({
                                    rowIndex: Number.parseInt(ri),
                                    colIndex: Number.parseInt(ci),
                                  }),
                                );
                              }}
                              onBlur={() => {
                                // handleConfirm();
                                dispatch(labelerSlice.actions.setActiveCell(null));
                              }}
                              onChange={(e) => handleInput(e.target.value, ci, ri)}
                              className={clsx(s.cell, {
                                [s.cell__error]: cell.value === null,
                              })}
                              value={cell.value}
                            />
                          </CSSTransition>
                        );
                      })}
                    </div>
                  );
                })}
                {/*<div className={s.column_hide_wrapper}>*/}
                {/*  {Object.entries(table.columns[1].cells).map(([k, v]) => {*/}
                {/*    if (hasHeaders && k === '0') return null;*/}
                {/*    return (*/}
                {/*      <div className={s.column__hide}>*/}
                {/*        <SettingDotsIcon />*/}
                {/*      </div>*/}
                {/*    );*/}
                {/*  })}*/}
                {/*</div>*/}
              </div>
            </>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentLabelerTablePanel;
