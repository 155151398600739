import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React from 'react';
import AdminMultiSelectDialog from '../AdminMultiSelectDialog.tsx';

interface Props {
  hidden?: boolean;
  title?: string;
  description?: string;
  value: any;
  onChange: any;
  options: { id: string; name: string; children?: any }[];
  showDialog: any;
}

const FormMultiSelectField: React.FC<Props> = ({
  hidden,
  title,
  description,
  value,
  onChange,
  options,
  showDialog,
}) => {
  if (hidden) return null;
  return (
    <div className={clsx(se.item, se.item__vertical)}>
      <div className={se.item_head}>
        <div className={se.item_text} style={{ marginBottom: 10 }}>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>

        {value && options && value?.length !== options?.length ? (
          <button
            type="button"
            className={se.item_field}
            style={{ maxWidth: 42, justifySelf: 'flex-end' }}
            onClick={() =>
              showDialog(
                <AdminMultiSelectDialog
                  title={title}
                  description={description}
                  selectedTypes={value ?? []}
                  handleCheckTypes={(e) => onChange(e)}
                  detailedList={options}
                />
              )
            }
          >
            <PlusIcon className={se.field_add} />
          </button>
        ) : (
          <div />
        )}
      </div>
      <div className={se.item_action}>
        <div className={se.item_fields}>
          {value?.map((opt) => {
            const details = options.find((o) => o.id === opt.id);
            const items = [];
            if (details?.children) {
              if (!opt.children) {
                items.push({ name: `${details?.name} / All Tables `, id: details.id });
              }
              opt?.children?.forEach((optChild) => {
                const childDetails = details.children.find((detailed) => detailed.id === optChild);
                items.push({
                  name: `${details?.name} / ${childDetails?.name}`,
                  id: childDetails.id,
                  parentId: opt.id,
                });
              });
            } else {
              items.push({ name: details?.name, id: details?.id });
            }
            return items.map((item) => {
              return (
                <div
                  key={item.name}
                  style={{ direction: 'ltr' }}
                  data-testid="sortable-item"
                  className={clsx(sr.wrapper, sr.wrapper__no_anim, { [sr.wrapper__small]: true })}
                >
                  <div className={sr.container}>
                    <span className={sr.title}>{item.name}</span>
                  </div>
                  <button
                    className={clsx(sr.action, sr.trash)}
                    data-testid={'sortable-item-delete'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (item.parentId) {
                        const valueItemIndex = value.findIndex((i) => i.id === item.parentId);
                        let valueItem = value[valueItemIndex];
                        const newChildren = valueItem?.children.filter((e) => e !== item.id);
                        if (newChildren.length === 0) {
                          onChange(value.filter((i) => i.id !== item.parentId));
                        } else {
                          valueItem = { ...valueItem, children: newChildren };
                          value[valueItemIndex] = valueItem;
                          onChange(value);
                        }
                      } else {
                        onChange(value.filter((i) => i.id !== item.id));
                      }
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default FormMultiSelectField;
