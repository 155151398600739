import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getUserToken, isPbxEmail } from '@shared/helpers/helpers';
import { Inbox } from '@shared/models/inbox';
import { UserAccount, UserPreferences } from '@shared/models/user';
import camelcaseKeys from 'camelcase-keys';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { api, auth, db } from './setup/firebase-setup';
import { AppThunk } from './store';

interface UserState {
  userAccount?: UserAccount;
  intercomHash?: string;
  inboxes?: Inbox[];
  isAuthPopupActive?: boolean;
  emailPreferenceSet?: boolean;
  isTenantInvalid?: boolean;
}

const initialState: UserState = {
  userAccount: {
    inboxes: [],
    email: null,
    isAdmin: false,
  },

  inboxes: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setIsAuthPopupActive: (state, action: PayloadAction<boolean>) => {
      state.isAuthPopupActive = action.payload;
    },

    setIsTenantInvalid: (state, action: PayloadAction<boolean>) => {
      state.isTenantInvalid = action.payload;
    },

    setEmailPreferenceSet: (state, action: PayloadAction<boolean>) => {
      state.emailPreferenceSet = action.payload;
    },

    setUserAccount: (state, action: PayloadAction<UserAccount>) => {
      state.userAccount = action.payload;
    },

    setInboxes: (state, action: PayloadAction<Inbox[]>) => {
      state.inboxes = action.payload;
    },
    setIntercomHash: (state, action: PayloadAction<string>) => {
      state.intercomHash = action.payload;
    },
  },
});
export const getInboxes = (inboxIds: string[]): AppThunk => {
  return async (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    // Create an array of references to the inbox documents
    const refs = inboxIds.map((id) => doc(db, `tenants/${tenantId}/inboxes/${id}`));

    // Fetch all documents in parallel using Promise.all
    const docs = await Promise.all(refs.map((ref) => getDoc(ref)));

    // Process the retrieved documents
    const localInboxes = docs
      .map((res, index) => {
        const inbox = camelcaseKeys(res.data(), { deep: true }) as Inbox;
        if (inbox) {
          inbox.id = inboxIds[index];
          return inbox;
        }
        return null;
      })
      .filter((inbox) => inbox !== null) as Inbox[];
    // Dispatch the processed inboxes
    dispatch(userSlice.actions.setInboxes(localInboxes));
  };
};

export const patchUserPreferences = (preferences: UserPreferences) => async () => {
  const b = await getUserToken();
  if (!b) return;
  return await api.patch(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/users/me/preferences`, preferences, {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${b}`,
    },
  });
};

export const getUserAccount =
  (userId: string): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    const ref = doc(db, `tenants/${tenantId}/users/${userId}`);
    let userExists = false;

    const unsubscribe = onSnapshot(ref, (res) => {
      if (res.exists()) {
        userExists = true;
        dispatch(userSlice.actions.setUserAccount({ id: res.id, ...res.data() } as UserAccount));
        dispatch(userSlice.actions.setEmailPreferenceSet(res.data().preferences.emailPreferenceSet));
        if (!isPbxEmail(res.data().email) && process.env.NODE_ENV === 'production') {
          console.log = () => {};
        }
      } else {
        userExists = false;
      }

      // Setup timeout and check mechanism
      let timeoutTimer: ReturnType<typeof setTimeout>;
      let checkTimer: ReturnType<typeof setInterval>;

      const startCheckMechanism = () => {
        checkTimer = setInterval(() => {
          console.log(userExists);
          if (userExists) {
            clearInterval(checkTimer);
            clearTimeout(timeoutTimer);
          }
        }, 100);

        timeoutTimer = setTimeout(() => {
          clearInterval(checkTimer);
          unsubscribe();
          auth.signOut();
        }, 7000);
      };

      startCheckMechanism();
    });
  };

export const getUserHash = () => async (dispatch) => {
  const b = await getUserToken();

  await api
    .get(
      `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/users/me`,

      {
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${b}`,
          'content-type': 'application/json',
        },
      },
    )
    .then((res) => {
      dispatch(userSlice.actions.setIntercomHash(res?.data.identity?._intercom_hash));
    });
};
export const resetPassword = (userEmail: string) => async (_, getState) => {
  const tenantId = getState().tenant.tenantId;
  if (!tenantId) return;

  return api.post(
    `${import.meta.env.VITE_PAPERBOX_LOGIN_URL}/tenants/${tenantId}/users/${userEmail}/reset_password`,
    null,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  );
};

export default userSlice;
