import useOutsideClick from '@shared/hooks/useOutsideClick.tsx';
import se from '@shared/styles/component/admin/admin-masterdata.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as EditIcon } from '@svg/edit-icon.svg';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  charList: string[];
  onChange: (newList: string[]) => void;
}

const AdminCharSelector: React.FC<Props> = ({ charList, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const parentRef = useRef();

  useOutsideClick(parentRef, () => setIsOpen(false));
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [transform, setTransform] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', () => setIsOpen(false), true);
    return () => {
      window.removeEventListener('scroll', () => setIsOpen(false), true);
    };
  }, []);

  const handleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
      return;
    }
    setIsOpen(true);
    const ele = ref.current as HTMLDivElement;
    setDropdownPosition({
      top: ele.getBoundingClientRect().top,
      left: ele.getBoundingClientRect().left + ele.getBoundingClientRect().width / 2,
    });
    setTransform('translateX(-50%) translateY(40px)');
  };
  return (
    <div ref={parentRef} style={{ display: 'contents' }}>
      <div
        ref={ref}
        onClick={() => handleOpen()}
        className={clsx(se.tags_button, { [se.tags_button__active]: isOpen })}
      >
        <div>
          <span>{charList?.length ?? 0}</span>
        </div>
        <div>
          <EditIcon />
        </div>
      </div>
      {isOpen && (
        <div
          className={se.tag_selector}
          style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            transform: transform,
            zIndex: 10000,
          }}
        >
          <CharTagInput charList={charList} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default AdminCharSelector;

const CharTagInput = ({ charList, onChange }) => {
  const { t } = useTranslation();

  const addTag = (char) => {
    onChange([...charList, char]);
  };

  const removeTag = (index) => {
    onChange(charList.filter((_, i) => i !== index));
  };

  const handleKeyPress = (e) => {
    if (e.target.value === '') return;
    if (charList.includes(e.target.value)) return;
    addTag(e.target.value);
    e.target.value = '';
  };

  return (
    <>
      {charList.length > 0 && (
        <div className={se.selectors}>
          {charList.map((tag, index) => (
            <button key={tag} type={'button'} onClick={() => removeTag(index)} className={se.selector_option}>
              {tag}
            </button>
          ))}
        </div>
      )}

      <input
        placeholder={charList.length !== 0 ? '...' : (t('admin:masterdata.headerCharsInput') as string)}
        className={s.input}
        style={{ width: charList.length === 0 ? 140 : null }}
        type="text"
        maxLength={1}
        onChange={handleKeyPress}
      />
    </>
  );
};
