import DocumentTagDropdown from '@components/document/header/DocumentTagDropdown';
import DocumentInboxSwitchModal from '@components/document/type-selector/DocumentInboxSwitchModal';
import DocumentTypeSwitchModal from '@components/document/type-selector/DocumentTypeSwitchModal';
import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { useModal } from '@shared/hooks/useModal';
import { DocumentDetails } from '@shared/models/document';
import { DocTypeExtended } from '@shared/models/inbox';
import { changeDocInbox, getRawPDF } from '@shared/store/documentSlice';
import { deleteDocument, patchDocument } from '@shared/store/inboxSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/inbox/inbox-overlay.module.scss';
import { ReactComponent as ChevronUpIcon } from '@svg/chevron-up.svg';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as DownloadIcon } from '@svg/download.svg';
import { ReactComponent as InboxIcon } from '@svg/inbox-icon.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon.svg';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { CSSTransition } from 'react-transition-group';

interface Props {
  checkedRows: DocumentDetails[];
  clearRows: () => void;
}

const InboxTableToolbar: React.FC<Props> = ({ checkedRows, clearRows }) => {
  const { inboxId } = useParams();
  const tagTypes = useSelector((state) => state.settings.tagTypes);

  const { showModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDownload = async (documentId) => {
    await dispatch(getRawPDF(documentId, inboxId)).then((res) => {
      const a = document.createElement('a');
      const filename = res.headers['content-disposition']?.match(/filename=(.+)/)[1];
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.setAttribute('target', '_blank');
      a.setAttribute('id', 'temp_download_url');
      a.click();
      a.remove();
    });
  };

  const handleDelete = () => {
    checkedRows.forEach((row) => {
      dispatch(deleteDocument(row.id, inboxId));
    });
  };
  const handleDownloadAll = () => {
    checkedRows.forEach((row) => {
      handleDownload(row.id);
    });
  };
  const handleSelectType = ({ docType, subType }: DocTypeExtended) => {
    checkedRows.forEach((row) => {
      dispatch(
        patchDocument(row.id, inboxId, {
          doc_type_id: docType.id,
          doc_subtype_id: subType ? subType.id : null,
        }),
      );
    });
    clearRows();
    closeModal();
  };
  const handleSelectTag = (tagId: string) => {
    checkedRows.forEach((row) => {
      dispatch(
        patchDocument(row.id, inboxId, {
          tag_type_id: tagId,
        }),
      );
    });
    clearRows();
    closeModal();
  };

  const handleSelectInbox = (newInboxId: string) => {
    checkedRows.forEach((row) => {
      dispatch(changeDocInbox(row.id, inboxId, newInboxId));
    });
    clearRows();
    closeModal();
  };
  return (
    <>
      <CSSTransition
        timeout={150}
        in={checkedRows.length > 0}
        classNames={'inbox-overlay-anim'}
        unmountOnExit
        mountOnEnter
      >
        <div className={s.container}>
          <CrossIcon data-testid="inbox-overlay-close" className={s.cross} onClick={clearRows} />
          <div className={s.counter} data-testid="inbox-overlay-count">
            {checkedRows.length}
          </div>
          <p className={s.text}>{checkedRows.length > 1 ? 'documents ' : 'document '}selected</p>

          <div className={s.actions}>
            <Tooltip position={'top'} content={t('document:typeSwitch.docTypeTitle')}>
              <button
                onClick={() =>
                  showModal(
                    <DocumentTypeSwitchModal
                      isMulti
                      handleConfirm={handleSelectType}
                      currentDocType={null}
                    />,
                  )
                }
                className={clsx(s.button, s.button_trash)}
              >
                <ChevronUpIcon />
              </button>
            </Tooltip>
            <Tooltip position={'top'} content={t('document:typeSwitch.inboxTitle')}>
              <button
                onClick={() =>
                  showModal(
                    <DocumentInboxSwitchModal currentInboxId={inboxId} handleConfirm={handleSelectInbox} />,
                  )
                }
                className={clsx(s.button, s.button_trash)}
              >
                <InboxIcon style={{ transform: 'scale(1.2)' }} />
              </button>
            </Tooltip>
            <Tooltip position={'top'} content={t('document:typeSwitch.download')}>
              <button onClick={handleDownloadAll} className={clsx(s.button, s.button_trash)}>
                <DownloadIcon style={{ transform: 'scale(1.2)' }} />
              </button>
            </Tooltip>
            <div>
              <DocumentTagDropdown
                tagTypes={tagTypes}
                handleSelectTag={handleSelectTag}
                className={clsx(s.button, s.button_trash)}
                position={'top'}
                minimal
              />
            </div>
            <Tooltip position={'top'} content={t('document:deletion.deleteDocument')}>
              <button onClick={handleDelete} className={clsx(s.button, s.button_inbox)}>
                <TrashIcon />
              </button>
            </Tooltip>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default InboxTableToolbar;
