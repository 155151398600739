import { ApprovalCheckStatus } from '@shared/models/document.ts';
import { ReactComponent as WarningIcon } from '@svg/alert-triangle-filled.svg';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';
import { ReactComponent as HelpIcon } from '@svg/help-icon.svg';
import React from 'react';

interface Props {
  to: string;
  title?: string;
  children?: React.ReactNode;
}

export const LinkText: React.FC<Props> = (props) => {
  return (
    <a href={props.to || '#'} target="_blank" title={props.title || ''} rel="noreferrer">
      {props.to}
    </a>
  );
};

export const statusToIcon = (status: ApprovalCheckStatus) => {
  switch (status) {
    case 'succeeded':
      return (
        <CheckmarkIcon
          data-testid={'check-success'}
          style={{ color: '#91C500', width: '20px', marginRight: -2, height: 'auto' }}
        />
      );
    case 'failed':
      return (
        <WarningIcon
          data-testid={'check-failed'}
          style={{ color: '#FF5555', width: '18px', height: 'auto' }}
        />
      );
    case 'warning':
      return (
        <HelpIcon data-testid={'check-warning'} style={{ color: '#FCBF19', width: '20px', height: 'auto' }} />
      );
    case 'info':
      return <HelpIcon data-testid={'check-info'} style={{ color: 'gray', width: '20px', height: 'auto' }} />;
  }
};
