import { useModal } from '@shared/hooks/useModal';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../modal/Modal';
import s from './confirmation-dialog.module.scss';

interface Props {
  confirmAction: VoidFunction | (() => Promise<void>);
  cancelAction?: VoidFunction;
  text: string | React.ReactNode;
  title?: string;
  cancelText?: string;
  confirmText?: string;
  dialogType?: 'warning' | 'confirmation';
}

const ConfirmationDialog: React.FC<Props> = ({
  confirmAction,
  cancelAction,
  text,
  title,
  confirmText,
  cancelText,
  dialogType = 'warning',
}) => {
  const { closeDialog } = useModal();
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleConfirm = async () => {
    setIsProcessing(true);
    try {
      if (confirmAction instanceof Function) {
        const result = confirmAction();
        if (result instanceof Promise) {
          await result;
        }
      }
      closeDialog();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal isDialog={true}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleConfirm();
        }}
        data-testid={'confirm-dialog'}
        className={clsx(s.container, {
          [s.container__confirm]: dialogType === 'confirmation',
        })}
      >
        <div className={s.title}>{title ? title : t('document:dialog.title')}</div>
        <div className={s.text}>{text}</div>
        <div
          className={clsx(s.buttons, {
            [s.buttons__confirm]: dialogType === 'confirmation',
          })}
        >
          <button
            type={'button'}
            className={clsx(s.button, s.button_cancel)}
            onClick={(e) => {
              e.preventDefault();
              if (cancelAction) cancelAction();
              closeDialog();
            }}
            disabled={isProcessing}
          >
            {cancelText ? cancelText : t('document:dialog.cancel')}
          </button>
          <button
            tabIndex={0}
            autoFocus
            type={'submit'}
            data-testid={'confirm-dialog-confirm'}
            className={clsx(s.button, s.button_confirm, {
              [s.button_processing]: isProcessing,
            })}
            disabled={isProcessing}
          >
            {isProcessing
              ? t('document:dialog.processing')
              : confirmText
                ? confirmText
                : t('document:dialog.confirm')}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmationDialog;
