// msalConfig.js
import { PublicClientApplication } from '@azure/msal-browser';
import { getCurrentEnvCode } from '@shared/helpers/helpers.ts';

const envCode = getCurrentEnvCode();
const envUrl = `https://${envCode}.paperbox.ai`;
const clientId = import.meta.env.VITE_PAPERBOX_MS365_CLIENT_ID;

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: clientId,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${envUrl}/m365-admin`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});
