import MultiSlider from '@components/shared/slider/MultiSlider';
import si from '@components/shared/slider/slider.module.scss';
import {DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, rectSortingStrategy, SortableContext, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {IClientFieldType} from '@shared/helpers/converters/fieldtype.ts';
import {DocTypeFieldType} from '@shared/models/inbox';
import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import a from '@shared/styles/component/admin/admin.module.scss';
import {ReactComponent as DragHandleDots} from '@svg/drag-handle-dots.svg';
import {ReactComponent as TrashIcon} from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import React, {CSSProperties, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  inboxEntityTypes: IClientFieldType[];
  entityTypes: DocTypeFieldType[];
  setItems: (entityTypes: DocTypeFieldType[]) => void;
  handleDelete: (id: string) => void;
}

const AdminSortableFields: React.FC<Props> = ({ inboxEntityTypes, entityTypes, setItems, handleDelete }) => {
  const [workableItems, setWorkableItems] = useState(entityTypes);

  const { t } = useTranslation();
  const handleChangeOccurrences = (id: string, value: any[]) => {
    const clone = cloneDeep(entityTypes);
    const index = clone.findIndex((e) => e.id === id);
    clone[index].minOccurrences = Number.parseInt(value[0]);
    clone[index].maxOccurrences = Number.parseInt(value[1]);
    setItems(clone);
  };
  const handleChangeConfidence = (id: string, value: number) => {
    const clone = cloneDeep(entityTypes);
    const index = clone.findIndex((e) => e.id === id);
    clone[index].approvalThreshold = value;
    setItems(clone);
  };
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      if (!over) return;
      const oldIndex = workableItems.findIndex((e) => e.id === active.id);
      const newIndex = workableItems.findIndex((e) => e.id === over.id);
      const sorted = arrayMove(workableItems, oldIndex, newIndex);

      setItems(sorted);
      setWorkableItems(sorted);
    }
  };

  useEffect(() => {
    if (entityTypes) {
      setWorkableItems(entityTypes);
    }
  }, [entityTypes]);

  return (
    <div className={s.row_list}>
      {workableItems?.length > 0 && (
        <div className={s.field_type_header}>
          <span>{t('admin:docType.fieldName')}</span>
          <span
            style={{ width: '100%', maxWidth: 200, marginRight: 0, marginLeft: 'auto', textAlign: 'center' }}
          >
            {t('admin:docType.fieldAutomation')} (%)
          </span>
          <span style={{ width: '45%', maxWidth: 375, marginLeft: 0 }}>
            {t('admin:docType.fieldOccurrences')}
          </span>
        </div>
      )}

      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={entityTypes ?? []} strategy={rectSortingStrategy}>
          {workableItems?.map((et) => {
            const name = inboxEntityTypes.find((ie) => ie.id === et.id)?.name ?? '';
            return (
              <AdminSortableFieldItem
                key={et.id}
                name={name}
                entityType={et}
                handleDelete={handleDelete}
                handleChangeOccurrences={handleChangeOccurrences}
                handleChangeConfidence={handleChangeConfidence}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default AdminSortableFields;

const AdminSortableFieldItem: React.FC<any> = ({
  name,
  entityType,
  handleChangeOccurrences,
  handleChangeConfidence,
  handleDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: entityType.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? 100 : 10,
    transition: transition,
  } as CSSProperties;

  return (
    <div className={clsx(sr.wrapper, sr.wrapper__no_anim)} style={style} {...attributes}>
      <div
        ref={setNodeRef}
        {...listeners}
        data-testid={'sortable-field'}
        className={clsx(sr.container, sr.container__no_hover)}
      >
        <div
          style={{ cursor: isDragging ? 'grabbing' : 'grab', display: 'flex', alignItems: 'center', gap: 4 }}
        >
          <DragHandleDots className={a.drag_handle} />
          <span className={sr.title}>{name}</span>
        </div>
      </div>

      <div className={sr.actions}>
        <div className={clsx(sr.action, sr.action__long)}>
          <input
            type={'number'}
            placeholder={'100'}
            min={0}
            max={100}
            value={
              entityType?.approvalThreshold !== null ? Math.round(entityType?.approvalThreshold * 100) : ''
            }
            onChange={(e) => {
              handleChangeConfidence(entityType.id, Number.parseFloat(e.target.value) / 100);
            }}
            style={{ border: 'none' }}
            className={clsx(si.number, si.number__small)}
          />
        </div>
        <div className={clsx(sr.action, sr.action__long)} style={{ minWidth: 350, paddingInline: 10 }}>
          <MultiSlider
            smallInput
            minValue={entityType?.minOccurrences ?? 0}
            maxValue={entityType?.maxOccurrences ?? 20}
            onChange={(e: number[]) => {
              handleChangeOccurrences(entityType.id, e);
            }}
          />
        </div>
        <button
          data-testid={'admin-item-row-delete'}
          className={clsx(sr.action, sr.trash)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete(entityType.id);
          }}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};
