import AdminMultiSelectDialog from '@components/admin/components/AdminMultiSelectDialog.tsx';
import StyledSelect, { DropdownOption } from '@components/shared/dropdown/StyledSelect.tsx';
import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { isSameDate, snakeToCamelCase, usePrevious } from '@shared/helpers/helpers.ts';
import { useIntercomBoot } from '@shared/hooks/intercomContext.tsx';
import useConsole from '@shared/hooks/useConsole.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { getAllApprovalChecks } from '@shared/store/dashboardSlice.ts';
import { getHistoricalDocumentIds, inboxSlice } from '@shared/store/inboxSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/inbox/inbox-content.module.scss';
import { ReactComponent as PaperboxLogoStrike } from '@svg/paperbox-logo-small-strike.svg';
import { ReactComponent as PaperboxLogo } from '@svg/paperbox-logo-small.svg';
import { ReactComponent as SearchIcon } from '@svg/search-icon.svg';
import clsx from 'clsx';
import { isEqual, throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { DateRangePicker } from 'rsuite';
import { RangeType } from 'rsuite/DateRangePicker';
import { allowedRange } from 'rsuite/esm/DateRangePicker/disabledDateUtils';
import { addDays, subDays } from 'rsuite/esm/utils/dateUtils';
import HeaderProfile from '../../header/profile/HeaderProfile.tsx';
import InboxTable, { TableColumns } from '../table/InboxTable.tsx';
import InboxTableFooter from '../table/InboxTableFooter.tsx';
import InboxTags from '../table/components/InboxTags.tsx';
import usePagination from '../table/hooks/usePagination.tsx';

interface Props {}

const InboxHistoricalDocuments: React.FC<Props> = () => {
  const { inboxId } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentPageIndex } = usePagination();
  const { isIntercomBooted } = useIntercomBoot();
  const { trackEvent } = useIntercom();
  const { showDialog } = useModal();
  const activeDocType = useSelector((state) => state.inbox.activeDocumentType);
  const documentListOptions = useSelector((state) => state.inbox.documentListOptions);
  const userInboxIds = useSelector((state) => state.user.userAccount.inboxes);
  const currentDocumentList = useSelector((state) => state.inbox.currentDocumentList);
  const count = useSelector((state) => state.inbox.historicalDocumentCount);

  const pageSize = documentListOptions.pageSize;
  const pageCount = Math.max(1, Math.ceil(count / pageSize)) || 1;
  const prevDocListOptions = usePrevious(documentListOptions);
  const prevPageIndex = usePrevious(currentPageIndex);
  const [activeDateRange, setActiveDateRange] = useState<[Date, Date]>([new Date(), new Date()]);

  const getDocs = useCallback(
    throttle(() => dispatch(getHistoricalDocumentIds(inboxId, currentPageIndex + 1)), 1000),
    [dispatch, inboxId, currentPageIndex, documentListOptions],
  );

  useEffect(() => {
    if (isIntercomBooted) {
      trackEvent('viewed-history');
    }
  }, [isIntercomBooted, trackEvent]);

  const actionOptions: DropdownOption[] = [
    { label: t('document:actions.all'), value: 'all' },
    { label: t('document:actions.approve'), value: 'approve' },
    { label: t('document:actions.bounce'), value: 'bounce' },
    { label: t('document:actions.delete'), value: 'delete' },
  ];
  const [actionValue, setActionValue] = useState(
    documentListOptions?.action
      ? actionOptions.find((o) => o.value === documentListOptions.action)
      : { label: t('document:actions.all'), value: 'all' },
  );

  const predefinedBottomRanges: RangeType[] = [
    {
      label: t('home:dashboard.rangeDay'),
      value: [new Date(), new Date()],
    },
    {
      label: t('home:dashboard.rangeYesterday'),
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      label: t('home:dashboard.rangeWeek'),
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: t('home:dashboard.rangeXDays', { days: 29 }),
      value: [subDays(new Date(), 29), new Date()],
    },
  ];
  const [approvalChecks, setApprovalChecks] = useState([]);
  useEffect(() => {
    const fetchFunc = async () => {
      const response = await dispatch(getAllApprovalChecks([inboxId]));
      const mapped = response.map((check) => {
        return {
          name: t(`document:approvalChecks.${snakeToCamelCase(check.id)}` as any, {
            defaultValue: check.name,
          }),
          id: check.id,
        };
      });
      console.log(mapped);
      setApprovalChecks(mapped);
    };
    fetchFunc();
  }, [t, dispatch, inboxId]);

  useEffect(() => {
    if (state?.back) {
      setActiveDateRange(documentListOptions.dateRange);
    }
  }, [state, documentListOptions]);

  useEffect(() => {
    if (!state?.back) {
      setActiveDateRange([subDays(new Date(), 29), new Date()]);
      dispatch(
        inboxSlice.actions.patchDocumentListOptions({
          dateRange: [subDays(new Date(), 29), new Date()],
        }),
      );
    }
  }, [dispatch, state]);

  const handlePbxFilterClick = () => {
    const { actorId, actorIdFilterMode } = documentListOptions;
    dispatch(inboxSlice.actions.setCurrentPageIndex(0));

    if (!actorId) {
      // Switch to Active state
      dispatch(
        inboxSlice.actions.patchDocumentListOptions({
          actorId: 'paperbox',
          actorIdFilterMode: undefined, // Clear filter type
        }),
      );
    } else if (actorId === 'paperbox' && !actorIdFilterMode) {
      // Switch to Disabled state
      dispatch(
        inboxSlice.actions.patchDocumentListOptions({
          actorId: 'paperbox',
          actorIdFilterMode: 'exclude',
        }),
      );
    } else {
      // Switch to Inactive state
      dispatch(
        inboxSlice.actions.patchDocumentListOptions({
          actorId: null,
          actorIdFilterMode: undefined,
        }),
      );
    }
  };
  const approvalCheckValue = useMemo(() => {
    if (!approvalChecks.length || !documentListOptions?.approvalChecks) {
      return [];
    }
    return documentListOptions.approvalChecks.map((e) => approvalChecks.find((o) => o.id === e));
  }, [approvalChecks, documentListOptions]);
  useConsole(approvalCheckValue, 'approvalCheckValue');
  useConsole(approvalChecks, 'approvalChecks');

  const pbxFilterState = useMemo(() => {
    const { actorId, actorIdFilterMode } = documentListOptions;

    if (!actorId) {
      return 'inactive'; // Inactive state
    }
    if (actorId === 'paperbox' && !actorIdFilterMode) {
      return 'active'; // Active state
    }
    if (actorId === 'paperbox' && actorIdFilterMode === 'exclude') {
      return 'disabled'; // Disabled state
    }
  }, [documentListOptions]);

  useEffect(() => {
    if (
      (userInboxIds && userInboxIds.length !== 0 && !isEqual(documentListOptions, prevDocListOptions)) ||
      currentPageIndex !== prevPageIndex
    ) {
      dispatch(inboxSlice.actions.setDocumentsLoading(true));
      getDocs();
    }
  }, [
    getDocs,
    documentListOptions,
    prevDocListOptions,
    userInboxIds,
    dispatch,
    inboxId,
    currentPageIndex,
    prevPageIndex,
  ]);
  const enabledColumns: Record<TableColumns, boolean> = {
    name: true,
    actor: true,
    actionDate: true,
    confidence: true,
    digitizedDate: false,
    docTypeId: true,
    tagTypeId: true,
    lastUserUpdate: false,
    locker: false,
    approvalChecks: false,
    initialApprovalChecks: true,
    actionType: true,
  };

  return (
    <div className={s.container} style={{ marginBottom: 0 }}>
      <div className={s.header}>
        <h3 className={s.title} data-testid="inbox-title">
          {t('home:historical')}
        </h3>
        <HeaderProfile />
      </div>
      <div className={s.sub_header}>
        <div className={s.search}>
          <SearchIcon />
          <input
            onChange={(e) => {
              dispatch(inboxSlice.actions.patchDocumentListOptions({ searchTerm: e.target.value }));
            }}
            value={documentListOptions?.searchTerm ?? ''}
            type="text"
            placeholder={'Search Subject, Recipient, Sender, Actor or Document Name'}
          />
        </div>
        <Tooltip content={t('home:filterPbx')} position={'bottom'}>
          <button
            className={clsx(s.search_button, {
              [s.search_button__active]: documentListOptions?.actorId === 'paperbox',
            })}
            onClick={handlePbxFilterClick}
          >
            {pbxFilterState === 'disabled' ? <PaperboxLogoStrike /> : <PaperboxLogo />}
          </button>
        </Tooltip>

        {documentListOptions && (
          <div>
            <StyledSelect
              style={{
                maxHeight: 'unset',
                height: '40px',
                border: '1px solid #eeeeee',
                fontWeight: '700 !important',
              }}
              options={actionOptions}
              value={actionValue}
              onChange={(val: any) => {
                if (val.value === actionValue.value) return;
                setActionValue(val);
                if (val.value === 'all') {
                  dispatch(inboxSlice.actions.patchDocumentListOptions({ action: null }));
                } else {
                  dispatch(inboxSlice.actions.patchDocumentListOptions({ action: val.value }));
                }
              }}
            />
          </div>
        )}
        {approvalChecks && (
          <Tooltip content={t('home:filterChecks')} position={'bottom'}>
            <button
              className={clsx(s.search_button, {
                [s.search_button__active]: true,
              })}
              onClick={() =>
                showDialog(
                  <AdminMultiSelectDialog
                    handleCheckTypes={(val: any) => {
                      const mapped = val.map((v: any) => v.id);
                      dispatch(inboxSlice.actions.patchDocumentListOptions({ approvalChecks: mapped }));
                    }}
                    detailedList={approvalChecks}
                    selectedTypes={approvalCheckValue}
                    description={t('home:filterChecksDescription')}
                    title={t('home:filterChecksTitle')}
                  />,
                )
              }
            >
              <span>
                Checks Filter{' '}
                {documentListOptions.approvalChecks && `(${documentListOptions.approvalChecks?.length})`}
              </span>
            </button>
          </Tooltip>
        )}

        {/*  style={{*/}
        {/*    maxHeight: 'unset',*/}
        {/*    height: '40px',*/}
        {/*    maxWidth: 400,*/}
        {/*    border: '1px solid #eeeeee',*/}
        {/*    fontWeight: '700 !important',*/}
        {/*  }}*/}
        {/*  isMulti*/}
        {/*  closeMenuOnSelect={false}*/}
        {/*  value={approvalCheckValue}*/}
        {/*  onChange={(v: any) => {*/}
        {/*    console.log(v);*/}
        {/*    console.log(Array.isArray(v));*/}
        {/*    if (!v) return;*/}
        {/*    if (!Array.isArray(v)) {*/}
        {/*      return;*/}
        {/*    }*/}
        {/*    const mapped = v.map((e) => e.value);*/}
        {/*    dispatch(inboxSlice.actions.patchDocumentListOptions({ approvalChecks: mapped }));*/}
        {/*  }}*/}
        {/*  options={approvalChecks}*/}
        {/*/>*/}

        <InboxTags
          activeTagId={documentListOptions?.activeTagId}
          setActiveTagId={(tagId) => {
            dispatch(inboxSlice.actions.patchDocumentListOptions({ activeTagId: tagId }));
          }}
        />

        <div className={s.info}>
          <DateRangePicker
            size={'md'}
            ranges={predefinedBottomRanges}
            shouldDisableDate={allowedRange(subDays(new Date(), 29), new Date())}
            placeholder="Filter Date Range"
            placement={'auto'}
            editable={false}
            cleanable={false}
            renderValue={(value) => {
              const test = predefinedBottomRanges.find(
                (e) =>
                  isSameDate(new Date(e.value[0]), new Date(value[0])) &&
                  isSameDate(new Date(e.value[1]), new Date(value[1])),
              );
              return (
                <div style={{ marginTop: 2 }}>
                  {test ? test.label : `${value[0].toLocaleDateString()} - ${value[1].toLocaleDateString()}`}
                </div>
              );
            }}
            value={activeDateRange}
            isoWeek
            character={'  -  '}
            onChange={(e) => {
              if (!e) {
                setActiveDateRange(undefined);
              } else {
                dispatch(inboxSlice.actions.patchDocumentListOptions({ dateRange: e }));
                setActiveDateRange(e);
              }
            }}
          />
        </div>
      </div>
      <InboxTable
        enabledColumns={enabledColumns}
        documentList={currentDocumentList}
        pageCount={pageCount}
        goToDocument={(docId) => {
          let query;
          if (activeDocType.docTypeId !== '') {
            query = `${activeDocType.docTypeId}`;
            if (activeDocType.subTypeId) {
              query += `&subTypeId=${activeDocType.subTypeId}`;
            }
          }
          let path = `/inbox/${inboxId}/historical/${docId}`;
          if (query) {
            path += `?docTypeId=${query}`;
          }
          navigate(path);
        }}
      />
      <InboxTableFooter pageCount={pageCount} pageSize={pageSize} />
    </div>
  );
};

export default InboxHistoricalDocuments;
