import AdminMultiSelectDialog from '@components/admin/components/AdminMultiSelectDialog.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { IBrioInboxMappingEntry } from '@shared/helpers/converters/endpoint.ts';
import { IClientInbox } from '@shared/helpers/converters/inbox.ts';
import { useModal } from '@shared/hooks/useModal.tsx';
import { getAdminDocTypesForInbox, getAdminMetadataTypesForInbox } from '@shared/store/adminSlice.ts';
import { useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-ms365.module.scss';

import se from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as ChevronDownIcon } from '@svg/chevron-down.svg';
import { ReactComponent as ErrorIcon } from '@svg/error-icon.svg';
import { ReactComponent as InboxIcon } from '@svg/inbox-icon.svg';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminBrioDoctypeRow from './AdminBrioDoctypeRow.tsx';
import AdminBrioMetadataRow from './AdminBrioMetadataRow.tsx';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  inboxId: string;
  inbox: IClientInbox;
  data: IBrioInboxMappingEntry;
  onChange: (value: IBrioInboxMappingEntry) => void;
}

const AdminBrioWebhookEdit: React.FC<Props> = ({ isOpen, setIsOpen, inboxId, inbox, onChange, data }) => {
  const [metadataOpen, setMetadataOpen] = useState(false);
  const [isMetadataActivityOpen, setIsMetadataActivityOpen] = useState(false);
  const [isDoctypeOpen, setIsDoctypeOpen] = useState(false);
  const [isFieldMappingOpen, setIsFieldMappingOpen] = useState(false);
  const [isActivitySettingsOpen, setIsActivitySettingsOpen] = useState(false);
  const [inboxDocTypes, setInboxDocTypes] = useState(null);
  const [metadataTypes, setMetadataTypes] = useState(null);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const { t } = useTranslation();
  const { showDialog } = useModal();

  const mappedMetadataTypes = metadataTypes?.map((m) => ({ label: m.name, value: m.id }));
  const nullableMappedMetadataTypes = useMemo(() => {
    if (mappedMetadataTypes) return [{ label: 'None', value: '@NONE' }].concat(mappedMetadataTypes);
  }, [mappedMetadataTypes]);

  useEffect(() => {
    getAdminMetadataTypesForInbox(tenantId, inboxId).then((res) => setMetadataTypes(res));
    getAdminDocTypesForInbox(tenantId, inboxId).then((res) => setInboxDocTypes(res));
  }, [inboxId, tenantId]);

  const handleChangeDocumentValue = (key, value) => {
    onChange({
      ...data,
      metadata_field_mapping: {
        ...data.metadata_field_mapping,
        document: {
          ...data.metadata_field_mapping.document,
          [key]: value,
        },
      },
    });
  };

  const handleChangeActivityValue = (key, value) => {
    onChange({
      ...data,
      metadata_field_mapping: {
        ...data.metadata_field_mapping,
        activity: {
          ...data.metadata_field_mapping.activity,
          [key]: value,
        },
      },
    });
  };
  const handleChangeActivitySettingsValue = (key, value) => {
    onChange({
      ...data,
      activity_settings: {
        ...data.activity_settings,
        [key]: value,
      },
    });
  };

  const handleChangeHierarchyValue = (key, value) => {
    onChange({
      ...data,
      metadata_field_hierarchy: {
        ...data.metadata_field_hierarchy,
        [key]: value,
      },
    });
  };

  const handleCheckTypes = (selectedTypes) => {
    const updatedDoctypeSettings = selectedTypes.reduce((acc, type) => {
      acc[type.id] = data.doctype_settings[type.id] || { preferred_hierarchy: 'parties' }; // Customize as needed
      return acc;
    }, {});
    onChange({
      ...data,
      doctype_settings: updatedDoctypeSettings,
    });
  };

  const fieldsToEdit = [
    'description',
    'carrier_type',
    'reference',
    'language',
    'origin',
    'category',
    'archive_delay',
    'delete_delay',
    'description_my_broker',
    'is_favorite',
    'security_level',
    'qualifier',
    'is_visible_my_broker',
  ];

  const activityFieldsToEdit = [
    'description',
    'is_completed',
    'handler',

    'archive_code',
    'type',
    'sub_type',
    'priority',
    'due_date_days_delta',
    'is_visible_my_broker',
  ];

  const activeDoctypes = data?.doctype_settings
    ? Object.keys(data.doctype_settings).map((e) => ({ id: e }))
    : [];

  return (
    <div className={s.wrapper}>
      <div onClick={() => setIsOpen(!isOpen)} className={s.header}>
        <div className={s.headerIcon}>
          <InboxIcon style={{ transform: 'scale(0.8)' }} />
        </div>
        <div className={s.headerText}>
          <h3>{inbox?.settings.name}</h3>
        </div>
        <div className={s.headerArrow}>
          <ChevronDownIcon style={isOpen ? { transform: 'rotate(180deg)' } : {}} />
        </div>
      </div>
      <div className={clsx(s.content, { [s.content__visible]: isOpen })}>
        <div className={s.section}>
          <div
            className={s.sectionHeader}
            onClick={() => setMetadataOpen(!metadataOpen)}
            style={{ cursor: 'pointer', marginBottom: 0 }}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>Metadata Mapping / Document </h3>
              <span>{t('admin:webhooks.brio.metadataDocumentMapping.title')}</span>
            </div>
            {!data.metadata_field_mapping.activity ||
              (Object.keys(data.metadata_field_mapping.document).length === 0 && (
                <Tooltip content={'Metadata Mapping cannot be empty'} position={'left'}>
                  <div style={{ color: 'red', padding: 10 }}>
                    <ErrorIcon />
                    <input
                      style={{
                        width: 10,
                        height: 10,
                        opacity: 0,
                      }}
                      aria-hidden
                      onInvalid={(e: any) => e.target.setCustomValidity('Metadata Mapping cannot be empty')}
                      onInput={(e: any) => e.target.setCustomValidity('')}
                      name={'Metadata Mapping / Document'}
                      required={!mappedMetadataTypes || mappedMetadataTypes.length === 0}
                    />
                  </div>
                </Tooltip>
              ))}

            <div className={s.headerArrow}>
              <ChevronDownIcon style={metadataOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>
          <div
            className={clsx(s.content, { [s.content__visible]: metadataOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: metadataOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            {!mappedMetadataTypes ||
              (mappedMetadataTypes.length === 0 ? (
                <div className={s.sectionWarning}>
                  <p>Please define metadata types for this inbox before continuing</p>
                </div>
              ) : (
                <>
                  {fieldsToEdit.map((field) => {
                    const isRequired = field === 'description';
                    if (!mappedMetadataTypes || mappedMetadataTypes.length === 0) return null;

                    let value = mappedMetadataTypes?.find(
                      (e) => e.value === data.metadata_field_mapping.document[field],
                    );
                    if (!value) {
                      value = isRequired ? mappedMetadataTypes[0] : { value: '@NONE', label: 'None' };
                      if (value != null && value.value !== '@NONE') {
                        handleChangeDocumentValue(field, value.value);
                      }
                    }
                    return (
                      <div key={field} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className={s.headerIcon}
                          style={{ transform: 'scale(0.2)', marginRight: '-10px' }}
                        />
                        <FormInputField
                          required={isRequired}
                          key={field}
                          value={value}
                          type="dropdown"
                          defaultDropdownOption={mappedMetadataTypes[0]}
                          label={t(`admin:webhooks.brio.metadataDocumentMapping.${field}`)}
                          dropdownOptions={isRequired ? mappedMetadataTypes : nullableMappedMetadataTypes}
                          description={t(`admin:webhooks.brio.metadataDocumentMapping.${field}Description`)}
                          onChange={(val) => {
                            if (val.value === '@NONE') {
                              handleChangeDocumentValue(field, null);
                            } else {
                              handleChangeDocumentValue(field, val.value);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ))}
          </div>
        </div>

        <div className={s.section}>
          <div
            className={s.sectionHeader}
            onClick={() => setIsMetadataActivityOpen(!isMetadataActivityOpen)}
            style={{ cursor: 'pointer', marginBottom: 0 }}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>Metadata Mapping / Activity </h3>
              <span>{t('admin:webhooks.brio.metadataActivityMapping.title')}</span>
            </div>
            {!data.metadata_field_mapping.activity ||
              (Object.keys(data.metadata_field_mapping.activity).length === 0 && (
                <Tooltip content={'Metadata Mapping cannot be empty'} position={'left'}>
                  <div style={{ color: 'red', padding: 10 }}>
                    <ErrorIcon />
                    <input
                      style={{
                        width: 10,
                        height: 10,
                        opacity: 0,
                      }}
                      aria-hidden
                      onInvalid={(e: any) => e.target.setCustomValidity('Metadata Mapping cannot be empty')}
                      onInput={(e: any) => e.target.setCustomValidity('')}
                      name={'Metadata Mapping / Activity'}
                      required={!mappedMetadataTypes || mappedMetadataTypes.length === 0}
                    />
                  </div>
                </Tooltip>
              ))}

            <div className={s.headerArrow}>
              <ChevronDownIcon style={isMetadataActivityOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>
          <div
            className={clsx(s.content, { [s.content__visible]: isMetadataActivityOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: isMetadataActivityOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            {!mappedMetadataTypes ||
              (mappedMetadataTypes.length === 0 ? (
                <div className={s.sectionWarning}>
                  <p>Please define metadata types for this inbox before continuing</p>
                </div>
              ) : (
                <>
                  {activityFieldsToEdit.map((field) => {
                    const isRequired =
                      field === 'description' || field === 'handler' || field === 'is_completed';
                    if (!mappedMetadataTypes || mappedMetadataTypes.length === 0) return null;

                    let value = mappedMetadataTypes?.find(
                      (e) => e.value === data.metadata_field_mapping.activity[field],
                    );
                    if (!value) {
                      value = isRequired ? mappedMetadataTypes[0] : { value: '@NONE', label: 'None' };
                      if (value != null && value.value !== '@NONE') {
                        handleChangeActivityValue(field, value.value);
                      }
                    }

                    return (
                      <div key={field} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className={s.headerIcon}
                          style={{ transform: 'scale(0.2)', marginRight: '-10px' }}
                        />
                        <FormInputField
                          required={isRequired}
                          key={field}
                          value={value}
                          type="dropdown"
                          defaultDropdownOption={mappedMetadataTypes[0]}
                          label={t(`admin:webhooks.brio.metadataActivityMapping.${field}`)}
                          dropdownOptions={isRequired ? mappedMetadataTypes : nullableMappedMetadataTypes}
                          description={t(`admin:webhooks.brio.metadataActivityMapping.${field}Description`)}
                          onChange={(val) => {
                            if (val.value === '@NONE') {
                              handleChangeActivityValue(field, null);
                            } else {
                              handleChangeActivityValue(field, val.value);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ))}
          </div>
        </div>

        <div className={s.section}>
          <div
            className={s.sectionHeader}
            style={{ cursor: 'pointer', marginBottom: 0 }}
            onClick={() => setIsFieldMappingOpen(!isFieldMappingOpen)}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>{t('admin:webhooks.brio.metadataHierarchy.title')}</h3>
              <span>{t('admin:webhooks.brio.metadataHierarchy.description')}</span>
            </div>
            {!data.metadata_field_mapping.activity ||
              (Object.keys(data.metadata_field_hierarchy).length === 0 && (
                <Tooltip content={'Metadata Field Hierarchy needs to be defined'} position={'left'}>
                  <div style={{ color: 'red', padding: 10 }}>
                    <ErrorIcon />
                    <input
                      style={{
                        width: 10,
                        height: 10,
                        opacity: 0,
                      }}
                      aria-hidden
                      onInvalid={(e: any) =>
                        e.target.setCustomValidity('Metadata Field Hierarchy needs to be defined')
                      }
                      onInput={(e: any) => e.target.setCustomValidity('')}
                      name={'Metadata Mapping / Document'}
                      required={!mappedMetadataTypes || mappedMetadataTypes.length === 0}
                    />
                  </div>
                </Tooltip>
              ))}
            <div className={s.headerArrow}>
              <ChevronDownIcon style={isFieldMappingOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>
          <div
            className={clsx(s.content, { [s.content__visible]: isFieldMappingOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: isFieldMappingOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            {!mappedMetadataTypes ||
              (mappedMetadataTypes.length === 0 ? (
                <div className={s.sectionWarning}>
                  <p>Please define metadata types for this inbox before continuing</p>
                </div>
              ) : (
                <div className={se.field_type_header} style={{ marginTop: 10 }}>
                  <span>Brio Resource Type</span>
                  <span
                    style={{
                      width: '100%',
                      maxWidth: 275,
                      marginRight: 0,
                      marginLeft: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    Metadata Field
                  </span>
                </div>
              ))}
            {data.metadata_field_hierarchy && mappedMetadataTypes && mappedMetadataTypes.length > 0 && (
              <>
                <AdminBrioMetadataRow
                  data={{ id: data.metadata_field_hierarchy.claim, type: 'claim' }}
                  metadataTypes={mappedMetadataTypes}
                  onChange={(val) => handleChangeHierarchyValue('claim', val)}
                />
                <AdminBrioMetadataRow
                  data={{ id: data.metadata_field_hierarchy.contract, type: 'contract' }}
                  metadataTypes={mappedMetadataTypes}
                  onChange={(val) => handleChangeHierarchyValue('contract', val)}
                />
                <AdminBrioMetadataRow
                  data={{ id: data.metadata_field_hierarchy.party, type: 'party' }}
                  metadataTypes={mappedMetadataTypes}
                  onChange={(val) => handleChangeHierarchyValue('party', val)}
                />
              </>
            )}
          </div>
        </div>

        <div className={s.section}>
          <div
            className={s.sectionHeader}
            style={{ cursor: 'pointer', marginBottom: 0 }}
            onClick={() => setIsDoctypeOpen(!isDoctypeOpen)}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>{t('admin:webhooks.brio.docTypeConfig.title')}</h3>
              <span>{t('admin:webhooks.brio.docTypeConfig.description')}</span>
            </div>
            {isDoctypeOpen && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showDialog(
                    <AdminMultiSelectDialog
                      description={t('admin:webhooks.brio.docTypeConfig.addDescription')}
                      handleCheckTypes={handleCheckTypes}
                      detailedList={inboxDocTypes?.map((dt) => ({ id: dt.id, name: dt.name })) ?? []}
                      title={t('admin:webhooks.brio.docTypeConfig.addTitle')}
                      selectedTypes={activeDoctypes ?? []}
                    />,
                  );
                }}
                className={se.top_button}
              >
                + {t('admin:webhooks.brio.docTypeConfig.add')}
              </button>
            )}
            <div className={s.headerArrow}>
              <ChevronDownIcon style={isDoctypeOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>

          <div
            className={clsx(s.content, { [s.content__visible]: isDoctypeOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: isDoctypeOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            <div className={se.field_type_header} style={{ marginTop: 10 }}>
              <span>{t('admin:webhooks.brio.docTypeConfig.docType')}</span>
              <span
                style={{
                  width: '100%',
                  maxWidth: 245,
                  marginRight: 0,
                  marginLeft: 'auto',
                  textAlign: 'center',
                }}
              >
                {t('admin:webhooks.brio.docTypeConfig.hierarchy')}
              </span>
              <span style={{ width: '15%', maxWidth: 120, marginLeft: 0 }}>
                {t('admin:webhooks.brio.docTypeConfig.createActivity')}
              </span>
            </div>
            {data.doctype_settings &&
              Object.entries(data.doctype_settings).map(([k, v]) => {
                return (
                  <AdminBrioDoctypeRow
                    inboxDocTypes={inboxDocTypes}
                    key={k}
                    data={{ docType: k, data: v }}
                    handleDelete={() => {
                      const dts = { ...data.doctype_settings };
                      delete dts[k];
                      onChange({
                        ...data,
                        doctype_settings: dts,
                      });
                    }}
                    onChange={(change) => {
                      onChange({
                        ...data,
                        doctype_settings: {
                          ...data.doctype_settings,
                          [k]: change,
                        },
                      });
                    }}
                  />
                );
              })}
          </div>
        </div>
        <div className={s.section}>
          <div
            className={s.sectionHeader}
            style={{ cursor: 'pointer', marginBottom: 0 }}
            onClick={() => setIsActivitySettingsOpen(!isActivitySettingsOpen)}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>{t('admin:webhooks.brio.activitySettings.title')}</h3>
              <span>{t('admin:webhooks.brio.activitySettings.description')}</span>
            </div>
            <div className={s.headerArrow}>
              <ChevronDownIcon style={isActivitySettingsOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>

          <div
            className={clsx(s.content, { [s.content__visible]: isActivitySettingsOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: isActivitySettingsOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={s.headerIcon} style={{ transform: 'scale(0.2)', marginRight: '-10px' }} />
              <FormInputField
                type={'text'}
                label={t('admin:webhooks.brio.activitySettings.rds')}
                description={t('admin:webhooks.brio.activitySettings.rdsDescription')}
                value={data.activity_settings?.recipient_default_service}
                onChange={(val) => handleChangeActivitySettingsValue('recipient_default_service', val)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBrioWebhookEdit;
