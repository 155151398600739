import StyledSelect from '@components/shared/dropdown/StyledSelect.tsx';
import { inboxSlice } from '@shared/store/inboxSlice.ts';
import { useDispatch } from '@shared/store/store.ts';
import s from '@shared/styles/component/inbox/inbox-table.module.scss';
import { ReactComponent as ChevronLeft } from '@svg/chevron-left.svg';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import usePagination from './hooks/usePagination.tsx';

interface Props {
  pageSize: number;
  pageCount: number;
}

const InboxTableFooter: React.FC<Props> = ({ pageSize, pageCount }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { handlePrevPage, handleNextPage, currentPageIndex } = usePagination();

  return (
    <div className={s.table_bottom}>
      <div className={s.pagination}>
        <button
          aria-label="nav-back"
          tabIndex={0}
          className={s.pagination_icon}
          onClick={handlePrevPage}
          disabled={!(currentPageIndex !== 0)}
        >
          <ChevronLeft />
        </button>
        <span className={s.pagination_text}>
          {pageCount && (
            <Trans
              i18nKey={'home:table.page'}
              defaults={'Page {{x}} of {{y}}'}
              values={{ x: currentPageIndex + 1, y: pageCount }}
            />
          )}
        </span>
        <button
          aria-label="nav-forward"
          tabIndex={0}
          className={s.pagination_icon}
          onClick={handleNextPage}
          disabled={!(currentPageIndex + 1 < pageCount)}
        >
          <ChevronLeft style={{ transform: 'rotate(180deg)' }} />
        </button>
      </div>

      <div className={s.pagination_select}>
        <span>{t('home:table.pageSize')}:</span>

        <div style={{ width: 70 }}>
          <StyledSelect
            style={{ maxHeight: 40, height: 40 }}
            value={{
              value: pageSize,
              label: pageSize.toString(),
            }}
            onChange={(v: any) =>
              dispatch(inboxSlice.actions.patchDocumentListOptions({ pageSize: v.value }))
            }
            options={[
              { value: 5, label: '5' },
              { value: 10, label: '10' },
              { value: 30, label: '30' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default InboxTableFooter;
