import { sleep } from '@shared/helpers/helpers';
import { createNewUser } from '@shared/store/adminSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import { DotPulse } from '@uiball/loaders';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type LoadingState = 'default' | 'submitting' | 'error' | 'done';

interface ValidationResult {
  isValid: boolean;
  error?: string;
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const RESET_DELAY = 3000;

const validateEmail = (email: string): ValidationResult => {
  if (!EMAIL_REGEX.test(email)) {
    return { isValid: false, error: 'Please enter a valid email' };
  }
  return { isValid: true };
};

const validateDomain = (email: string, allowedDomains: string[]): ValidationResult => {
  const isAllowed = allowedDomains.some((domain) => email.endsWith(`@${domain}`));
  return {
    isValid: isAllowed,
    error: isAllowed ? undefined : 'Email not allowed',
  };
};

const AdminNewUserRow: React.FC = () => {
  const tenantSettings = useSelector((state) => state.tenant.details.settings);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>('default');
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const resetForm = () => {
    setEmail('');
    setLoadingState('default');
  };

  const handleValidationError = (error: string) => {
    setEmail('');
    inputRef.current?.focus();
    setError(error);
  };

  const handleUserExists = () => {
    setError('User already exists');
    setLoadingState('error');
  };

  const handleSuccess = async () => {
    setError('');
    setLoadingState('done');
    await sleep(RESET_DELAY);
    resetForm();
  };

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();

    // Email format validation
    const emailValidation = validateEmail(email);
    if (!emailValidation.isValid) {
      handleValidationError(emailValidation.error);
      return;
    }

    // Domain validation
    const domainValidation = validateDomain(email, tenantSettings.allowedDomains);
    if (!domainValidation.isValid) {
      handleValidationError(domainValidation.error);
      return;
    }

    try {
      setLoadingState('submitting');
      const response = await dispatch(createNewUser(email));

      if (response.status === 409) {
        handleUserExists();
        return;
      }

      await handleSuccess();
    } catch (err: any) {
      if (err.request?.status === 409) {
        handleUserExists();
      }
    }
  };

  useEffect(() => {
    resetForm();
    setError(null);
  }, []);

  const renderInput = () => {
    if (loadingState === 'done') {
      return (
        <div className={clsx(se.input, se.input__top)}>
          <CheckmarkIcon className={se.check} />
          <span>
            {t('admin:users.emailSent')} {email}
          </span>
        </div>
      );
    }

    return (
      <input
        data-testid="admin-user-input"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        ref={inputRef}
        type="email"
        placeholder="user@email.com"
        className={clsx(se.input, se.input__top)}
      />
    );
  };

  const renderButton = () => {
    switch (loadingState) {
      case 'done':
        return (
          <>
            <CheckmarkIcon className={se.check} />
            <span>{t('admin:users.success')}</span>
          </>
        );
      case 'submitting':
        return (
          <>
            <DotPulse color="#0085FF" size={3} />
            <span>{t('admin:users.creatingUser')}</span>
          </>
        );
      default:
        return (
          <>
            <PlusIcon />
            <span>{t('admin:users.createUser')}</span>
          </>
        );
    }
  };

  return (
    <form onSubmit={handleCreateUser} className={s.body_header_action}>
      <span className={s.body_header_action_error}>{error}</span>
      <div className={se.input_wrapper}>{renderInput()}</div>
      <button data-testid="admin-user-add" className={se.top_button}>
        {renderButton()}
      </button>
    </form>
  );
};

export default AdminNewUserRow;
