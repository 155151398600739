import SearchResultCardSimple from '@components/document/labeler/sidebar/search/SearchResultCardSimple.tsx';
import StyledSelect from '@components/shared/dropdown/StyledSelect.tsx';
import Input from '@components/shared/input/Input.tsx';
import Modal from '@components/shared/modal/Modal.tsx';
import { documentSlice, MasterDataSearchPayload, searchMasterData } from '@shared/store/documentSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import { DotPulse } from '@uiball/loaders';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import s from '@shared/styles/component/admin/admin-table-search.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {}

const AdminMasterdataTableSearch: React.FC<Props> = () => {
  const [searchInput, setSearchInput] = useState('');
  const tables = useSelector((state) => state.admin.inboxMasterdataTables);
  const [selectedTables, setSelectedTables] = useState(null);
  const masterDataResults = useSelector((state) => state.document.masterDataResults);
  const masterDataStatus = useSelector((state) => state.document.masterDataStatus);
  const inboxId = useSelector((state) => state.admin.activeInboxId);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const tableOptions = useMemo(() => {
    return tables.map((table) => {
      return {
        value: table.id,
        label: table.name,
      };
    });
  }, [tables]);

  useEffect(() => {
    if (selectedTables === null && tableOptions.length > 0) {
      setSelectedTables(tableOptions);
    }
  }, [selectedTables, tableOptions]);

  useEffect(() => {
    const debouncedHandleSearch = debounce(() => {
      const tableIds = selectedTables.map((e) => e.value);
      const payload: MasterDataSearchPayload = {
        prompt: searchInput,
        fields: [],
        table_ids: tableIds,
      };
      dispatch(searchMasterData(payload, inboxId));
    }, 300);

    debouncedHandleSearch();
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [dispatch, inboxId, searchInput, selectedTables]);
  useEffect(() => {
    return () => {
      dispatch(documentSlice.actions.setMasterDataResults(null));
    };
  }, [dispatch]);

  return (
    <Modal>
      <div className={s.container}>
        <div className={s.header}>Masterdata Search</div>
        <div className={s.content}>
          <div className={s.search}>
            <Input
              value={searchInput}
              placeholder={t('admin:masterdata.tableSearchDescription')}
              setValue={(value) => setSearchInput(value)}
              id={''}
            />
            <StyledSelect
              value={selectedTables}
              options={tableOptions}
              onChange={(e) => {
                setSelectedTables(e as any);
              }}
              isMulti
            />
          </div>
          {(!masterDataResults || masterDataStatus === 'searching') && searchInput !== '' && (
            <div className={s.status}>
              {masterDataStatus === 'searching' && <DotPulse color={'#0085FF'} />}
              {masterDataStatus === 'no-results' && <div>No Results found</div>}
            </div>
          )}

          {masterDataResults && (
            <div className={s.results}>
              {masterDataResults?.map((e) => {
                return <SearchResultCardSimple result={e} key={e.id} />;
              })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AdminMasterdataTableSearch;
