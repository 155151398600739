import Modal from '@components/shared/modal/Modal.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import s from '@shared/styles/component/admin/admin-ms365.module.scss';
import si from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as ChevronLeft } from '@svg/chevron-left.svg';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MS365MailFolder } from './AdminMS365UserRow.tsx';

interface Props {
  currentPath?: string;
  mailFolders: MS365MailFolder[];
  onChange: (newPathId: string) => void;
}

const AdminMS365UserPicker: React.FC<Props> = ({ mailFolders, currentPath, onChange }) => {
  // A list of columns, each column is an array of MS365MailFolder
  const [folderColumns, setFolderColumns] = useState<MS365MailFolder[][]>([]);
  const [path, setPath] = useState<string[]>(currentPath ? currentPath.split('/') : []);
  const [selectedPathId, setSelectedPathId] = useState(null);
  const { closeDialog } = useModal();
  const { t } = useTranslation();

  const findFolderByName = useCallback(
    (folders: MS365MailFolder[], folderName: string): MS365MailFolder | null => {
      for (const folder of folders) {
        if (folder.displayName === folderName) {
          return folder;
        }
        if (folder.children && folder.children.length > 0) {
          const found = findFolderByName(folder.children, folderName);
          if (found) return found;
        }
      }
      return null;
    },
    [],
  );

  // Initialize folderColumns based on currentPath
  useEffect(() => {
    if (folderColumns.length > 0) return;
    const initialColumns = [mailFolders];
    let currentFolders = mailFolders;

    path.forEach((folderName) => {
      const foundFolder = findFolderByName(currentFolders, folderName);
      if (foundFolder?.children) {
        initialColumns.push(foundFolder.children);
        currentFolders = foundFolder.children;
      }
    });

    setFolderColumns(initialColumns);
  }, [findFolderByName, folderColumns.length, mailFolders, path]);

  // Function to handle folder selection
  const selectFolder = (folder: MS365MailFolder, folders: MS365MailFolder[]) => {
    const columnIndex = folderColumns.findIndex((column) => column === folders);
    // Update the path with the new selection
    const newPath = [...path.slice(0, columnIndex), folder.displayName];
    setPath(newPath);
    setSelectedPathId(folder.id);

    // If the folder has children, add them as a new column
    if (folder.children && folder.children.length > 0) {
      const newColumns = folderColumns.slice(0, columnIndex + 1);
      newColumns.push(folder.children);
      setFolderColumns(newColumns);
    } else {
      // If there are no children, truncate the columns to the current level
      setFolderColumns(folderColumns.slice(0, columnIndex + 1));
    }
  };
  const handleSave = () => {
    onChange(selectedPathId);
    closeDialog();
  };

  // Function to render each column of folders
  const renderColumn = (folders: MS365MailFolder[], index: number) => (
    <div className={s.column} key={index}>
      {folders.map((folder) => (
        <button
          type={'button'}
          tabIndex={0}
          className={clsx(
            s.columnItem,
            {
              [s.columnItem__disabled]:
                folder.isUsed && (currentPath ? !currentPath.split('/').includes(folder.displayName) : true),
            },
            { [s.columnItem__active]: path.includes(folder.displayName) },
          )}
          key={folder.id}
          onClick={() => {
            if (folder.isUsed && (currentPath ? !currentPath.split('/').includes(folder.displayName) : true))
              return;
            selectFolder(folder, folders);
          }}
        >
          <span>{folder.displayName}</span>
          {folder.children?.length > 0 && <ChevronLeft style={{ transform: 'rotate(180deg)' }} />}
        </button>
      ))}
    </div>
  );
  const truncatedColumns = useMemo(() => {
    if (folderColumns.length > 4) {
      return folderColumns.slice(-4, folderColumns.length);
    }
    return folderColumns;
  }, [folderColumns]);

  return (
    <Modal isDialog>
      <div className={s.userPicker}>
        <div className={s.userPickerHeader}>
          <h3>{path.length > 0 ? path.join(' / ') : t('admin:connectors.ms365.userPicker.selectPath')}</h3>
        </div>
        <div className={s.userPickerBody}>{truncatedColumns.map(renderColumn)}</div>
        <div className={s.userPickerFooter}>
          <button className={clsx(si.save_button, si.save_button__alt)} onClick={closeDialog}>
            {t('document:dialog.cancel')}
          </button>
          <button className={si.save_button} onClick={handleSave}>
            {t('document:dialog.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdminMS365UserPicker;
