import AdminMultiSelectDialog from '@components/admin/components/AdminMultiSelectDialog.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { ICCSInboxMappingEntry } from '@shared/helpers/converters/endpoint.ts';
import { IClientInbox } from '@shared/helpers/converters/inbox.ts';
import { useModal } from '@shared/hooks/useModal.tsx';
import { getAdminDocTypesForInbox, getAdminMetadataTypesForInbox } from '@shared/store/adminSlice.ts';
import { useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-ms365.module.scss';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as ChevronDownIcon } from '@svg/chevron-down.svg';
import { ReactComponent as ErrorIcon } from '@svg/error-icon.svg';
import { ReactComponent as InboxIcon } from '@svg/inbox-icon.svg';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminCCSDoctypeRow from './AdminCCSDoctypeRow.tsx';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  inboxId: string;
  inbox: IClientInbox;
  data: ICCSInboxMappingEntry;
  onChange: (value: ICCSInboxMappingEntry) => void;
}

const AdminCCSWebhookEdit: React.FC<Props> = ({ isOpen, setIsOpen, inboxId, inbox, onChange, data }) => {
  const [claimMappingOpen, setClaimMappingOpen] = useState(false);
  const [agendaMappingOpen, setAgendaMappingOpen] = useState(false);
  const [archiveMappingOpen, setArchiveMappingOpen] = useState(false);
  const [isDoctypeOpen, setIsDoctypeOpen] = useState(false);
  const [inboxDocTypes, setInboxDocTypes] = useState(null);
  const [metadataTypes, setMetadataTypes] = useState(null);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const { t } = useTranslation();
  const { showDialog } = useModal();

  const mappedMetadataTypes = useMemo(() => {
    return metadataTypes?.map((m) => ({ label: m.name, value: m.id })) ?? [];
  }, [metadataTypes]);

  const nullableMappedMetadataTypes = useMemo(() => {
    if (mappedMetadataTypes) return [{ label: 'None', value: '@NONE' }].concat(mappedMetadataTypes);
  }, [mappedMetadataTypes]);

  useEffect(() => {
    getAdminMetadataTypesForInbox(tenantId, inboxId).then((res) => setMetadataTypes(res));
    getAdminDocTypesForInbox(tenantId, inboxId).then((res) => setInboxDocTypes(res));
  }, [inboxId, tenantId]);

  const handleChangeClaimValue = (key, value) => {
    onChange({
      ...data,
      metadata_field_mapping: {
        ...data.metadata_field_mapping,
        claim: {
          ...data.metadata_field_mapping.claim,
          [key]: value,
        },
      },
    });
  };

  const handleChangeAgendaValue = (key, value) => {
    onChange({
      ...data,
      metadata_field_mapping: {
        ...data.metadata_field_mapping,
        agenda: {
          ...data.metadata_field_mapping.agenda,
          [key]: value,
        },
      },
    });
  };

  const handleChangeArchiveValue = (key, value) => {
    onChange({
      ...data,
      metadata_field_mapping: {
        ...data.metadata_field_mapping,
        archive: {
          ...data.metadata_field_mapping.archive,
          [key]: value,
        },
      },
    });
  };

  const handleCheckTypes = (selectedTypes) => {
    const updatedDoctypeSettings = selectedTypes.reduce((acc, type) => {
      acc[type.id] = data.doctype_settings?.[type.id] || { agenda_reason: '', agenda_create: false };
      return acc;
    }, {});
    onChange({
      ...data,
      doctype_settings: updatedDoctypeSettings,
    });
  };

  const claimFieldsToEdit = useMemo(
    () => ['company_number', 'intermediary_person_number', 'claim_number_office'],
    [],
  );

  const agendaFieldsToEdit = ['due_date_delta', 'employee_number', 'description'];

  const archiveFieldsToEdit = ['is_secret', 'document_type', 'description'];

  const activeDoctypes = data?.doctype_settings
    ? Object.keys(data.doctype_settings).map((e) => ({ id: e }))
    : [];

  return (
    <div className={s.wrapper}>
      <div onClick={() => setIsOpen(!isOpen)} className={s.header}>
        <div className={s.headerIcon}>
          <InboxIcon style={{ transform: 'scale(0.8)' }} />
        </div>
        <div className={s.headerText}>
          <h3>{inbox?.settings.name}</h3>
        </div>
        <div className={s.headerArrow}>
          <ChevronDownIcon style={isOpen ? { transform: 'rotate(180deg)' } : {}} />
        </div>
      </div>
      <div className={clsx(s.content, { [s.content__visible]: isOpen })}>
        {/* Claim Mapping Section */}
        <div className={s.section}>
          <div
            className={s.sectionHeader}
            onClick={() => setClaimMappingOpen(!claimMappingOpen)}
            style={{ cursor: 'pointer', marginBottom: 0 }}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>{t('admin:webhooks.ccs.claimMapping.title')}</h3>
              <span>{t('admin:webhooks.ccs.claimMapping.description')}</span>
            </div>
            {!data.metadata_field_mapping.claim ||
              (Object.keys(data.metadata_field_mapping.claim).length === 0 && (
                <Tooltip content={'Claim Mapping cannot be empty'} position={'left'}>
                  <div style={{ color: 'red', padding: 10 }}>
                    <ErrorIcon />
                    <input
                      style={{
                        width: 10,
                        height: 10,
                        opacity: 0,
                      }}
                      aria-hidden
                      onInvalid={(e: any) => e.target.setCustomValidity('Claim Mapping cannot be empty')}
                      onInput={(e: any) => e.target.setCustomValidity('')}
                      name={'Claim Mapping'}
                      required={!mappedMetadataTypes || mappedMetadataTypes.length === 0}
                    />
                  </div>
                </Tooltip>
              ))}
            <div className={s.headerArrow}>
              <ChevronDownIcon style={claimMappingOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>
          <div
            className={clsx(s.content, { [s.content__visible]: claimMappingOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: claimMappingOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            {!mappedMetadataTypes ||
              (mappedMetadataTypes.length === 0 ? (
                <div className={s.sectionWarning}>
                  <p>Please define metadata types for this inbox before continuing</p>
                </div>
              ) : (
                <>
                  {claimFieldsToEdit.map((field) => {
                    const isRequired = true; // All claim fields are required
                    if (!mappedMetadataTypes || mappedMetadataTypes.length === 0) return null;

                    let value = mappedMetadataTypes?.find(
                      (e) => e.value === data.metadata_field_mapping.claim[field],
                    );
                    if (!value) {
                      value = isRequired ? mappedMetadataTypes[0] : { value: '@NONE', label: 'None' };
                      if (value != null && value.value !== '@NONE') {
                        handleChangeClaimValue(field, value.value);
                      }
                    }
                    return (
                      <div key={field} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className={s.headerIcon}
                          style={{ transform: 'scale(0.2)', marginRight: '-10px' }}
                        />
                        <FormInputField
                          required={isRequired}
                          key={field}
                          value={value}
                          type="dropdown"
                          defaultDropdownOption={mappedMetadataTypes[0]}
                          label={t(`admin:webhooks.ccs.claimMapping.fields.${field}`)}
                          dropdownOptions={isRequired ? mappedMetadataTypes : nullableMappedMetadataTypes}
                          description={t(`admin:webhooks.ccs.claimMapping.fields.${field}Description`)}
                          onChange={(val) => {
                            if (val.value === '@NONE') {
                              handleChangeClaimValue(field, null);
                            } else {
                              handleChangeClaimValue(field, val.value);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ))}
          </div>
        </div>

        <div className={s.section}>
          <div
            className={s.sectionHeader}
            onClick={() => setAgendaMappingOpen(!agendaMappingOpen)}
            style={{ cursor: 'pointer', marginBottom: 0 }}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>{t('admin:webhooks.ccs.agendaMapping.title')}</h3>
              <span>{t('admin:webhooks.ccs.agendaMapping.description')}</span>
            </div>
            {!data.metadata_field_mapping.agenda ||
              (Object.keys(data.metadata_field_mapping.agenda).length === 0 && (
                <Tooltip content={'Agenda Mapping cannot be empty'} position={'left'}>
                  <div style={{ color: 'red', padding: 10 }}>
                    <ErrorIcon />
                    <input
                      style={{
                        width: 10,
                        height: 10,
                        opacity: 0,
                      }}
                      aria-hidden
                      onInvalid={(e: any) => e.target.setCustomValidity('Agenda Mapping cannot be empty')}
                      onInput={(e: any) => e.target.setCustomValidity('')}
                      name={'Agenda Mapping'}
                      required={!mappedMetadataTypes || mappedMetadataTypes.length === 0}
                    />
                  </div>
                </Tooltip>
              ))}
            <div className={s.headerArrow}>
              <ChevronDownIcon style={agendaMappingOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>
          <div
            className={clsx(s.content, { [s.content__visible]: agendaMappingOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: agendaMappingOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            {!mappedMetadataTypes ||
              (mappedMetadataTypes.length === 0 ? (
                <div className={s.sectionWarning}>
                  <p>Please define metadata types for this inbox before continuing</p>
                </div>
              ) : (
                <>
                  {agendaFieldsToEdit.map((field) => {
                    const isRequired = field !== 'description';
                    if (!mappedMetadataTypes || mappedMetadataTypes.length === 0) return null;

                    let value = mappedMetadataTypes?.find(
                      (e) => e.value === data.metadata_field_mapping.agenda[field],
                    );
                    if (!value) {
                      value = isRequired ? mappedMetadataTypes[0] : { value: '@NONE', label: 'None' };
                      if (value != null && value.value !== '@NONE') {
                        handleChangeAgendaValue(field, value.value);
                      }
                    }
                    return (
                      <div key={field} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className={s.headerIcon}
                          style={{ transform: 'scale(0.2)', marginRight: '-10px' }}
                        />
                        <FormInputField
                          required={isRequired}
                          key={field}
                          value={value}
                          type="dropdown"
                          defaultDropdownOption={mappedMetadataTypes[0]}
                          label={t(`admin:webhooks.ccs.agendaMapping.fields.${field}`)}
                          dropdownOptions={isRequired ? mappedMetadataTypes : nullableMappedMetadataTypes}
                          description={t(`admin:webhooks.ccs.agendaMapping.fields.${field}Description`)}
                          onChange={(val) => {
                            if (val.value === '@NONE') {
                              handleChangeAgendaValue(field, null);
                            } else {
                              handleChangeAgendaValue(field, val.value);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ))}
          </div>
        </div>

        {/* Archive Mapping Section */}
        <div className={s.section}>
          <div
            className={s.sectionHeader}
            onClick={() => setArchiveMappingOpen(!archiveMappingOpen)}
            style={{ cursor: 'pointer', marginBottom: 0 }}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>{t('admin:webhooks.ccs.archiveMapping.title')}</h3>
              <span>{t('admin:webhooks.ccs.archiveMapping.description')}</span>
            </div>
            {!data.metadata_field_mapping.archive ||
              (Object.keys(data.metadata_field_mapping.archive).length === 0 && (
                <Tooltip content={'Archive Mapping cannot be empty'} position={'left'}>
                  <div style={{ color: 'red', padding: 10 }}>
                    <ErrorIcon />
                    <input
                      style={{
                        width: 10,
                        height: 10,
                        opacity: 0,
                      }}
                      aria-hidden
                      onInvalid={(e: any) => e.target.setCustomValidity('Archive Mapping cannot be empty')}
                      onInput={(e: any) => e.target.setCustomValidity('')}
                      name={'Archive Mapping'}
                      required={!mappedMetadataTypes || mappedMetadataTypes.length === 0}
                    />
                  </div>
                </Tooltip>
              ))}
            <div className={s.headerArrow}>
              <ChevronDownIcon style={archiveMappingOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>
          <div
            className={clsx(s.content, { [s.content__visible]: archiveMappingOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: archiveMappingOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            {!mappedMetadataTypes ||
              (mappedMetadataTypes.length === 0 ? (
                <div className={s.sectionWarning}>
                  <p>Please define metadata types for this inbox before continuing</p>
                </div>
              ) : (
                <>
                  {archiveFieldsToEdit.map((field) => {
                    const isRequired = field !== 'description';
                    if (!mappedMetadataTypes || mappedMetadataTypes.length === 0) return null;

                    let value = mappedMetadataTypes?.find(
                      (e) => e.value === data.metadata_field_mapping.archive[field],
                    );
                    if (!value) {
                      value = isRequired ? mappedMetadataTypes[0] : { value: '@NONE', label: 'None' };
                      if (value != null && value.value !== '@NONE') {
                        handleChangeArchiveValue(field, value.value);
                      }
                    }
                    return (
                      <div key={field} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className={s.headerIcon}
                          style={{ transform: 'scale(0.2)', marginRight: '-10px' }}
                        />

                        <FormInputField
                          required={isRequired}
                          value={value}
                          type="dropdown"
                          defaultDropdownOption={mappedMetadataTypes[0]}
                          label={t(`admin:webhooks.ccs.archiveMapping.fields.${field}`)}
                          dropdownOptions={isRequired ? mappedMetadataTypes : nullableMappedMetadataTypes}
                          description={t(`admin:webhooks.ccs.archiveMapping.fields.${field}Description`)}
                          onChange={(val) => {
                            if (val.value === '@NONE') {
                              handleChangeArchiveValue(field, null);
                            } else {
                              handleChangeArchiveValue(field, val.value);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ))}
          </div>
        </div>

        {/* Document Type Settings Section */}
        <div className={s.section}>
          <div
            className={s.sectionHeader}
            style={{ cursor: 'pointer', marginBottom: 0 }}
            onClick={() => setIsDoctypeOpen(!isDoctypeOpen)}
          >
            <div className={s.headerIcon} style={{ transform: 'scale(0.4)' }} />
            <div className={s.headerText}>
              <h3>{t('admin:webhooks.ccs.docTypeConfig.title')}</h3>
              <span>{t('admin:webhooks.ccs.docTypeConfig.description')}</span>
            </div>
            {isDoctypeOpen && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showDialog(
                    <AdminMultiSelectDialog
                      description={t('admin:webhooks.ccs.docTypeConfig.addDescription')}
                      handleCheckTypes={handleCheckTypes}
                      detailedList={inboxDocTypes?.map((dt) => ({ id: dt.id, name: dt.name })) ?? []}
                      title={t('admin:webhooks.ccs.docTypeConfig.addTitle')}
                      selectedTypes={activeDoctypes ?? []}
                    />,
                  );
                }}
                className={se.top_button}
              >
                + {t('admin:webhooks.ccs.docTypeConfig.add')}
              </button>
            )}
            <div className={s.headerArrow}>
              <ChevronDownIcon style={isDoctypeOpen ? { transform: 'rotate(180deg)' } : {}} />
            </div>
          </div>

          <div
            className={clsx(s.content, { [s.content__visible]: isDoctypeOpen })}
            style={{
              width: '100%',
              gap: 10,
              display: isDoctypeOpen ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            <div className={se.field_type_header} style={{ marginTop: 10 }}>
              <span>{t('admin:webhooks.ccs.docTypeConfig.docType')}</span>
              <span
                style={{
                  width: '100%',
                  maxWidth: 245,
                  marginRight: 0,
                  marginLeft: 'auto',
                  textAlign: 'center',
                }}
              >
                {t('admin:webhooks.ccs.docTypeConfig.agendaReason')}
              </span>
              <span style={{ width: '25%', maxWidth: 220, marginLeft: 45 }}>
                {t('admin:webhooks.ccs.docTypeConfig.agendaCreate')}
              </span>
            </div>
            {data.doctype_settings &&
              Object.entries(data.doctype_settings).map(([k, v]) => {
                return (
                  <AdminCCSDoctypeRow
                    metadataTypes={mappedMetadataTypes}
                    inboxDocTypes={inboxDocTypes}
                    key={k}
                    data={{ docType: k, data: v }}
                    handleDelete={() => {
                      const dts = { ...data.doctype_settings };
                      delete dts[k];
                      onChange({
                        ...data,
                        doctype_settings: dts,
                      });
                    }}
                    onChange={(change) => {
                      onChange({
                        ...data,
                        doctype_settings: {
                          ...data.doctype_settings,
                          [k]: change,
                        },
                      });
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCCSWebhookEdit;
