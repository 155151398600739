import { ActiveEntityPair, DocumentMetadata } from '@shared/models/document';
import { UrlParams } from '@shared/models/generic';
import { deleteDocumentEntity, labelerSlice, setSortedDocumentEntities } from '@shared/store/labelerSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler.module.scss';
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import TopBarProgress from 'react-topbar-progress-indicator';
import DocumentLabelerSide from './sidebar/DocumentLabelerSide';
import DocumentLabelerView from './viewer/DocumentLabelerView';

TopBarProgress.config({
  barThickness: 2,
  barColors: {
    '0': '#0037ff',
    '1.0': '#00AAFF',
  },
});
interface Props {
  handleNext: VoidFunction;
  handlePrevious: VoidFunction;
}

const DocumentLabeler: React.FC<Props> = ({ handleNext, handlePrevious }) => {
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const metadataTypes = useSelector((state) => state.settings.metadataTypes);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);
  const { inboxId }: UrlParams = useParams();
  const dispatch = useDispatch();

  const handleDelete = (entityPair: ActiveEntityPair, event?: React.MouseEvent) => {
    if (event) event.stopPropagation();
    dispatch(labelerSlice.actions.setActiveEntityPair(null));
    dispatch(deleteDocumentEntity(inboxId, entityPair));
  };

  useEffect(() => {
    if (docTypeSettings && activeDocument) {
      const list: DocumentMetadata[] = [];
      const settings = docTypeSettings.find((dts) => dts.docTypeId === activeDocument.docTypeId)?.settings;
      if (!settings || !settings.metadataKeys) return;
      const metadataKeys = settings.metadataKeys;
      const mappedKeys = metadataTypes.filter((md) => {
        return metadataKeys.find((key) => key.id === md.id) || !md.isArchived;
      });

      mappedKeys.forEach((mappedKey) => {
        const mappedObj = { type: mappedKey, value: { value: '', source: '' } };
        activeDocument.metadata?.provider.forEach((e) => {
          let metaObj;

          if (typeof e.value !== 'object') {
            metaObj = { type: e.type, value: { value: e.value ?? '', source: '' } };
          } else {
            metaObj = cloneDeep(e);
            if (!metaObj.value.value) metaObj.value.value = '';
          }
          if (metaObj.type === mappedKey.id && metaObj.value != null) {
            mappedObj.value = metaObj.value;
          }
        });
        if (!(mappedObj.value.value === '' && mappedKey.isArchived)) {
          list.push(mappedObj);
        }
      });
      dispatch(
        labelerSlice.actions.setDocumentMetadata(
          list.filter((v, i, a) => a.findIndex((t) => t.type === v.type) === i),
        ),
      );
    }
  }, [dispatch, activeDocument, docTypeSettings, metadataTypes]);

  useEffect(() => {
    if (activeDocument) {
      document.title = `${activeDocument.name} | Paperbox`;
    }
    return () => {
      document.title = 'Home | Paperbox';
    };
  }, [dispatch, activeDocument]);

  useEffect(() => {
    return () => {
      dispatch(setSortedDocumentEntities([]));
      dispatch(labelerSlice.actions.setDocumentMetadata([]));
    };
  }, [dispatch]);

  return (
    <div className={s.grid}>
      <DocumentLabelerSide handleNext={handleNext} handlePrevious={handlePrevious} />

      <div className={s.main}>
        <DocumentLabelerView
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          documentDetails={activeDocument}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export default DocumentLabeler;
