import { Page } from '../../models/document';

export interface IRawPage {
  page_no: number;
  bundle_page_no: number;
  width: number;
  height: number;
  archived: boolean;
}

export function rawPageToClient(raw: IRawPage): Page {
  return {
    pageNo: raw.page_no,
    bundlePageNo: raw.bundle_page_no,
    width: raw.width,
    height: raw.height,
    archived: raw.archived,
  };
}

export function clientPageToRaw(page: Page): IRawPage {
  return {
    page_no: page.pageNo,
    bundle_page_no: page.bundlePageNo,
    width: page.width,
    height: page.height,
    archived: page.archived,
  };
}
