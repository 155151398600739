import {IClientFieldType} from '@shared/helpers/converters/fieldtype.ts';
import useOutsideClick from '@shared/hooks/useOutsideClick';
import s from '@shared/styles/component/document/document-labeler-grid-panel.module.scss';
import {ReactComponent as LinkIcon} from '@svg/link-icon.svg';
import clsx from 'clsx';
import React, {useRef, useState} from 'react';
import {TableTypeOptions} from './DocumentLabelerTablePanel.tsx';

interface Props {
  tableTypeOptions: TableTypeOptions;
  selectedType?: IClientFieldType;
  selectedTypes: string[];
  onChange: (selectedType: IClientFieldType) => void;
}

const DocumentLabelerTableTypeSelector: React.FC<Props> = ({
  tableTypeOptions,
  selectedType,
  selectedTypes,
  onChange,
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (type: IClientFieldType) => {
    setIsOpen(false);
    onChange(type);
  };

  return (
    <div className={s.selector_wrapper}>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={s.selector_selected}
      >
        {selectedType?.name || '...'}
      </button>
      <div ref={ref} className={clsx(s.selectors, { [s.selectors__active]: isOpen })}>
        {Object.entries(tableTypeOptions)
          .sort((_, b) => {
            if (b[0] === selectedType?.id) return 1;
            return -1;
          })
          .map(([k, v]) => {
            const exists = selectedTypes.includes(k);
            return (
              <button
                key={k}
                onClick={() => handleClick(v.type)}
                className={clsx(
                  s.selector,
                  { [s.selector__mandatory]: v.mandatory },
                  { [s.selector__current]: v.type === selectedType },
                  { [s.selector__picked]: exists }
                )}
              >
                <span> {v.type.name}</span>
                <div className={s.icon_mandatory}>{' *'}</div>

                <div className={s.icon}>
                  <LinkIcon />
                </div>
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default DocumentLabelerTableTypeSelector;
