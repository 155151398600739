import RadioButton from '@components/shared/radio-button/RadioButton';
import { extendedSearch, globalFuseOptions } from '@shared/helpers/helpers';
import { DocumentCount } from '@shared/models/inbox';
import documentSlice from '@shared/store/documentSlice';
import { useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler-sidebar.module.scss';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const fuseOptions = {
  ...globalFuseOptions,
  keys: [
    'id',
    {
      name: 'subTypes.name',
      weight: 0.1,
    },
  ],
};

const DocumentLabelerSidebarDelete: React.FC = () => {
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const selectedDocTypeOption = useSelector((state) => state.document.selectedDocTypeOption);
  const documentTypes = useSelector((state) => state.inbox.documentCounts);
  const dispatch = useDispatch();

  const [typeOptions, setTypeOptions] = useState([]);
  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const subRef = useRef();
  const { t } = useTranslation();

  const fuse = new Fuse(fuseData, fuseOptions);

  useEffect(() => {
    if (documentTypes) {
      let options = documentTypes
        .filter((e) => !e.isPrivate)
        .map((type) => {
          return { ...type, score: 0 };
        });
      if (activeDocument?.alternativeClassificationResults) {
        options = options.map((t) => {
          const suggestion = activeDocument.alternativeClassificationResults.find(
            (ce) => ce.docTypeId === t.id,
          );
          if (suggestion) {
            return { ...t, score: suggestion.confidence };
          }
          return { ...t };
        });
      }
      options.sort((a, b) => {
        return b.score - a.score;
      });
      setTypeOptions(options);
    }
  }, [documentTypes, activeDocument]);

  useEffect(() => {
    if (typeOptions) {
      setFuseData(typeOptions);
      setSearchResults(typeOptions);
    }
  }, [typeOptions]);

  useEffect(() => {
    return () => {
      dispatch(documentSlice.actions.setSelectedDocTypeOption(null));
    };
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMatches = useCallback(
    throttle((value, fuse) => setSearchResults(extendedSearch(value, fuse, 'id')), 500),
    [],
  );

  const handleSearchInput = (value) => {
    if (value === '') {
      setSearchResults(typeOptions);
    } else {
      handleMatches(value, fuse);
    }
  };
  const handleSelect = (id, subTypeId) => {
    if (id === null) {
      dispatch(
        documentSlice.actions.setSelectedDocTypeOption({
          docTypeId: selectedDocTypeOption?.docTypeId,
          subTypeId: subTypeId,
        }),
      );
    } else if (selectedDocTypeOption?.docTypeId !== id) {
      dispatch(
        documentSlice.actions.setSelectedDocTypeOption({
          docTypeId: id,
          subTypeId: subTypeId,
        }),
      );
    } else {
      dispatch(documentSlice.actions.setSelectedDocTypeOption(null));
    }
  };

  useEffect(() => {
    if (subRef.current) {
      const element = subRef.current as HTMLDivElement;
      const parent = element.parentElement as HTMLDivElement;
      parent.scrollTo({
        top: element.offsetTop - parent.offsetTop - 40,
        behavior: 'smooth',
      });
    }
  }, [subRef, selectedDocTypeOption]);

  return (
    <div className={clsx(s.fields)}>
      <div className={s.delete_section}>
        <h3 className={s.title}>{t('document:deleteSuggestion')}</h3>
        <p className={s.description}>{t('document:deleteSuggestionDescription')}</p>
        <input
          onChange={(e) => handleSearchInput(e.target.value)}
          placeholder={'Search DocType'}
          className={s.search}
          type="text"
        />
        <TransitionGroup className={s.options}>
          {searchResults?.map((opt) => {
            const active = opt.id === selectedDocTypeOption?.docTypeId;
            let subTypes: DocumentCount[] = [];
            if (active) {
              const type = documentTypes.find((e) => e.id === opt.id);
              subTypes = type.subTypes;
            }

            return (
              <CSSTransition key={opt.id} timeout={400} classNames="option">
                <>
                  <button
                    className={clsx(s.option, { [s.active]: active })}
                    onClick={() => {
                      const type = documentTypes.find((e) => e.id === opt.id);
                      const subTypes = type.subTypes;
                      handleSelect(opt.id, subTypes && subTypes.length > 0 ? subTypes[0].id : null);
                    }}
                    key={opt.id}
                  >
                    <RadioButton
                      checked={active}
                      onClick={() => {
                        const type = documentTypes.find((e) => e.id === opt.id);
                        const subTypes = type.subTypes;
                        handleSelect(opt.id, subTypes && subTypes.length > 0 ? subTypes[0].id : null);
                      }}
                    />
                    <span> {opt.name}</span>
                  </button>
                  {active && subTypes.length > 0 && (
                    <div ref={subRef} className={s.sub_list} style={{ maxHeight: 300 }}>
                      {subTypes.map((subType) => {
                        const subTypeActive = subType.id === selectedDocTypeOption?.subTypeId;
                        return (
                          <button
                            className={clsx(s.option, { [s.active]: subTypeActive })}
                            onClick={() => handleSelect(null, subType.id)}
                            key={subType.id}
                          >
                            <RadioButton
                              checked={subTypeActive}
                              onClick={() => handleSelect(null, subType.id)}
                            />
                            <span> {subType.name}</span>
                          </button>
                        );
                      })}
                    </div>
                  )}
                </>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default DocumentLabelerSidebarDelete;
