import React, { useEffect } from 'react';

const AuthM365Admin: React.FC = () => {
  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const state = hashParams.get('state');

    if (state) {
      const parts = state.split('|');
      if (parts.length > 1 && parts[1]) {
        const targetSubdomainUrl = new URL(parts[1]);
        targetSubdomainUrl.search = window.location.search;
        targetSubdomainUrl.hash = window.location.hash;
        window.location.replace(targetSubdomainUrl.toString());
      }
    }
  }, []);

  return null;
};

export default AuthM365Admin;
