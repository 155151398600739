import AdminMultiSelectDialog from '@components/admin/components/AdminMultiSelectDialog.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog';
import ScaleLoader from '@components/shared/loader/ScaleLoader.tsx';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { statusToIcon } from '@shared/helpers/HelperComponents.tsx';
import { useModal } from '@shared/hooks/useModal';
import { ActiveEntityPair } from '@shared/models/document';
import { UrlParams } from '@shared/models/generic';
import { deleteDocumentEntities, deleteDocumentEntity, labelerSlice } from '@shared/store/labelerSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import sf from '@shared/styles/component/document/document-labeler-sidebar-footer.module.scss';
import { ReactComponent as ChevronDown } from '@svg/chevron-down.svg';
import { ReactComponent as EditIcon } from '@svg/edit-icon.svg';
import { ReactComponent as ImportIcon } from '@svg/import.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import s from './document-labeler-side.module.scss';

import DocumentLabelerMetadataRow from './DocumentLabelerMetadataRow.tsx';
import DocumentLabelerSideButtons from './DocumentLabelerSideButtons';
import DocumentLabelerSideChecks from './DocumentLabelerSideChecks';
import DocumentLabelerSidebar from './DocumentLabelerSidebar';
import { DragSidebarRow } from './DocumentLabelerSidebarRow';
import Search from './search/Search';
import SearchResultCard from './search/SearchResultCard.tsx';

interface Props {
  handlePrevious: () => void;
  handleNext: () => void;
}

const DocumentLabelerSide: React.FC<Props> = ({ handleNext, handlePrevious }) => {
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const documentMetadata = useSelector((state) => state.labeler.documentMetadata);
  const approvalCheckTypes = useSelector((state) => state.settings.approvalCheckTypes);
  const masterDataResults = useSelector((state) => state.document.masterDataResults);
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const masterDataMappings = useSelector((state) => state.inbox.masterDataMappings);
  const { labelingMode } = useSelector((state) => state.inbox.currentInbox.settings);

  const dispatch = useDispatch();
  const location = useLocation();
  const { inboxId }: UrlParams = useParams();
  const { t } = useTranslation();
  const { showDialog } = useModal();
  const historical = location.pathname.includes('historical');

  const hasMasterdataLink = useMemo(() => {
    // If masterdata link is null or empty, return false
    return !!activeDocument?.metadata?.table?.tableId;
  }, [activeDocument]);

  const handleDelete = (entityPair: ActiveEntityPair, event?: React.MouseEvent) => {
    if (historical) return;
    if (event) event.stopPropagation();
    dispatch(labelerSlice.actions.setActiveEntityPair(null));
    dispatch(deleteDocumentEntity(inboxId, entityPair));
  };
  const checkNameMapping = useCallback(
    (check: any) => {
      const checkMapItem = approvalCheckTypes.find((e) => e.id === check.id);
      if (checkMapItem) return { name: checkMapItem.name, description: checkMapItem.description };
      const name = t(`document:approvalChecks.${check.id}` as any, { defaultValue: check.name || '' });
      const description = t(`document:approvalChecks.${check.id}Description` as any, {
        defaultValue: check.description,
      });
      return { name, description };
    },
    [t, approvalCheckTypes],
  );

  const handleDeleteAll = (e) => {
    if (historical) return;
    e.preventDefault();
    e.stopPropagation();
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          dispatch(labelerSlice.actions.setActiveEntityPair(null));
          dispatch(deleteDocumentEntities(inboxId));
        }}
        text={t('document:deletion.fields')}
      />,
    );
  };

  const mappedApprovalChecks = useMemo(() => {
    if (!activeDocument?.approvalChecks) return [];
    return activeDocument?.approvalChecks
      ?.map((check) => {
        const { name, description } = checkNameMapping(check);
        return { name, description, status: check.status, id: check.id };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [checkNameMapping, activeDocument?.approvalChecks]);

  const mappedInitialApprovalChecks = useMemo(() => {
    if (!activeDocument?.initialApprovalChecks) return [];
    return activeDocument?.initialApprovalChecks
      ?.map((check) => {
        const { name, description } = checkNameMapping(check);
        return { name, description, status: check.status, id: check.id };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [checkNameMapping, activeDocument?.initialApprovalChecks]);

  const filteredMetadata = useMemo(() => {
    if (documentMetadata && documentMetadata.length > 0) {
      return documentMetadata
        .filter((v) => !v.type.isHidden && v.value.value !== '')
        .sort((a, b) => b.type.name.localeCompare(a.type.name));
    }
    return [];
  }, [documentMetadata]);

  const [isMetadataOpen, setIsMetadataOpen] = useState(false);
  const [isHistoricalInfoOpen, setIsHistoricalInfoOpen] = useState(true);
  const [isFieldsOpen, setIsFieldsOpen] = useState(false);
  const [isChecksOpen, setIsChecksOpen] = useState(true);
  const [droppedEntity, setDroppedEntity] = useState(null);
  const [draggingEntity, setDraggingEntity] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedMasterdataTables, setSelectedMasterdataTables] = useState<string[]>([]);

  useEffect(() => {
    if (documentEntities) setIsFieldsOpen(documentEntities.length !== 0);
  }, [documentEntities]);

  const approvalFailCounts = useMemo(() => {
    const warning = mappedApprovalChecks?.filter((e) => e.status === 'warning')?.length ?? 0;
    const failed = mappedApprovalChecks?.filter((e) => e.status === 'failed')?.length ?? 0;

    return { warning, failed };
  }, [mappedApprovalChecks]);

  const masterdataTableOptions = useMemo(() => {
    if (masterDataMappings == null || Object.keys(masterDataMappings).length === 0) return [];
    setSelectedMasterdataTables(Object.entries(masterDataMappings).map(([k]) => k));
    return Object.entries(masterDataMappings).map(([k, v]) => ({ name: v.name, id: k }));
  }, [masterDataMappings]);

  const handleOpenMasterdataModal = () => {
    showDialog(
      <AdminMultiSelectDialog
        detailedList={masterdataTableOptions}
        selectedTypes={selectedMasterdataTables?.map((e) => ({ id: e }))}
        handleCheckTypes={(val) => setSelectedMasterdataTables(val.map((e) => e.id))}
        description={t('document:tableSelect.description')}
        title={t('document:tableSelect.title')}
      />,
    );
  };
  const activeTableText = useMemo(() => {
    if (masterdataTableOptions.length === 1) {
      return null;
    }

    if (selectedMasterdataTables.length === 1 && masterDataMappings) {
      return `${t('document:masterdata.table')} : ${masterDataMappings[selectedMasterdataTables[0]].name}`;
    }
    if (selectedMasterdataTables.length > 1 && masterDataMappings) {
      if (selectedMasterdataTables.length === masterdataTableOptions.length) {
        return `${t('document:masterdata.allTables')}`;
      }
      return `${t('document:masterdata.tables', { value: selectedMasterdataTables.length })}`;
    }
    return 'No table selected';
  }, [masterDataMappings, masterdataTableOptions, selectedMasterdataTables, t]);

  const activeTableLinkText = useMemo(() => {
    const tableId = activeDocument?.metadata?.table?.tableId;
    if (tableId != null && masterDataMappings && masterDataMappings[tableId]) {
      return `${t('document:masterdata.table')} : ${masterDataMappings[tableId]?.name}`;
    }
  }, [activeDocument?.metadata?.table?.tableId, masterDataMappings, t]);

  const hasExternalTable = useMemo(() => {
    if (masterDataMappings) return Object.values(masterDataMappings)?.some((el) => el.type === 'external');
    return false;
  }, [masterDataMappings]);

  useEffect(() => {
    if (
      masterDataMappings &&
      !hasExternalTable &&
      !activeDocument?.docTypeId?.includes('@PB') &&
      !labelingMode
    ) {
      dispatch(labelerSlice.actions.setActiveMode('search'));
      dispatch(labelerSlice.actions.setActiveTool('default'));
    } else {
      dispatch(labelerSlice.actions.setActiveMode('label'));
    }
  }, [activeDocument?.docTypeId, dispatch, hasExternalTable, labelingMode, masterDataMappings]);

  const isMasterDataSearchActive = useSelector((state) => state.document.isMasterDataSearchActive);

  const masterdataStyle = useMemo(() => {
    if (!isMasterDataSearchActive) return { flexShrink: 0 };
    if (!masterDataResults || masterDataResults.length === 0) return { flexGrow: 0, flexShrink: 0 };
    return {};
  }, [isMasterDataSearchActive, masterDataResults]);

  if (activeDocument?.processed === false) {
    return (
      <aside className={clsx(s.container, s.container__processing)}>
        Processing... <ScaleLoader />
      </aside>
    );
  }
  return (
    <DndContext
      onDragStart={(e) => {
        setIsDragging(true);
        setDraggingEntity(e.active.data.current.entity);
      }}
      onDragEnd={(e) => {
        if (e.active?.data && e.over) {
          setDroppedEntity(e.active.data.current.entity);
        } else {
          setDroppedEntity(null);
        }
        setDraggingEntity(null);

        setIsDragging(false);
      }}
    >
      <aside className={s.container}>
        <div className={s.sections}>
          {masterDataMappings &&
            (!historical || hasMasterdataLink) &&
            !activeDocument?.docTypeId?.includes('@PB') &&
            !labelingMode &&
            (!hasExternalTable || hasMasterdataLink) && (
              <div className={clsx(s.section, s.section__open)} style={masterdataStyle}>
                <div className={s.section_header}>
                  <span>Masterdata</span>
                  {!hasExternalTable && activeTableText && !hasMasterdataLink && (
                    <>
                      <div onClick={handleOpenMasterdataModal} className={s.section_info}>
                        <div className={clsx(s.section_count, s.section_count__large)}>{activeTableText}</div>
                      </div>
                      <div onClick={handleOpenMasterdataModal} className={s.section_edit}>
                        <EditIcon style={{ width: 14 }} />
                      </div>
                    </>
                  )}
                  {hasMasterdataLink && (
                    <>
                      <div className={s.section_info}>
                        <div className={clsx(s.section_count, s.section_count__large)}>
                          {activeTableLinkText}
                        </div>
                      </div>
                      <div className={s.section_edit}>
                        <ImportIcon style={{ height: 14 }} />
                      </div>
                    </>
                  )}
                </div>
                {!historical && (
                  <>
                    <Search
                      selectedTableIds={selectedMasterdataTables}
                      hasMasterdataLink={hasMasterdataLink}
                      isDragging={isDragging}
                      droppedItem={droppedEntity}
                      clearDroppedItem={() => setDroppedEntity(null)}
                    />
                    {masterDataResults?.length > 0 && (
                      <div className={s.section_body}>
                        <div className={s.cards}>
                          {masterDataResults?.map((result) => {
                            return <SearchResultCard key={result.id} result={result} />;
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

          {(!masterDataResults || masterDataResults.length === 0) && (
            <div className={clsx(s.section, { [s.section__open]: isFieldsOpen })}>
              <div onClick={() => setIsFieldsOpen(!isFieldsOpen)} className={s.section_header}>
                <span>{t('document:fields')}</span>
                <div className={s.section_info}>
                  {documentEntities?.length > 0 && !hasMasterdataLink && !historical && (
                    <button
                      data-testid={'delete-all-fields'}
                      onClick={handleDeleteAll}
                      className={clsx(s.section_count, s.section_count__delete)}
                    >
                      <TrashIcon />
                    </button>
                  )}

                  <div className={s.section_count}>{documentEntities?.length ?? 0}</div>
                </div>
                <div className={s.section_collapse}>
                  <ChevronDown />
                </div>
              </div>
              <div className={s.section_body}>
                <DocumentLabelerSidebar handleFieldDelete={handleDelete} />
              </div>
            </div>
          )}
          {(!masterDataResults || masterDataResults.length === 0) && filteredMetadata.length > 0 && (
            <div
              data-testid={'metadata-section'}
              // style={{ flexGrow: 1, flexShrink: 2 }}
              className={clsx(s.section, { [s.section__open]: isMetadataOpen })}
            >
              <div onClick={() => setIsMetadataOpen(!isMetadataOpen)} className={s.section_header}>
                <span>Metadata</span>
                <div className={s.section_info}>
                  <div data-testid={'section-count'} className={s.section_count}>
                    {filteredMetadata?.length ?? 0}
                  </div>
                </div>
                <div className={s.section_collapse}>
                  <ChevronDown />
                </div>
              </div>
              <div className={s.section_body}>
                {filteredMetadata.map((md) => {
                  return <DocumentLabelerMetadataRow key={md.type.id + md.value.value} metadata={md} />;
                })}
              </div>
            </div>
          )}
          {(!masterDataResults || masterDataResults.length === 0) && !historical && (
            <div
              style={{ marginBottom: 0, marginTop: 'auto' }}
              // style={{ marginBottom: 0, marginTop: 'auto', flexGrow: 2 }}
              className={clsx(s.section, {
                [s.section__open]: isChecksOpen,
              })}
            >
              <div onClick={() => setIsChecksOpen(!isChecksOpen)} className={s.section_header}>
                <span>Checklist</span>
                <div className={s.section_info}>
                  {approvalFailCounts.warning > 0 && (
                    <div className={clsx(s.section_count, s.section_count__warning)}>
                      {approvalFailCounts.warning}
                    </div>
                  )}
                  {approvalFailCounts.failed > 0 && (
                    <div className={clsx(s.section_count, s.section_count__failed)}>
                      {approvalFailCounts.failed}
                    </div>
                  )}
                </div>
                <div className={s.section_collapse}>
                  <ChevronDown />
                </div>
              </div>
              <div className={s.section_body}>
                <DocumentLabelerSideChecks checks={mappedApprovalChecks} />
              </div>
            </div>
          )}
          {historical && activeDocument && (
            <>
              <div
                style={{ marginBottom: 0, marginTop: 'auto' }}
                data-testid={'historical-section'}
                className={clsx(s.section, {
                  [s.section__open]: isChecksOpen,
                })}
              >
                <div onClick={() => setIsChecksOpen(!isChecksOpen)} className={s.section_header}>
                  <span>Initial Checks</span>
                  <div className={s.section_collapse}>
                    <ChevronDown />
                  </div>
                </div>
                <div className={s.section_body}>
                  <div className={sf.checks_rows}>
                    {mappedInitialApprovalChecks.map((check) => {
                      return (
                        <div
                          key={check.id}
                          className={sf.checks_row_group}
                          data-testid={'sidebar-check-classification'}
                        >
                          <div className={clsx(sf.checks_row)}>
                            <span>{check.name}</span>
                            <div className={sf.checks_icon}>{statusToIcon(check.status)}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                style={{ marginBottom: 0 }}
                data-testid={'historical-section'}
                className={clsx(s.section, {
                  [s.section__open]: isHistoricalInfoOpen,
                })}
              >
                <div
                  onClick={() => setIsHistoricalInfoOpen(!isHistoricalInfoOpen)}
                  className={s.section_header}
                >
                  <span>Document Info</span>

                  <div className={s.section_collapse}>
                    <ChevronDown />
                  </div>
                </div>
                <div className={s.section_body}>
                  <DocumentLabelerMetadataRow
                    key={'paperboxId'}
                    metadata={{
                      type: { name: 'Paperbox ID', id: 'paperboxId', isSensitive: false },
                      value: { value: activeDocument?.id },
                    }}
                  />
                  <DocumentLabelerMetadataRow
                    key={'providerDocumentId'}
                    metadata={{
                      type: { name: 'Provider ID', id: 'providerDocumentId', isSensitive: false },
                      value: { value: activeDocument?.providerDocumentId },
                    }}
                  />

                  <DocumentLabelerMetadataRow
                    key={'uploadTime'}
                    metadata={{
                      type: { name: t('document:archive.uploadTime'), id: 'uploadTime', isSensitive: false },
                      value: { value: activeDocument?.uploadTime.toLocaleString() },
                    }}
                  />
                  {activeDocument?.availableTime && (
                    <DocumentLabelerMetadataRow
                      key={'availableTime'}
                      metadata={{
                        type: {
                          name: t('document:archive.availableTime'),
                          id: 'availableTime',
                          isSensitive: false,
                        },
                        value: { value: activeDocument?.availableTime.toLocaleString() },
                      }}
                    />
                  )}

                  <DocumentLabelerMetadataRow
                    key={'timestamp'}
                    metadata={{
                      type: { name: t('document:archive.processedOn'), id: 'timestamp', isSensitive: false },
                      value: { value: activeDocument?.action.timestamp.toLocaleString() },
                    }}
                  />
                  <DocumentLabelerMetadataRow
                    key={'actor'}
                    metadata={{
                      type: { name: 'Processed By', id: 'actor', isSensitive: false },
                      value: { value: activeDocument?.action.actorEmail },
                    }}
                  />
                  <DocumentLabelerMetadataRow
                    key={'action'}
                    metadata={{
                      type: { name: 'Action', id: 'action', isSensitive: false },
                      value: { value: t(`document:actions.${activeDocument?.action.type}`) },
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className={s.footer}>
          <DocumentLabelerSideButtons handleNext={handleNext} handlePrevious={handlePrevious} />
        </div>
      </aside>
      {createPortal(
        <DragOverlay dropAnimation={null}>
          <DragSidebarRow entityTypes={entityTypes} draggingEntity={draggingEntity} />
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};

export default DocumentLabelerSide;
