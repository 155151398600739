import { SearchResult } from '@shared/models/document';
import { useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/sidebar/search.module.scss';
import clsx from 'clsx';
import React, { useCallback, useMemo, useRef } from 'react';

interface Props {
  result: SearchResult;
}

const SearchResultCardSimple: React.FC<Props> = ({ result }) => {
  const entityTypes = useSelector((state) => state.admin.inboxEntityTypes);
  const metadataTypes = useSelector((state) => state.admin.inboxMetadataTypes);
  const rowsRef = useRef();

  const fieldTypeName = useCallback(
    (field) => {
      if (field.mapping?.referenceType) {
        if (field.mapping.referenceType.includes('metadata')) {
          const mdResult = metadataTypes.find((e) => e.id === field.mapping.reference);
          if (mdResult) return mdResult.name;
        } else {
          const etResult = entityTypes.find((e) => e.id === field.mapping.reference);
          if (etResult) return etResult.name;
        }
      } else {
        if (field.mapping?.displayName) return field.mapping?.displayName;
      }
      return field.name;
    },
    [entityTypes, metadataTypes],
  );

  const sortedResults = useMemo(() => {
    const original = result.fields;
    const filtered = original.filter((e) => e.value);

    let sortedMapped = filtered.sort((a, b) => {
      return (
        Number(b.match) - Number(a.match) ||
        Number(b?.mapping?.isPinned || false) - Number(a?.mapping?.isPinned || false) ||
        fieldTypeName(a).localeCompare(fieldTypeName(b))
      );
    });
    sortedMapped = sortedMapped.filter((e) => {
      const mapped = metadataTypes.find((m) => m.id === e.mapping?.reference);
      return !mapped?.isHidden;
    });

    return [...sortedMapped].filter(
      (field) => field.mapping.displayName != null || field.mapping.reference != null,
    );
  }, [fieldTypeName, metadataTypes, result]);

  const style = useMemo(() => {
    const el = rowsRef.current as HTMLDivElement;
    if (el) {
      let totalHeight = 0;
      const childArr = Array.from(el.children);
      childArr.forEach((child) => {
        totalHeight += child.clientHeight + 2;
      });
      return { maxHeight: totalHeight };
    }
    return {};
  }, []);

  return (
    <div className={s.card} data-testid={'masterdata-result-card'}>
      {result.index?.tableName && <div className={s.header}>{result.index.tableName}</div>}
      <div ref={rowsRef} style={style} className={clsx(s.rows)}>
        {sortedResults.map((field) => {
          return (
            <div
              key={field.value + field.name}
              className={clsx(
                s.row,
                { [s.row__matched]: field.match },
                { [s.row__field]: field.mapping?.referenceType },
              )}
            >
              <div className={s.info}>
                <span className={s.value}>
                  {field.highlight !== null ? (
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    <div dangerouslySetInnerHTML={{ __html: field.highlight }} />
                  ) : (
                    field.value
                  )}
                </span>
                <span className={s.type}>{fieldTypeName(field)}</span>
              </div>
              {field.mapping?.referenceType && <div className={s.tag} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchResultCardSimple;
