import React, { ReactElement } from 'react';
import s from '../button/button.module.scss';

interface Props {
  iconElement?: ReactElement;
  disabled?: boolean;
}

const IconButton: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = (
  { iconElement, className, onClick, disabled },
  rest
) => {
  return (
    <button
      className={`${className} ${s.button} ${disabled ? s.disabled : ''} `}
      onClick={!disabled ? onClick : null}
      {...rest}
    >
      {iconElement && React.cloneElement(iconElement, { className: '' })}
    </button>
  );
};

export default IconButton;
