import Modal from '@components/shared/modal/Modal.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { DocumentClassification } from '@shared/models/document.ts';
import { useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/document/document.module.scss';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { ScatterChart } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
import React from 'react';

import * as echarts from 'echarts/core';
interface Props {
  currentDocTypeId: string;
  classification: DocumentClassification;
}

const DocumentClassificationModal: React.FC<Props> = ({ classification }) => {
  const documentCounts = useSelector((state) => state.inbox.documentCounts);
  const { closeModal } = useModal();
  return (
    <Modal>
      <div className={s.classification_container}>
        <div className={s.classification_header}>
          <h2 className={s.title}>Similarity of Different Documents by Type</h2>
          <CrossIcon onClick={closeModal} className={s.close} />
        </div>
        <div className={s.classification_content}>
          <ChartComp results={classification.results ?? []} documentCounts={documentCounts} />
        </div>
      </div>
    </Modal>
  );
};

export default DocumentClassificationModal;

echarts.use([ScatterChart, GridComponent, TitleComponent, TooltipComponent, CanvasRenderer, SVGRenderer]);

export const ChartComp = ({ results, documentCounts }) => {
  if (!results) return null;

  // Sort document types by confidence (highest to lowest)
  const sortedDocTypes = [...results]
    .sort((a, b) => a.confidence - b.confidence)
    .map((result) => ({
      docTypeId: result.docTypeId,
      docSubtypeId: result.docSubtypeId,
      name: result.docSubtypeId ? `${result.docTypeId} (${result.docSubtypeId})` : result.docTypeId,
      confidence: result.confidence,
      documents: result.documents as Record<string, any>,
    }));

  // Helper function to get color based on similarity value (red to green)
  function getColorFromValue(value) {
    // Clamp value between 0 and 1
    const clampedValue = Math.max(0, Math.min(1, value));

    // Define our three colors (red -> orange -> green)
    const colors = [
      { r: 255, g: 87, b: 34 }, // Red
      { r: 252, g: 191, b: 25 }, // Orange
      { r: 76, g: 175, b: 80 }, // Green
    ];
    // Return solid red if <= 0.3
    if (clampedValue <= 0.3) {
      return `rgba(${colors[0].r},${colors[0].g},${colors[0].b},0.4)`;
    }

    // Return solid green if >= 0.9
    if (clampedValue >= 0.9) {
      return `rgba(${colors[2].r},${colors[2].g},${colors[2].b},0.4)`;
    }

    // Normalize the value to 0-1 range between 0.3 and 0.9
    const normalizedValue = (clampedValue - 0.3) / (0.9 - 0.3);

    // First half: red to orange, Second half: orange to green
    const isFirstHalf = normalizedValue <= 0.5;
    const adjustedValue = isFirstHalf ? normalizedValue * 2 : (normalizedValue - 0.5) * 2;

    const startColor = isFirstHalf ? colors[0] : colors[1];
    const endColor = isFirstHalf ? colors[1] : colors[2];

    // Interpolate between the appropriate colors
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * adjustedValue);
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * adjustedValue);
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * adjustedValue);

    return `rgba(${r},${g},${b},0.4)`;
  }
  // Prepare scatter data
  const scatterData = sortedDocTypes.flatMap((dt, index) =>
    Object.entries(dt.documents).map(([_, { similarity }]) => ({
      name: dt.name,
      value: [similarity, index],
      itemStyle: {
        color: getColorFromValue(similarity),
      },
    })),
  );

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        const name = documentCounts.find((e) => e.id === params.data.name)?.name;
        return `
        <b>Document type:</b> ${name ?? params.data.name}<br>
        <b>Similarity:</b> ${(params.data.value[0] * 100).toFixed(2)}%
        <br/>
<!--        <i style="font-style: italic">Click to open</i>-->
      `;
      },
    },
    grid: {
      top: 0,
      left: 0,
      right: 20,
      bottom: 10,
      containLabel: true,
    },
    yAxis: {
      type: 'category',
      name: 'Document Type',
      data: sortedDocTypes.map((e) => e.name),
      axisLine: { lineStyle: { color: '#ccc' } },
      axisLabel: {
        color: '#333',
        fontSize: 12,
        lineHeight: 18,
        formatter: (value) => {
          const name = documentCounts.find((e) => e.id === value)?.name;
          const confidence = sortedDocTypes.find((e) => e.name === value)?.confidence;

          return name ? `${name} \n {percentage|${(confidence * 100).toFixed(1)}%}` : value;
        },
        rich: {
          percentage: {
            fontWeight: 'bold', // Make percentage bold
            fontSize: 11, // Increase font size for percentage
            color: '#000', // Optional: Make percentage black
          },
        },
      },
    },
    xAxis: {
      type: 'value',
      min: 0,
      max: 1,
      axisLine: { lineStyle: { color: '#ccc' } },
      axisLabel: {
        color: '#333',
        fontSize: 12,
        formatter: (value) => {
          return `${value * 100}%`;
        },
      },
    },
    series: [
      {
        type: 'scatter',
        data: scatterData,
        symbolSize: 15,
      },
    ],
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      style={{ width: '100%', height: sortedDocTypes.length * 60 }}
    />
  );
};
