import Button from '@components/shared/button/Button';
import { DropdownOption } from '@components/shared/dropdown/StyledSelect';
import IconButton from '@components/shared/icon-button/IconButton';
import Modal from '@components/shared/modal/Modal';
import { useModal } from '@shared/hooks/useModal';
import { FileState } from '@shared/models/files';
import { postDocument } from '@shared/store/inboxSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/inbox/inbox-modal.module.scss';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as InboxIcon } from '@svg/inbox-icon-old.svg';
import { ReactComponent as PlusLargeIcon } from '@svg/plus-large-icon.svg';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FileRow from './FileRow';

interface Props {
  inboxId: string;
}

const FileUploadModal: React.FC<Props> = ({ inboxId }) => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [files, setFiles] = useState<File[]>([]);
  const [draggingFiles, setDraggingFiles] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const documentCounts = useSelector((state) => state.inbox.documentCounts);
  const tagTypes = useSelector((state) => state.settings.tagTypes);
  const { activeTagId, docTypeId } = useSelector((state) => state.inbox.documentListOptions);

  const [hasRun, setHasRun] = useState(false);
  const [uploadStep, setUploadStep] = useState(0);
  const dropRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setUploadStep(0);
      setFiles([]);
    };
  }, []);

  const [docTypeOptions, setDocTypeOptions] = useState<DropdownOption[]>(null);
  const [fileStates, setFileStates] = useState<FileState[]>([]);
  const [globalFileState, setGlobalFileState] = useState<any>({
    file: { name: `Change All (${fileStates.length})` },
    docTypeId: 'auto-detect',
  });

  const getSubTypeOptions = (docTypeId: string) => {
    let subTypes: DropdownOption[] = [];
    const docType = documentCounts.find((e) => !e.isArchived && !e.isPrivate && e.id === docTypeId);
    if (docType) {
      subTypes = docType.subTypes
        .filter((st) => !st.isArchived && !st.isPrivate)
        .map((subType) => {
          const option: DropdownOption = { value: subType.id, label: subType.name };
          return option;
        });
    }

    return subTypes;
  };
  const tagOptions = useMemo(() => {
    if (tagTypes) {
      const mappedTypes = tagTypes.filter((st) => !st.isArchived);
      if (mappedTypes.length > 0) return mappedTypes;
    }
  }, [tagTypes]);

  useEffect(() => {
    if (fileStates.length === 0) {
      setGlobalFileState({
        file: { name: `Change All (${fileStates.length})` },
        docTypeId: 'auto-detect',
      });
    } else {
      setGlobalFileState((prevState) => {
        return { ...prevState, file: { name: `Change All (${fileStates.length})` } } as any;
      });
    }
  }, [fileStates]);

  useEffect(() => {
    if (documentCounts?.length > 0 && !hasRun) {
      const options: DropdownOption[] = [];
      options.push({
        label: t('home:fileUpload.autoDetect'),
        value: 'auto-detect',
      });
      documentCounts
        .filter((e) => !e.isArchived && !e.isPrivate)
        .filter((e) => e.topologyType !== 'document' && e.topologyType !== 'mail')
        .forEach((documentCount) => {
          const option: DropdownOption = { value: null, label: null };
          option.label = documentCount.name;
          option.value = documentCount.id;
          options.push(option);
        });
      setDocTypeOptions(options);
      setHasRun(true);
    }
  }, [documentCounts, hasRun, t]);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleReset = () => {
    setFiles([]);
    setFileStates([]);
    setUploadStep(0);
  };

  const handleConfirm = async () => {
    setUploadStep(1);
    setFileStates((prevState) => {
      const state = [...prevState];
      state.map((item) => {
        return { ...item, progress: 0 };
      });
      return state;
    });
    for (const index in fileStates) {
      const fileType = fileStates[index];
      await dispatch(
        postDocument(
          inboxId,

          {
            payload: fileType.file,
          },
          (progressEvent) => {
            setFileStates((prevState) => {
              const state = [...prevState];
              const index = state.findIndex((prev) => prev.file === fileType.file);
              state[index].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              return state;
            });
          },
          fileType.docTypeId === 'auto-detect' ? null : fileType.docTypeId,
          fileType.subTypeId === 'auto-detect' ? null : fileType.subTypeId ?? null,
          fileType.tagId ?? null
        )
      )
        .then()
        .catch((err: AxiosResponse) => {
          let error;
          if (err.status === 400) {
            error = 'Invalid/Corrupt file';
          } else {
            error = 'Something went wrong';
          }
          setUploadStep(2);
          setFileStates((prevState) => {
            const state = [...prevState];
            const index = state.findIndex((prev) => prev.file === fileType.file);
            state[index].progress = 100;
            state[index].error = error;
            return state;
          });
        });
    }
    if (fileStates.filter((item) => item.error !== null).length === 0) {
      setUploadStep(2);
    } else {
      setUploadStep(2);
    }
  };

  const handleDelete = (file: File) => {
    setFileStates((prevState) => {
      return prevState.filter((stateFileType) => stateFileType.file !== file);
    });
    setFiles((prevState) => {
      return prevState.filter((stateFile) => stateFile !== file);
    });
  };

  const handleDeleteAll = () => {
    setFileStates([]);
    setFiles([]);
  };

  const handleFileSelect = (e) => {
    if (e.target.files) {
      let mixedFiles = [...files, ...e.target.files];
      mixedFiles = mixedFiles.filter(
        (file, index, self) => self.findIndex((t) => t.name === file.name) === index
      );
      setFiles([...mixedFiles]);
    }
  };
  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDraggingFiles(false);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        let mixedFiles: File[] = [...files, ...e.dataTransfer.files];
        mixedFiles = mixedFiles.filter(
          (file, index, self) => self.findIndex((t) => t.name === file.name) === index
        );
        setFiles([...mixedFiles]);
        e.dataTransfer.clearData();
      }
    },
    [files]
  );

  const handleDragIn = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragCounter(dragCounter + 1);

        setDraggingFiles(true);
      }
    },
    [dragCounter]
  );

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragCounter(dragCounter - 1);
      if (dragCounter > 1) return;
      setDraggingFiles(false);
    },
    [dragCounter]
  );

  useEffect(() => {
    const dropZone = dropRef.current;
    dropZone.addEventListener('dragenter', handleDragIn);
    dropZone.addEventListener('dragleave', handleDragOut);
    dropZone.addEventListener('dragover', handleDrag);
    dropZone.addEventListener('drop', handleDrop);
    return () => {
      dropZone.removeEventListener('dragenter', handleDragIn);
      dropZone.removeEventListener('dragleave', handleDragOut);
      dropZone.removeEventListener('dragover', handleDrag);
      dropZone.removeEventListener('drop', handleDrop);
    };
  }, [handleDragIn, handleDragOut, handleDrop]);

  useEffect(() => {
    setFileStates((prevState) => {
      const activeDocType = docTypeId && docTypeId !== '' ? (docTypeId as string) : null;
      const state = [...prevState];
      files.forEach((file) => {
        if (
          prevState.find((item) => {
            return item.file.name === file.name && item.file.size === file.size;
          })
        ) {
        } else {
          const fileType: FileState = {
            docTypeId: activeDocType ?? 'auto-detect',
            file,
            tagId: activeTagId ?? null,
          };
          state.push(fileType);
        }
      });
      return state;
    });
  }, [docTypeId, activeTagId, files]);

  const handleChange = (newState: FileState) => {
    setFileStates((prevState: FileState[]) => {
      const state = [...prevState];
      const fileIndex = prevState.findIndex((ft) => ft.file.name === newState.file.name);
      if (fileIndex !== -1) {
        state[fileIndex] = newState;
      }
      return state;
    });
  };
  const handleChangeAll = (newState: FileState) => {
    const changes: any = {};
    setGlobalFileState((prevState) => {
      const state = { ...prevState } as any;
      if (state.docTypeId !== newState.docTypeId) {
        changes.docTypeId = state.docTypeId = newState.docTypeId;
      }
      changes.subTypeId = state.subTypeId = newState.subTypeId;
      if (state.tagId !== newState.tagId) {
        changes.tagId = state.tagId = newState.tagId;
      }
      return state;
    });
    setFileStates((prevState: FileState[]) => {
      const state = [...prevState];
      state.forEach((item, i) => {
        state[i] = { ...item, ...changes };
      });

      return state;
    });
  };

  return (
    <Modal>
      <div data-testid="add-document-modal" className={s.container} ref={dropRef}>
        <div className={s.header}>
          <h2 className={s.title}>{t('home:fileUpload.title')}</h2>
          <CrossIcon data-testid="add-document-close" onClick={closeModal} className={s.close} />
        </div>
        {files.length === 0 && (
          <div
            onClick={handleClick}
            className={clsx(s.body, s.body__centered, {
              [s.body__centered__active]: draggingFiles,
            })}
          >
            <InboxIcon className={clsx(s.upload_icon, { [s.upload_icon__active]: !draggingFiles })} />

            <button
              data-testid="upload-button"
              className={clsx(s.circle_button, {
                [s.circle_button__active]: draggingFiles,
              })}
            >
              <PlusLargeIcon color={'white'} />
            </button>

            {!draggingFiles && (
              <input
                data-testid="document-upload-input"
                onChange={handleFileSelect}
                ref={inputRef}
                className={s.upload__input}
                id="new-file-button"
                type="file"
                multiple
              />
            )}
            <p className={clsx(s.body_text, { [s.body_text__active]: !draggingFiles })}>
              {t('home:fileUpload.drop')}
            </p>
            <p
              className={clsx(s.body_text, s.body_text__upload, {
                [s.body_text__active]: draggingFiles,
              })}
            >
              <Trans i18nKey={'home:fileUpload.dragDrop'} />
            </p>
          </div>
        )}
        {files.length !== 0 && (
          <div className={s.body}>
            <div className={s.table_wrapper}>
              <div className={s.table}>
                <div className={clsx(s.cell, s.cell__head, s.cell__head__start)} style={{ gridColumn: 1 }}>
                  Filename
                </div>
                <div className={clsx(s.cell, s.cell__head)} style={{ gridColumn: 2, minWidth: 200 }}>
                  Document Type
                </div>
                {fileStates.find((fs) => fs.subTypeId) && (
                  <div style={{ gridColumn: 3 }} className={clsx(s.cell, s.cell__head)}>
                    {!fileStates.find((fs) => fs.progress != null) && ' Sub Type'}
                  </div>
                )}
                {tagOptions && (
                  <div style={{ gridColumn: 4 }} className={clsx(s.cell, s.cell__head)}>
                    Tag
                  </div>
                )}

                <div
                  className={clsx(s.cell, s.cell__head, s.cell__head__end)}
                  style={{ gridColumn: 5 }}
                />
                {fileStates.length > 1 &&
                  uploadStep === 0 &&
                  fileStates.filter((item) => item.error === null).length === 0 && (
                    <FileRow
                      isGlobal
                      tagOptions={tagOptions}
                      key={'select-all'}
                      handleDelete={handleDeleteAll}
                      handleChange={handleChangeAll}
                      getSubTypeOptions={getSubTypeOptions}
                      fileState={globalFileState}
                      docTypeOptions={docTypeOptions}
                    />
                  )}

                {fileStates.map((state) => {
                  return (
                    <FileRow
                      tagOptions={tagOptions}
                      key={state.file.name}
                      handleDelete={handleDelete}
                      handleChange={handleChange}
                      getSubTypeOptions={getSubTypeOptions}
                      fileState={state}
                      docTypeOptions={docTypeOptions}
                    />
                  );
                })}
              </div>
            </div>
            <div className={s.footer}>
              {uploadStep === 2 ? (
                <Button
                  data-testid="document-upload-again"
                  onClick={handleReset}
                  className={s.footer_button}
                  text={t('home:fileUpload.uploadMore')}
                />
              ) : (
                <>
                  <Button
                    data-testid="document-upload-confirm"
                    onClick={handleConfirm}
                    className={s.footer_button}
                    disabled={fileStates.length !== files.length || uploadStep === 1}
                    text={
                      uploadStep === 1 ? t('home:fileUpload.uploading') : t('home:fileUpload.uploadBegin')
                    }
                  />

                  <input
                    data-testid="document-upload-input-bottom"
                    onChange={handleFileSelect}
                    ref={inputRef}
                    className={s.upload__input}
                    id="new-file-button"
                    type="file"
                    multiple
                  />
                  {uploadStep === 0 && (
                    <IconButton
                      onClick={handleClick}
                      className={s.footer_add}
                      iconElement={<PlusLargeIcon />}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FileUploadModal;
