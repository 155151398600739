import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export const db = getFirestore(firebaseApp);
export const realtime = getDatabase(firebaseApp);
export const api = axios.create();

axiosRetry(api, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return error?.response?.status === 429 || error?.response?.status === 503;
  },
});

api.interceptors.response.use(
  (res) => res,
  (err: AxiosError) => {
    if (err?.message === 'canceled') return;
    if (err?.message.includes('Firebase: Error (auth/network-request-failed)')) return;
    if (err?.config?.url?.includes('generate')) return;
    if (err.isAxiosError) {
      Sentry.captureException(err, {
        tags: {
          code: err.code || null,
        },
        contexts: {
          request: {
            requestUrl: err?.config?.url || null,
            message: err.message || null,
            code: err.code || null,
            statusText: err?.response?.statusText || null,
            status: err?.response?.status || null,
          },
        },
      });
    } else {
      Sentry.captureException(err);
    }

    return Promise.reject(err);
  }
);
