import React from 'react';
import { RaceBy } from '@uiball/loaders';
import clsx from 'clsx';
import PageLoader from '../page-loader/PageLoader';
import s from './suspense-loader.module.scss';
interface Props {
  name: string;
  fullPage?: boolean;
}

const SuspenseLoader: React.FC<Props> = ({ name, fullPage = false }) => {
  if (fullPage) {
    return (
      <div className={clsx(s.container, s.container__full)} data-testid={name}>
        <PageLoader />
      </div>
    );
  }
    return (
      <div className={s.container} data-testid={name}>
        <RaceBy size={100} lineWeight={5} color={'#0085FF'} />
      </div>
    );
};

export default SuspenseLoader;
