import FormBodyHeader from '@components/admin/components/form/FormBodyHeader.tsx';
import { FormHeaderNav } from '@components/admin/components/form/FormHeaderNav.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { IClientBounceFieldOption } from '@shared/helpers/converters/bouncefield.ts';
import { convertToId } from '@shared/helpers/helpers.ts';
import useChangeTracker, { ChangeSaveCallback } from '@shared/hooks/useChangeTracker.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import {
  adminSlice,
  deleteActionTypeOption,
  patchActionTypeOption,
  postActionTypeOption,
} from '@shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

const AdminInboxesBounceOptionEdit: React.FC = () => {
  const inboxBounceFields = useSelector((state) => state.admin.inboxActionTypes);
  const newTempBounceOptions = useSelector((state) => state.admin.newTempBounceOptions);

  const { inboxId, bounceFieldId, optionId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showDialog } = useModal();
  const dispatch = useDispatch();

  const activeOptions = useMemo(() => {
    if (bounceFieldId === 'new' && newTempBounceOptions) {
      return newTempBounceOptions;
    }
    if (bounceFieldId && inboxBounceFields) {
      const bounceField = inboxBounceFields.find((e) => e.id === bounceFieldId);
      if (bounceField) {
        return bounceField.options;
      }
    }
    return [];
  }, [bounceFieldId, inboxBounceFields, newTempBounceOptions]);

  const activeOption = useMemo(() => {
    return activeOptions.find((e) => e.id === optionId);
  }, [activeOptions, optionId]);

  const handleSave: ChangeSaveCallback<IClientBounceFieldOption> = async () => {
    const mappedState = { ...state };
    const rawData: any = { name: mappedState.name, provider_id: mappedState.providerId };
    if (!state.providerId || state.providerId === '') {
      rawData.provider_id = state.id;
    }

    // If there is an active ID , we are editing an existing bounceField
    if (bounceFieldId !== 'new') {
      const payload = rawData;

      if (optionId !== 'new') {
        // Patch existing option
        patchActionTypeOption(inboxId, bounceFieldId, optionId, payload).then(() => {
          navigate(`/admin/inboxes/${inboxId}/bounce-fields/${bounceFieldId}`, { state: { NO_RESET: true } });
        });
      } else {
        if (state.providerId === '') state.providerId = null;
        // Post new option
        postActionTypeOption(inboxId, bounceFieldId, payload).then(() => {
          navigate(`/admin/inboxes/${inboxId}/bounce-fields/${bounceFieldId}`, { state: { NO_RESET: true } });
        });
      }
    } else {
      const existingIndex = activeOptions?.findIndex((st) => st.id === activeOption?.id);
      const clone = [...activeOptions];

      const option = {
        id: state.id !== '' ? state.id : convertToId(state.name),
        name: state.name,
      } as IClientBounceFieldOption;
      if (state.providerId === '') state.providerId = null;
      if (existingIndex !== -1) {
        clone[existingIndex] = option;
      } else {
        clone.push(option);
      }
      dispatch(adminSlice.actions.setNewTempBounceOptions(clone));
      navigate(`/admin/inboxes/${inboxId}/bounce-fields/${bounceFieldId}`);
    }
  };

  const handleDeleteSubtype = () => {
    if (bounceFieldId === 'new') {
      dispatch(adminSlice.actions.setNewTempBounceOptions(activeOptions?.filter((st) => st.id !== optionId)));
      navigate(`/admin/inboxes/${inboxId}/bounce-fields/${bounceFieldId}`);
    } else {
      dispatch(deleteActionTypeOption(inboxId, bounceFieldId, optionId)).then(() => {
        navigate(`/admin/inboxes/${inboxId}/bounce-fields/${bounceFieldId}`);
      });
    }
  };

  const initialState = useMemo(() => {
    if (activeOption) {
      const item = { ...activeOption };
      if (item.providerId === '') {
        item.providerId = item.id;
      }
      return item;
    }
    const obj: IClientBounceFieldOption = {
      name: activeOption?.name ?? '',
      id: activeOption?.id ?? '',
      providerId: activeOption?.providerId ?? activeOption?.id ?? '',
    };
    return obj;
  }, [activeOption]);

  const handleNavBack = () => {
    const navLoc = `/admin/inboxes/${inboxId}/bounce-fields/${bounceFieldId}`;

    if (hasChanges) {
      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(navLoc, { state: { NO_RESET: true } });
          }}
          cancelAction={() => navigate(navLoc, { state: { NO_RESET: true } })}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={t('admin:unsavedChanges.description')}
        />,
      );
    } else {
      navigate(navLoc, { state: { NO_RESET: true } });
    }
  };

  const { save, saving, state, handleInput, hasChanges, error } = useChangeTracker<IClientBounceFieldOption>(
    initialState,
    handleSave,
  );

  return (
    <form onSubmit={save} className={s.form_body}>
      <FormHeaderNav onClick={handleNavBack} label={t('admin:page.backToOverview')} />
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={error ? t('admin:actionType.alreadyExists') : ''}
        title={activeOption?.name ?? t('admin:actionType.newType')}
      />
      <div className={s.sections}>
        <FormSection title={t('admin:actionType.config')}>
          <FormInputField
            required
            type={'text'}
            description={t('admin:actionType.nameDescription')}
            onChange={(e) => handleInput(e, 'name')}
            value={state?.name ?? ''}
            label={t('admin:actionType.name')}
          />
          <FormInputField
            type={'text'}
            description={t('admin:actionType.idDescription')}
            onChange={(e) => handleInput(e, 'providerId')}
            value={state?.providerId ?? ''}
            label={t('admin:actionType.id')}
          />
        </FormSection>

        <FormSection title={t('admin:actionType.dangerZone')} hidden={!activeOption}>
          <FormInputField
            label={t('admin:actionType.optionDelete')}
            description={t('admin:actionType.optionDeleteDescription')}
            type={'button'}
            buttonOptions={{
              onClick: handleDeleteSubtype,
              text: t('admin:actionType.optionDelete'),
              type: 'error',
            }}
          />
        </FormSection>
      </div>
    </form>
  );
};

export default AdminInboxesBounceOptionEdit;
