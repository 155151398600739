import { ClassificationResult, DocumentTopology, TopologyItem } from '../../models/document';
import { IRawPage, clientPageToRaw, rawPageToClient } from './page';

export interface IRawDocumentPart {
  archived?: boolean;
  content_type: string;
  doc_subtype_id?: string;
  doc_type_id: string;
  content_id: string;
  name: string;
  pages: IRawPage[];
  transformable?: boolean;
  topology_type: string;
  confidence?: number;
  alternative_classification_results?: ClassificationResult[];
  extension?: string;
  alt_extensions?: string[];
  content_checksum?: string;
  modified_content_checksum?: string;
  content_modified?: boolean;
  metadata: any;
}

export interface IRawTopology {
  name: string;
  content_type: string;
  extension?: string;
  alt_extensions?: string[];
  content_checksum?: string;
  modified_content_checksum?: string;
  content_modified?: boolean;
  content_ready?: boolean;
  // Changed parts from array to Record for Firestore
  parts: Record<string, IRawDocumentPart>;
  transformable?: boolean;
}

export function rawPartToClient(id: string, raw: IRawDocumentPart): TopologyItem {
  return {
    // ClassificationResult properties
    id: id,
    docSubtypeId: raw.doc_subtype_id,
    docTypeId: raw.doc_type_id,
    confidence: raw.confidence,
    // TopologyItem fields
    archived: raw.archived ?? false,
    pages: raw.pages.map(rawPageToClient),
    contentId: raw.content_id,
    name: raw.name,
    contentType: raw.content_type,
    transformable: raw.transformable ?? false,
    topologyType: raw.topology_type,
    alternativeClassificationResults: raw.alternative_classification_results,
    extension: raw.extension,
    altExtensions: raw.alt_extensions,
    contentChecksum: raw.content_checksum,
    modifiedContentChecksum: raw.modified_content_checksum,
    contentModified: raw.content_modified,
    metadata: raw.metadata,
  };
}

export function rawTopologyToClient(raw: IRawTopology): DocumentTopology {
  return {
    name: raw.name,
    contentType: raw.content_type,
    extension: raw.extension,
    altExtensions: raw.alt_extensions,
    parts: Object.entries(raw.parts).map(([id, part]) => rawPartToClient(id, part)),
    contentChecksum: raw.content_checksum,
    modifiedContentChecksum: raw.modified_content_checksum,
    contentModified: raw.content_modified,
    contentReady: raw.content_ready ?? true,
    transformable: raw.transformable ?? false,
  };
}

export function clientPartToRaw(client: TopologyItem): IRawDocumentPart {
  return {
    archived: client.archived,
    content_type: client.contentType,
    doc_subtype_id: client.docSubtypeId,
    doc_type_id: client.docTypeId,
    content_id: client.contentId,
    name: client.name,
    pages: client.pages.map(clientPageToRaw),
    transformable: client.transformable,
    topology_type: client.topologyType,
    confidence: client.confidence,
    alternative_classification_results: client.alternativeClassificationResults,
    extension: client.extension,
    alt_extensions: client.altExtensions,
    content_checksum: client.contentChecksum,
    modified_content_checksum: client.modifiedContentChecksum,
    content_modified: client.contentModified,
    metadata: client.metadata,
  };
}

export function clientTopologyToRaw(client: DocumentTopology): IRawTopology {
  const partsRecord: Record<string, IRawDocumentPart> = Object.keys(client.parts).reduce(
    (acc, key) => {
      acc[key] = clientPartToRaw(client.parts[key]);
      return acc;
    },
    {} as Record<string, IRawDocumentPart>,
  );
  return {
    name: client.name,
    content_type: client.contentType,
    extension: client.extension,
    alt_extensions: client.altExtensions,
    content_checksum: client.contentChecksum,
    modified_content_checksum: client.modifiedContentChecksum,
    content_modified: client.contentModified,
    content_ready: client.contentReady,
    parts: partsRecord,
    transformable: client.transformable,
  };
}
