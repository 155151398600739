import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import sa from '@shared/styles/component/admin/admin.module.scss';
import { ReactComponent as DragHandleDots } from '@svg/drag-handle-dots.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

interface Props<T extends { id: string; name: string } = { id: string; name: string }> {
  items: any[];
  detailedItems?: T[];
  handleRemoveType: (id: string) => void;
  setItems: (items: string[]) => void;
  small?: boolean;
}

const AdminSortableList: React.FC<Props> = ({ items, handleRemoveType, setItems, detailedItems, small }) => {
  const [workableItems, setWorkableItems] = useState(items);

  const mappedItems = useMemo(() => {
    if (workableItems) {
      if (detailedItems) {
        const workable = workableItems
          .map((id) => detailedItems.find((di) => di.id === id))
          .filter((e) => e != null);
        return workable;
      }
        if (workableItems.length > 0 && workableItems[0]?.id) {
          return workableItems.filter((e) => e !== null);
        }
          return [];
    }return [];
  }, [detailedItems, workableItems]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      if (!over) return;
      const oldIndex = mappedItems.findIndex((e) => e.id === active.id);
      const newIndex = mappedItems.findIndex((e) => e.id === over.id);
      const sorted = arrayMove(workableItems, oldIndex, newIndex);
      setItems(sorted);
      setWorkableItems(sorted);
    }
  };

  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    if (items) setWorkableItems(items);
  }, [items]);

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={mappedItems} strategy={rectSortingStrategy}>
        {mappedItems.map((item) => {
          return (
            <AdminSortableItem key={item?.id} item={item} handleRemoveType={handleRemoveType} small={small} />
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

export default AdminSortableList;

interface ItemProps {
  item: { id: string; name: string };
  handleRemoveType?: (id: string) => void;
  small?: boolean;
}

const AdminSortableItem: React.FC<ItemProps> = ({ item, handleRemoveType, small }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    background: isDragging ? '#f5f7fa' : '',
    zIndex: isDragging ? 100 : 10,
    width: small ? 'calc(33% - 8px)' : '100%',
    transition: transition,
  } as CSSProperties;

  return (
    <div
      data-testid="sortable-item"
      className={clsx(sr.wrapper, sr.wrapper__no_anim, { [sr.wrapper__small]: small })}
      ref={setNodeRef}
      style={style}
    >
      <div
        {...attributes}
        {...listeners}
        style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        className={sr.container}
      >
        <DragHandleDots className={sa.drag_handle} />
        <span className={sr.title}>{item?.name}</span>
      </div>
      <button
        type={'button'}
        className={clsx(sr.action, sr.trash)}
        data-testid={'sortable-item-delete'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleRemoveType(item.id);
        }}
      >
        <TrashIcon />
      </button>
    </div>
  );
};
