import Tooltip from '@components/shared/tooltip/Tooltip';
import { statusToIcon } from '@shared/helpers/HelperComponents.tsx';
import { ApprovalCheckStatus } from '@shared/models/document';
import { isMutationSelector } from '@shared/store/documentSlice.ts';
import { patchDocument, patchTopologyPart } from '@shared/store/inboxSlice.ts';
import { editDocumentEntity, labelerSlice } from '@shared/store/labelerSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler-sidebar-footer.module.scss';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

interface Props {
  checks: {
    name: string;
    description: string;
    status: ApprovalCheckStatus;
    id: string;
  }[];
  occurrencesCounter?: Record<string, { current: number; min: number; max: number }>;
}

const DocumentLabelerSideChecks: React.FC<Props> = ({ checks }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { inboxId } = useParams();
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const documentCounts = useSelector((state) => state.inbox.documentCounts);

  const isMutation = useSelector(isMutationSelector);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);

  const activeDocType = useMemo(() => {
    return docTypeSettings?.find((dt) => dt.docTypeId === activeDocument?.docTypeId);
  }, [activeDocument?.docTypeId, docTypeSettings]);

  const occurrencesCounterItems = useMemo(() => {
    if (!activeDocument) return [];

    const counter =
      activeDocType?.settings?.entityTypes.reduce(
        (acc, et) => {
          acc[et.id] = { current: 0, min: et.minOccurrences ?? 0, max: et.maxOccurrences };
          return acc;
        },
        {} as Record<string, { current: number; min: number; max: number }>,
      ) ?? {};
    console.log(counter);

    documentEntities.forEach((et) => {
      if (!counter[et.type]) {
        console.warn(`No counter found for entity type: ${et.type}`);
        return;
      }

      const currentEntityType = entityTypes.find((e) => e.id === et.type);

      if ((et.value as any).complex) {
        const complexDefinition = currentEntityType?.complexDefinition?.entityTypes;
        const hasUnmetMandatoryFields = Object.values((et.value as any).complex).some((c: any) => {
          const complexType = complexDefinition?.find((e) => e.id === c.type);
          return complexType?.mandatory && !c.value;
        });

        counter[et.type].current = hasUnmetMandatoryFields ? 0 : 1;
      } else {
        counter[et.type].current += 1;
      }
    });

    console.log(counter);

    return Object.entries(counter).map(([key, value]) => {
      const entityTypeDetails = entityTypes.find((e) => e.id === key);
      const isValid = value.current >= value.min && value.current <= (value.max ?? Number.POSITIVE_INFINITY);

      return {
        id: key,
        ...value,
        status: isValid ? 'succeeded' : 'failed',
        name: entityTypeDetails?.name,
      };
    });
  }, [documentEntities, activeDocument, activeDocType, entityTypes]);

  const classificationOccurrenceItems = useMemo(() => {
    if (!activeDocument) return [];
    const list = [];

    const docTypeDetails = docTypeSettings.find((e) => e.docTypeId === activeDocument.docTypeId);
    const mainStatus =
      activeDocument.docTypeId === '@PB_NOTYPE'
        ? 'failed'
        : activeDocument.confidence < docTypeDetails?.settings.approvalThreshold
          ? 'warning'
          : 'succeeded';

    list.push({ name: 'Bundle', status: mainStatus });

    if (activeDocument.topology?.parts) {
      activeDocument.topology.parts
        .filter((e) => !e.archived)
        .forEach((part) => {
          const partDocTypeDetails = docTypeSettings.find((e) => e.docTypeId === part.docTypeId);

          const status =
            part.docTypeId === '@PB_NOTYPE'
              ? 'failed'
              : part.confidence < partDocTypeDetails?.settings.approvalThreshold
                ? 'warning'
                : 'succeeded';

          const filteredDocTypes = documentCounts?.filter(
            (e) => e.topologyType === (part.topologyType ?? 'document'),
          );
          const validTypes = filteredDocTypes?.filter(
            (e) => e.topologyType === part.topologyType && !e.isArchived,
          );

          const finalStatus = part.docTypeId === null && validTypes?.length > 1 ? 'failed' : status;

          const activePages = part.pages.filter((e) => !e.archived);
          const firstPage = activePages[0].bundlePageNo;
          const lastPage = activePages[activePages.length - 1].bundlePageNo;
          const capitalizedName = part.topologyType.charAt(0).toUpperCase() + part.topologyType.slice(1);

          const name =
            firstPage === lastPage
              ? `${capitalizedName} (${t('document:approvalChecks.page')} ${firstPage})`
              : `${capitalizedName} (${t('document:approvalChecks.page')} ${firstPage} - ${lastPage})`;

          list.push({
            name,
            status: finalStatus,
            firstPage,
            partId: part.id,
          });
        });
    }

    // Return empty list if only bundle is present
    return list.length === 1 ? [] : list;
  }, [activeDocument, docTypeSettings, t]);

  return (
    <div className={s.checks_rows}>
      {checks.map((check) => {
        if (check.status === 'info') return null;
        const { name, description } = check;

        let list = [];
        if (check.id === 'classificationConfidence') {
          list = classificationOccurrenceItems;
        }
        if (check.id === 'entityOccurrences') {
          list = occurrencesCounterItems;
        }
        let content;

        const handleManualOverride = (partId?: string) => {
          if (check.id === 'classificationConfidence') {
            if (!partId) {
              dispatch(patchDocument(activeDocument.id, inboxId, { confidence: 1 }, isMutation));
            } else {
              dispatch(patchTopologyPart(activeDocument.id, inboxId, { confidence: 1 }, partId, isMutation));
            }
          }
          if (check.id === 'ocrConfidence') {
            activeDocument.entities.forEach((ent) => {
              if (activeDocType.settings.ocrThreshold > ent.ocrConfidence) {
                dispatch(editDocumentEntity(inboxId, ent.uuid, { ocrConfidence: 1 }));
              }
            });
          }
        };

        if (list.length > 0) {
          if (check.id === 'entityOccurrences') {
            const succeededItems = list.filter((li) => li.status === 'succeeded').length;
            const allSucceeded = succeededItems === list.length;
            const hasVisibleList = list.length > 0 && !allSucceeded;
            content = (
              <div
                key={check.id}
                className={s.checks_row_group}
                data-testid={'sidebar-check-entity-occurrences'}
              >
                <div
                  className={clsx(s.checks_row, {
                    [s.checks_row__checked]: check.status === 'succeeded',
                    [s.checks_row__head]: hasVisibleList,
                  })}
                >
                  <span>{name}</span>
                  <div className={s.checks_icon}>{statusToIcon(check.status)}</div>
                </div>
                {hasVisibleList &&
                  list.map((checkItem) => {
                    const denominator =
                      checkItem.max && checkItem.current > checkItem.max ? checkItem.max : checkItem.min;
                    if (
                      (checkItem.max && checkItem.current > checkItem.max) ||
                      (checkItem.min && checkItem.current < checkItem.min)
                    ) {
                      return (
                        <div
                          key={checkItem.name}
                          className={clsx(s.checks_row, {
                            [s.checks_row__checked]: checkItem.status === 'succeeded',
                          })}
                        >
                          <span>{checkItem.name}</span>
                          <div className={s.checks_label}>
                            {checkItem.current}/{denominator}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            );
          } else {
            const succeededItems = list.filter((li) => li.status === 'succeeded').length;
            const allSucceeded = succeededItems === list.length;
            const hasVisibleList = list.length > 0 && !allSucceeded;
            content = (
              <div key={check.id} className={s.checks_row_group} data-testid={'sidebar-check-classification'}>
                <div
                  className={clsx(s.checks_row, {
                    [s.checks_row__checked]: check.status === 'succeeded',
                    [s.checks_row__head]: hasVisibleList,
                  })}
                >
                  <span>{name}</span>
                  {check.status === 'succeeded' && (
                    <div className={s.checks_icon}>{statusToIcon(check.status)}</div>
                  )}
                  {check.status !== 'succeeded' && (
                    <div
                      className={clsx(s.checks_label, {
                        [s.checks_label__warning]: check.status === 'warning',
                      })}
                    >
                      {`${succeededItems} / ${list.length}`}
                    </div>
                  )}
                </div>
                {hasVisibleList &&
                  list.map((checkItem, i) => {
                    const success = checkItem.status === 'succeeded';
                    return (
                      <div
                        key={checkItem.name}
                        className={clsx(s.checks_row_wrapper, { [s.interactive]: !success })}
                      >
                        <div
                          className={clsx(
                            s.checks_row,
                            {
                              [s.checks_row__checked]: success,
                            },
                            { [s.selectable]: i !== 0 && !success },
                          )}
                          onClick={() => {
                            if (checkItem.firstPage)
                              dispatch(labelerSlice.actions.setActivePageNo(checkItem.firstPage));
                          }}
                        >
                          <span>{checkItem.name}</span>
                          <div className={s.checks_icon}>{statusToIcon(checkItem.status)}</div>
                        </div>
                        {checkItem.status !== 'failed' && (
                          <button
                            onClick={() => handleManualOverride(checkItem.partId)}
                            className={s.checks_button}
                            data-testid={'check-override'}
                          >
                            <CheckmarkIcon />
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          }
        } else {
          const success = check.status === 'succeeded';
          content = (
            <div key={check.id} className={s.checks_row_group} data-testid={`sidebar-check-${check.id}`}>
              <div key={check.name} className={clsx(s.checks_row_wrapper, { [s.interactive]: !success })}>
                <div
                  className={clsx(s.checks_row, {
                    [s.checks_row__checked]: check.status === 'succeeded',
                  })}
                >
                  <span>{name}</span>
                  <div className={s.checks_icon}>{statusToIcon(check.status)}</div>
                </div>
                {check.id === 'ocrConfidence' && (
                  <button
                    data-testid={'check-override'}
                    onClick={() => handleManualOverride()}
                    className={s.checks_button}
                  >
                    <CheckmarkIcon />
                  </button>
                )}
              </div>
            </div>
          );
        }
        if (description && check.status !== 'succeeded') {
          return (
            <Tooltip
              key={check.id}
              content={
                <div
                  style={{
                    width: '314px',
                    maxWidth: '314px',
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                  }}
                >
                  <p>{description}</p>
                </div>
              }
              alignment={'start'}
              position={'top'}
              lightTheme
            >
              {content}
            </Tooltip>
          );
        }
        return content;
      })}
    </div>
  );
};

export default DocumentLabelerSideChecks;
