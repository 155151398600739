import { uuid4hex } from '@shared/helpers/helpers';
import {
  Bounds,
  DocumentEntity,
  EntityTable,
  EntityTableCell,
  TaggedBounds,
  Token,
} from '@shared/models/document';
import { ChartData } from '@shared/models/inbox';
import { cloneDeep, isEqual } from 'lodash';

export type Optional<T> = T | undefined;

export function normalizeBounds(b: Bounds): Bounds {
  const normalized = Object.assign({}, b);
  if (b.x2 < b.x1) {
    const l = b.x1;
    normalized.x1 = b.x2;
    normalized.x2 = l;
  }
  if (b.y2 < b.y1) {
    const t = b.y1;
    normalized.y1 = b.y2;
    normalized.y2 = t;
  }
  return normalized;
}

export const getClippedCoordinates = (x: number, y: number, maxX: number, maxY: number) => {
  let clippedX = x;
  let clippedY = y;
  if (clippedX < 0) clippedX = 0;
  if (clippedX > maxX) clippedX = maxX;
  if (clippedY < 0) clippedY = 0;
  if (clippedY > maxY) clippedY = maxY;

  return { clippedX, clippedY };
};

export function scaleSelection(b: Bounds, scale: number): Bounds {
  const normalized = Object.assign(
    {},
    {
      x1: b.x1 * scale,
      x2: b.x2 * scale,
      y1: b.y1 * scale,
      y2: b.y2 * scale,
    },
  );
  if (b.x2 < b.x1) {
    const l = b.x1;
    normalized.x1 = b.x2;
    normalized.x2 = l;
  }
  if (b.y2 < b.y1) {
    const t = b.y1;
    normalized.y1 = b.y2;
    normalized.y2 = t;
  }

  return normalized;
}

export function doOverlap(a: Bounds, b: Bounds): boolean {
  if (a?.x1 > b?.x2 || a?.x2 < b?.x1) {
    return false;
  }
  if (a?.y2 < b?.y1 || a?.y1 > b?.y2) {
    return false;
  }
  return true;
}

export function getTokenBounds(t: Token): Bounds {
  return {
    x1: t.x,
    y1: t.y,
    x2: t.x + t.width,
    y2: t.y + t.height,
  };
}

function spanningBound(bounds: Bounds[], padding = 1): Bounds {
  // Start with a bounding box for which any bound would be
  // contained within, meaning we immediately update maxBound.
  const maxBound: Bounds = {
    x1: Number.MAX_VALUE,
    y1: Number.MAX_VALUE,
    x2: 0,
    y2: 0,
  };

  bounds.forEach((bound) => {
    maxBound.y2 = Math.max(bound.y2, maxBound.y2);
    maxBound.y1 = Math.min(bound.y1, maxBound.y1);
    maxBound.x1 = Math.min(bound.x1, maxBound.x1);
    maxBound.x2 = Math.max(bound.x2, maxBound.x2);
  });

  maxBound.y1 = maxBound.y1 - padding;
  maxBound.x1 = maxBound.x1 - padding;
  maxBound.x2 = maxBound.x2 + padding;
  maxBound.y2 = maxBound.y2 + padding;

  return maxBound;
}

export const getTableEntityFromSelection = (tokens: Token[], selection: Bounds, activePageNo: number) => {
  const overlappingTokens = getOverlappingTokens(tokens, normalizeBounds(selection)) as Token[];

  if (overlappingTokens.length < 2) return null;

  return getTableEntityFromBounds(
    overlappingTokens,
    activePageNo,
    normalizeBounds(selection),
  ) as DocumentEntity;
};

export function getOverlappingTokens(tokens: Token[], selection: Bounds) {
  if (Math.abs(selection.x2 - selection.x1) < 1 && Math.abs(selection.y2 - selection.y1) < 1) {
    return [];
  }
  const tokenList: Token[] = [];

  const length = tokens.length;
  for (let i = 0; i < length; i++) {
    const tokenBound = getTokenBounds(tokens[i]);
    const doesOverlap = doOverlap(tokenBound, selection);
    if (doesOverlap) {
      tokenList.push({
        ...tokens[i],
        separator: tokens[i].separator ?? ' ',
      });
    }
  }
  return tokenList;
}

export const getTableEntityFromBounds = (
  tokens: Token[],
  activePageNo: number,
  selection: Bounds,
): DocumentEntity | Bounds[] => {
  const tableEntity: DocumentEntity = {
    uuid: uuid4hex(),
    type: 'temp',
    valueLocations: [selection],
    value: { columns: {} },
    rawValue: null,
    pageNo: activePageNo,
    isChecked: true,
  };

  const newTable: EntityTable = { columns: {}, hasHeaders: true };

  const horizontalGroups: Record<number, Token[]> = {};
  // Groups all tokens into horizontal groups
  tokens.forEach((token) => {
    let sameGroup = null;

    Object.entries(horizontalGroups).forEach(([key, value]) => {
      const first = value[0];
      if (token.y - 5 < first.y && token.y + 5 > first.y) {
        sameGroup = key;
      }
    });
    if (sameGroup) {
      horizontalGroups[sameGroup].push(token);
    } else {
      horizontalGroups[token.y] = [token];
    }
  });

  Object.values(horizontalGroups).forEach((rowItems) => {
    rowItems.sort((a, b) => {
      return a.x - b.x;
    });
  });

  const maxWidth = Object.values(horizontalGroups).reduce((a, b) => {
    const maxX = Math.max(...b.map((o) => o.x + o.width));
    const minX = Math.min(...b.map((o) => o.x));
    if (a < maxX - minX) return maxX - minX;

    return a;
  }, 0);

  Object.entries(horizontalGroups).forEach(([key, rowItems]) => {
    if (maxWidth / 5 > rowItems.at(-1).x - rowItems[0].x) {
      delete horizontalGroups[key];
    }
  });

  let rows: Bounds[] = [];
  Object.values(horizontalGroups).forEach((rowItems) => {
    const maxX = Math.max(...rowItems.map((o) => o.x + o.width));
    const minX = Math.min(...rowItems.map((o) => o.x));
    const maxY = Math.max(...rowItems.map((o) => o.y + o.height));
    const minY = Math.min(...rowItems.map((o) => o.y));
    rows.push({ x1: minX, y1: minY, y2: maxY, x2: maxX });
  });

  const lMin = Math.min(...rows.map((o) => o.x1));
  const rMax = Math.max(...rows.map((o) => o.x2));
  const tableWidth = rMax - lMin;
  rows.sort((a, b) => a.y2 - b.y2);
  rows.forEach((row, i) => {
    const prevRow = rows[i - 1];

    const rowWidth = row.x2 - row.x1;
    if (rowWidth < tableWidth / 1.4) {
      if (prevRow) {
        prevRow.y2 = row.y2;
        rows[i] = null;
        if (row.x2 > prevRow.x2) prevRow.x2 = row.x2;
      } else if (i !== 0) {
        const sliced = rows.slice(0, i).reverse();
        const notNull = sliced.find((e) => e != null);
        const notNullOriginal = rows.find((e) => e === notNull);
        if (row.x2 > notNullOriginal.x2) notNullOriginal.x2 = row.x2;

        notNullOriginal.y2 = row.y2;
        rows[i] = null;
      }
    }
    row.x2 = selection.x2;
    row.x1 = selection.x1;
  });
  rows = rows.filter((e) => e != null);

  const verticalGroups: Record<number, Token[]> = {};

  const horizontalList = Object.entries(horizontalGroups).sort(
    (a, b) => Number.parseFloat(a[0]) - Number.parseFloat(b[0]),
  );

  if (horizontalList.length > 0) {
    horizontalList[0][1].forEach((token: Token) => {
      let sameGroup = null;

      Object.entries(verticalGroups).forEach(([key, value]) => {
        const maxX = Math.max(...value.map((o) => o.x + o.width));
        const testValue = token.x - 5;

        if (testValue < maxX) {
          sameGroup = key;
        }
      });
      if (sameGroup) {
        verticalGroups[sameGroup].push(token);
      } else {
        verticalGroups[token.x] = [token];
      }
    });
  }

  const cols: Bounds[] = [];
  const verticalList = Object.entries(verticalGroups).sort(
    (a, b) => Number.parseFloat(a[0]) - Number.parseFloat(b[0]),
  );
  verticalList.forEach(([, colItems], i) => {
    const maxX = Math.max(
      ...colItems.map((o) => (i === verticalList.length - 1 ? selection.x2 : o.x + o.width - 3)),
    );
    const minX = Math.min(...colItems.map((o) => (i === 0 ? selection.x1 : o.x - 3)));
    const maxY = Math.max(...rows.map((o) => o.y2));
    const minY = i === 0 ? selection.y1 : Math.min(...rows.map((o) => o.y1));
    cols.push({ x1: minX, y1: minY, y2: maxY, x2: maxX });
  });

  cols.forEach((col, ci) => {
    const tableCells: Record<string, EntityTableCell> = {};
    const x1 = col.x1;
    const x2 = cols[ci + 1] ? cols[ci + 1].x1 : col.x2;
    const y1 = selection.y1;
    const y2 = col.y2;
    rows.forEach((row, ri) => {
      const nextCol = cols[ci + 1];
      const nextRow = rows[ri + 1];
      const x1 = col.x1;
      const x2 = nextCol ? nextCol.x1 : selection.x2;
      const y1 = ri === 0 ? selection.y1 : row.y1;
      const y2 = nextRow ? nextRow.y1 : selection.y2;

      const cellTokens = getOverlappingTokens(tokens, {
        x1: x1 + 3,
        x2: x2 - 3,
        y1: y1 + 3,
        y2: y2 - 3,
      });

      const cellText = cellTokens ? tokensToText(cellTokens) : null;
      tableCells[ri] = {
        value: cellText,
        rawValue: cellText,
        valueLocations: [{ x1, x2, y1, y2 }],
      };
    });
    newTable.columns[ci] = {
      type: 'temp',
      valueLocations: [{ x1, x2, y1, y2 }],
      cells: tableCells,
    };
  });

  tableEntity.value = newTable;
  return tableEntity;
};

export const convertTablePointsToTable = (
  colPoints: Bounds[],
  rowPoints: Bounds[],
  tableBounds: Bounds,
  tableEntity: DocumentEntity,
  tokens: Token[],
  hiddenIndexes: Record<'col' | 'row', Record<string, boolean>>,
) => {
  const workableEntity = cloneDeep(tableEntity);
  workableEntity.valueLocations = [tableBounds];
  const existingTable = workableEntity.value as EntityTable;
  const newTable: EntityTable = {
    columns: {},
    hasHeaders: existingTable?.hasHeaders,
    hiddenIndexes: hiddenIndexes,
  };

  colPoints.sort((a, b) => a.x1 - b.x1);
  const fltrColPoints = colPoints.filter((e) => {
    return !(e.x1 < tableBounds.x1 || e.x2 > tableBounds.x2);
  });

  rowPoints.sort((a, b) => a.y1 - b.y1);
  const fltrRowPoints = rowPoints.filter((e) => {
    return !(e.y1 < tableBounds.y1 || e.y2 > tableBounds.y2);
  });

  for (let ci = 0; ci < fltrColPoints.length + 1; ci++) {
    const tableCells: Record<string, EntityTableCell> = {};

    const x1 = ci === 0 ? tableBounds.x1 : fltrColPoints[ci - 1].x1;
    const x2 = ci === fltrColPoints.length ? tableBounds.x2 : fltrColPoints[ci].x1;
    const y1 = tableBounds.y1;
    const y2 = tableBounds.y2;

    for (let ri = 0; ri < fltrRowPoints.length + 1; ri++) {
      const cellX1 = x1;
      const cellX2 = x2;
      const cellY1 = ri === 0 ? tableBounds.y1 : fltrRowPoints[ri - 1].y1;
      const cellY2 = ri === fltrRowPoints.length ? tableBounds.y2 : fltrRowPoints[ri].y1;

      const cellTokens = getOverlappingTokens(tokens, {
        x1: cellX1 + 5,
        x2: cellX2 - 5,
        y1: cellY1 + 5,
        y2: cellY2 - 5,
      }) as Token[];
      const cellText = cellTokens ? tokensToText(cellTokens) : null;
      tableCells[ri] = {
        value: cellText,
        rawValue: cellText,
        valueLocations: [{ x1: cellX1, x2: cellX2, y1: cellY1, y2: cellY2 }],
      };
    }
    let type = 'temp';
    if (Object.keys(existingTable.columns).length === fltrColPoints.length + 1) {
      type = existingTable.columns[ci].type;
    } else {
      for (const c of Object.values(existingTable.columns)) {
        if (isEqual(c.valueLocations[0], { x1, x2, y1, y2 })) {
          type = c.type;
        }
      }
    }
    newTable.columns[ci] = {
      type: type,
      valueLocations: [{ x1, x2, y1, y2 }],
      cells: tableCells,
    };
  }
  workableEntity.value = newTable;
  return workableEntity;
};
export const generateMockChartData = (count: number, hasValue?: boolean) => {
  const list = [];
  Array.from(Array(count)).forEach((_, index) => {
    const date = new Date();
    date.setTime(date.getTime() - 24 * 60 * 60 * 1000 * index);
    const data = {
      i: index,
      date: date,
      counts: [],
      total: hasValue ? Math.floor(Math.random() * (10 * (index + 1))) : 0,
      overdueCount: hasValue ? Math.floor(Math.random() * (5 * (index + 1))) : 0,
      pendingCount: hasValue ? Math.floor(Math.random() * (15 * (index + 3))) : 0,
    } as ChartData;
    list.push(data);
  });
  return list;
};
export const convertTableEntityToRowCols = (
  tableEntity: DocumentEntity,
): {
  colPoints: TaggedBounds[];
  rowPoints: TaggedBounds[];
  allColPoints: TaggedBounds[];
  allRowPoints: TaggedBounds[];
  tableBounds: Bounds;
} => {
  const colPoints: TaggedBounds[] = [];
  const rowPoints: TaggedBounds[] = [];
  const allColPoints: TaggedBounds[] = [];
  const allRowPoints: TaggedBounds[] = [];
  const table = tableEntity.value as EntityTable;

  if (!table.columns)
    return { colPoints: [], rowPoints: [], allColPoints: [], allRowPoints: [], tableBounds: null };
  const colList = Object.values(table?.columns)?.map((v, i) => {
    const columnCopy = cloneDeep(v);
    const loc = columnCopy.valueLocations[0];

    const points: TaggedBounds = {
      x1: loc.x1,
      x2: loc.x1,
      y1: loc.y1,
      y2: tableEntity.valueLocations[0].y2,
      id: `x1${loc.x1}y1${loc.y1}x2${loc.x2}y2${tableEntity.valueLocations[0].y2}`,
    };
    const linePoints: TaggedBounds = {
      ...points,
      x2: loc.x2,
    };
    allColPoints.push(linePoints);
    if (i !== 0) colPoints.push(points);

    return columnCopy;
  });
  if (colList.length > 0) {
    Object.values(colList[0].cells).forEach((cell, i) => {
      const loc = cell.valueLocations[0];
      const points: TaggedBounds = {
        x1: loc.x1,
        x2: colList.at(-1).valueLocations[0].x2,
        y1: loc.y1,
        y2: loc.y1,
        id: `x1${loc.x1}y1${loc.y1}x2${colList.at(-1).valueLocations[0].x2}y2${loc.y2}`,
      };
      const linePoints: TaggedBounds = {
        ...points,
        y2: loc.y2,
      };
      allRowPoints.push(linePoints);

      if (i !== 0) rowPoints.push(points);
    });
  }
  const tableBounds = tableEntity.valueLocations[0];

  colPoints.sort((a, b) => a.x1 - b.x1);
  rowPoints.sort((a, b) => a.y1 - b.y1);
  allColPoints.sort((a, b) => a.y1 - b.y1);
  allRowPoints.sort((a, b) => a.y1 - b.y1);
  return { colPoints, rowPoints, allColPoints, allRowPoints, tableBounds };
};

export const tokensToText = (textList: Token[]) => {
  const l = textList.length;
  return textList.reduce((a, b, c) => {
    return a + b.text + (l !== c + 1 ? b.separator : '');
  }, '');
};

export function createEntityFromBounds(
  bounds: Bounds,
  text: string,
  pageNo: number,
  tempEntity?: DocumentEntity,
  isKeyValue?: boolean,
  imageUrl?: string,
) {
  let entity: DocumentEntity;
  if (isKeyValue) {
    if (tempEntity) {
      entity = cloneDeep(tempEntity);
      if (tempEntity.valueLocations) {
        entity = {
          ...entity,
          type: text,
        };
      }
    } else {
      entity = {
        uuid: uuid4hex(),
        pageNo: pageNo,
        type: text,
        isChecked: true,
      } as DocumentEntity;
    }
  } else {
    entity = {
      uuid: uuid4hex(),
      type: 'temp',
      pageNo: pageNo,
      value: imageUrl ? 'IMAGE' : text,
      rawValue: text,
      valueLocations: [bounds],
      dataURL: imageUrl,
      isChecked: true,
    } as DocumentEntity;
  }
  return entity;
}

export function createEntityFromSelection(
  selection: Bounds,
  pageNo: number,
  tokens?: Token[],
  tempEntity?: DocumentEntity,
  isKeyValue?: boolean,
  imageUrl?: string,
): Optional<DocumentEntity> {
  const tokenBounds: Bounds[] = [];
  const entityTokens: Token[] = [];
  let bounds = selection;
  if (tokens) {
    const length = tokens.length;

    for (let i = 0; i < length; i++) {
      const tokenBound = getTokenBounds(tokens[i]);
      const doesOverlap = doOverlap(tokenBound, selection);
      if (doesOverlap) {
        tokenBounds.push(tokenBound);
        entityTokens.push({
          ...tokens[i],
          separator: tokens[i].separator ?? ' ',
        });
      }
    }

    if (tokenBounds.length === 0 || entityTokens.length === 0) return null;
    bounds = spanningBound(tokenBounds);
  }
  const tokenText = tokensToText(entityTokens);
  return createEntityFromBounds(bounds, tokenText, pageNo, tempEntity, isKeyValue, imageUrl);
}
