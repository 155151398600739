import FormBodyHeader from '@components/admin/components/form/FormBodyHeader.tsx';
import { FormHeaderNav } from '@components/admin/components/form/FormHeaderNav.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { IClientTag, tagClientToRaw } from '@shared/helpers/converters/tag.ts';
import useChangeTracker, { ChangeSaveCallback } from '@shared/hooks/useChangeTracker.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { deleteTagTypes, patchTagType, postTagType } from '@shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

const AdminInboxesTagEdit: React.FC = () => {
  const { inboxId, tagId } = useParams();
  const inboxTagTypes = useSelector((state) => state.admin.inboxTagTypes);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const navigate = useNavigate();

  const activeTag = useMemo(() => {
    return inboxTagTypes?.find((id) => id.id === tagId);
  }, [tagId, inboxTagTypes]);

  const handleDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          dispatch(deleteTagTypes([tagId]));
          navigate(`/admin/inboxes/${inboxId}/tags`);
        }}
        text={t('admin:inboxes.sections.tagTypeDelete')}
      />,
    );
  };
  const handleNavBack = () => {
    if (hasChanges) {
      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(`/admin/inboxes/${inboxId}/tags`);
          }}
          cancelAction={() => navigate(`/admin/inboxes/${inboxId}/tags`)}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={t('admin:unsavedChanges.description')}
        />,
      );
    } else {
      navigate(`/admin/inboxes/${inboxId}/tags`);
    }
  };

  const handleSave: ChangeSaveCallback<IClientTag> = async () => {
    const item = tagClientToRaw(state);

    if (tagId !== 'new') {
      return patchTagType(inboxId, tagId, item).then(() => {
        navigate(`/admin/inboxes/${inboxId}/tags`);
      });
    }
    return postTagType(inboxId, item).then(() => {
      navigate(`/admin/inboxes/${inboxId}/tags`);
    });
  };

  const initialState = useMemo(() => {
    if (activeTag) {
      const item = { ...activeTag };
      if (item.providerId === '') {
        item.providerId = item.id;
      }
      return item;
    }
    const obj: IClientTag = {
      color: '#0085FF',
      name: '',
      id: '',
      isArchived: false,
    };

    return obj;
  }, [activeTag]);

  const { state, save, saving, handleInput, error, hasChanges } = useChangeTracker<IClientTag>(
    initialState,
    handleSave,
  );

  const nextField = useMemo(() => {
    if (tagId && inboxTagTypes) {
      const sorted = [...inboxTagTypes].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === tagId);
      return sorted[index + 1];
    }
    return null;
  }, [tagId, inboxTagTypes]);

  const prevField = useMemo(() => {
    if (tagId && inboxTagTypes) {
      const sorted = [...inboxTagTypes].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === tagId);
      return sorted[index - 1];
    }
    return null;
  }, [tagId, inboxTagTypes]);

  const handleNavNextField = () => {
    if (nextField) {
      navigate(`/admin/inboxes/${inboxId}/tags/${nextField.id}`);
    }
  };

  const handleNavPrevField = () => {
    if (prevField) {
      navigate(`/admin/inboxes/${inboxId}/tags/${prevField.id}`);
    }
  };

  return (
    <form onSubmit={save} className={s.form_body}>
      <FormHeaderNav
        onClick={handleNavBack}
        label={t('admin:page.backToOverview')}
        navOptions={
          tagId === 'new'
            ? {}
            : {
                prev: {
                  label: t('admin:page.previous'),
                  onClick: handleNavPrevField,
                  active: !!prevField,
                },
                next: {
                  label: t('admin:page.next'),
                  onClick: handleNavNextField,
                  active: !!nextField,
                },
              }
        }
      />
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={error ? t('admin:tagType.alreadyExists') : ''}
        title={activeTag?.name ?? t('admin:tagType.newType')}
      />
      <div className={s.sections}>
        <FormSection title={t('admin:tagType.config')}>
          <FormInputField
            type={'text'}
            description={t('admin:tagType.nameDescription')}
            label={t('admin:tagType.name')}
            required
            onChange={(e) => handleInput(e, 'name')}
            value={state?.name ?? ''}
          />
          <FormInputField
            hidden={tagId === 'new'}
            isCopyField
            type={'text'}
            description={t('admin:tagType.idDescription')}
            label={t('admin:tagType.id')}
            value={state?.id ?? ''}
          />
          <FormInputField
            type={'text'}
            description={t('admin:tagType.providerIdDescription')}
            label={t('admin:tagType.providerId')}
            onChange={(e) => handleInput(e, 'providerId')}
            value={state?.providerId ?? ''}
          />
          <FormInputField
            required
            type={'colorpicker'}
            description={t('admin:tagType.colorDescription')}
            label={t('admin:tagType.color')}
            onChange={(e) => handleInput(e, 'color')}
            value={state?.color}
          />
        </FormSection>
        <FormSection hidden={tagId === 'new'} title={t('admin:tagType.dangerZone')}>
          <FormInputField
            type={'button'}
            buttonOptions={{
              type: 'error',
              text: t('admin:tagType.deleteType'),
              onClick: handleDelete,
            }}
            label={t('admin:tagType.deleteType')}
            description={t('admin:tagType.deleteTypeDescription')}
          />
        </FormSection>
      </div>
    </form>
  );
};

export default AdminInboxesTagEdit;
