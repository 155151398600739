import { usePrevious } from '@shared/helpers/helpers.ts';
import { getActiveCountSelector, getDocumentList, inboxSlice } from '@shared/store/inboxSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/inbox/inbox-content.module.scss';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import HeaderProfile from '../../header/profile/HeaderProfile.tsx';
import InboxTable from '../table/InboxTable.tsx';
import InboxTableFooter from '../table/InboxTableFooter.tsx';
import InboxTags from '../table/components/InboxTags.tsx';
import InboxUpload from '../table/components/InboxUpload.tsx';
import usePagination from '../table/hooks/usePagination.tsx';

interface Props {}

const InboxDocuments: React.FC<Props> = () => {
  const { inboxId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentPageIndex } = usePagination();
  const typeMap = useSelector((state) => state.inbox.typeMap);
  const activeDocType = useSelector((state) => state.inbox.activeDocumentType);
  const documentListOptions = useSelector((state) => state.inbox.documentListOptions);
  const userInboxIds = useSelector((state) => state.user.userAccount.inboxes);
  const currentDocumentList = useSelector((state) => state.inbox.currentDocumentList);
  const count = useSelector(getActiveCountSelector);
  const pageCount = Math.max(1, Math.ceil(count / documentListOptions?.pageSize)) || 1;
  const prevDocListOptions = usePrevious(documentListOptions);
  const prevPageIndex = usePrevious(currentPageIndex);
  const tableColumnsState = useSelector((state) => state.inbox.currentInbox.settings.tableColumns);

  useEffect(() => {
    if (
      (userInboxIds && userInboxIds.length !== 0 && !isEqual(documentListOptions, prevDocListOptions)) ||
      currentPageIndex !== prevPageIndex
    ) {
      dispatch(inboxSlice.actions.setDocumentsLoading(true));
      dispatch(getDocumentList(inboxId));
    }
  }, [
    userInboxIds,
    dispatch,
    documentListOptions,
    inboxId,
    prevDocListOptions,
    currentPageIndex,
    prevPageIndex,
  ]);
  const enabledColumns = {
    ...tableColumnsState,
    locker: true,
    actor: false,
    actionDate: false,
    approvalChecks: true,
    actionType: false,
    initialApprovalChecks: false,
  };

  const title = useMemo(() => {
    let ti = '';
    const { docTypeId, subTypeId } = activeDocType;

    if (docTypeId !== '') {
      if (typeMap.normal.size > 0) {
        ti = `${typeMap.normal.get(docTypeId)}${
          subTypeId !== undefined ? `/${typeMap.normal.get(`${docTypeId}-${subTypeId}`)}` : ''
        }`;
      } else {
        ti = '';
      }
    } else {
      ti = t('home:allDocuments');
    }

    return ti;
  }, [activeDocType, t, typeMap.normal]);
  // const prevHistorical = usePrevious(isHistorical); // for re-render on histori

  return (
    <div className={s.container} style={{ marginBottom: 0 }}>
      <div className={s.header}>
        <h3 className={s.title} data-testid="inbox-title">
          {title}
        </h3>
        <HeaderProfile />
      </div>
      <div className={s.sub_header}>
        <InboxTags
          activeTagId={documentListOptions?.activeTagId}
          setActiveTagId={(tagId) => {
            dispatch(inboxSlice.actions.patchDocumentListOptions({ activeTagId: tagId }));
          }}
        />

        <div className={s.info}>
          <InboxUpload />
        </div>
      </div>
      <InboxTable
        enabledColumns={enabledColumns}
        documentList={currentDocumentList}
        pageCount={pageCount}
        goToDocument={(docId) => {
          let query;
          if (activeDocType.docTypeId !== '') {
            query = `${activeDocType.docTypeId}`;
            if (activeDocType.subTypeId) {
              query += `&subTypeId=${activeDocType.subTypeId}`;
            }
          }
          let path = `/inbox/${inboxId}/documents/${docId}`;
          if (query) {
            path += `?docTypeId=${query}`;
          }
          navigate(path);
        }}
      />
      <InboxTableFooter pageCount={pageCount} pageSize={documentListOptions?.pageSize} />
    </div>
  );
};

export default InboxDocuments;
