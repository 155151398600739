import AuthButton from '@components/auth/methods/AuthButton';
import { errorTextMap } from '@components/reset/PasswordReset';
import Input from '@components/shared/input/Input';
import Modal from '@components/shared/modal/Modal';
import { useModal } from '@shared/hooks/useModal';
import { auth } from '@shared/store/setup/firebase-setup';
import { useDispatch, useSelector } from '@shared/store/store';
import userSlice, { patchUserPreferences } from '@shared/store/userSlice';
import s from '@shared/styles/component/auth/auth.module.scss';
import clsx from 'clsx';
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  multiFactor,
  unlink,
} from 'firebase/auth';
import React, { useState } from 'react';
import { NavigateFunction } from 'react-router';
import AuthMFA from './AuthMFA';

interface Props {
  email: string;
  navigate: NavigateFunction;
}

const AuthPasswordModal: React.FC<Props> = ({ email, navigate }) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const isMultiFactor = useSelector((state) => state.tenant.details.config?.multiFactor);
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>();
  const [isMFAActive, setIsMFAActive] = useState(false);
  const [isAuthRunning, setIsAuthRunning] = useState(false);

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    if (!password) return;
    setIsAuthRunning(true);
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    const credential = EmailAuthProvider.credential(email, password);

    if (
      signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) ||
      signInMethods.includes(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD)
    ) {
      try {
        await unlink(auth.currentUser, EmailAuthProvider.PROVIDER_ID);
      } catch (e) {
        console.log(e);
      }
    }
    linkWithCredential(auth.currentUser, credential)
      .then(async () => {
        const multiFactorUser = multiFactor(auth.currentUser);
        if (multiFactorUser.enrolledFactors.length === 0 && isMultiFactor) {
          setIsMFAActive(true);
        } else {
          closeModal();
        }
        dispatch(userSlice.actions.setEmailPreferenceSet(true));
        dispatch(patchUserPreferences({ emailPreferenceSet: true }));
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'auth/weak-password') {
          setError('Password too weak, must contain more than 6 characters.');
        } else if (error.code === 'auth/requires-recent-login') {
          closeModal();
          auth.signOut();
          navigate(`onboard?email=${email}`);
        }
        console.log('Account linking error', error);
      });

    setIsAuthRunning(false);
  };
  const handleContinueWithoutPassword = (e) => {
    e.preventDefault();
    dispatch(userSlice.actions.setEmailPreferenceSet(true));
    dispatch(patchUserPreferences({ emailPreferenceSet: true }));
    closeModal();
  };
  return (
    <Modal dismissible={false}>
      {isMFAActive && <AuthMFA />}
      {!isMFAActive && (
        <div className={clsx(s.card, s.card__center, s.card__small)}>
          <h1 className={s.header}>Almost there</h1>
          <span className={s.sub_header}>Just one last thing</span>
          <div className={s.card_inner}>
            <Input id={'email'} disabled value={email} />
            <Input
              hasError={!!error}
              errorText={errorTextMap[error]}
              type={'password'}
              id={'password'}
              placeholder={'Password (Optional)'}
              value={password}
              setValue={(e) => setPassword(e)}
            />

            <AuthButton
              style={{ marginTop: 50 }}
              disabled={!password}
              onClick={handleConfirmPassword}
              isLoading={isAuthRunning}
            >
              Set password {isMultiFactor && '( and 2FA )'}
            </AuthButton>

            <AuthButton onClick={handleContinueWithoutPassword} isActive>
              Continue without password
            </AuthButton>

            <p className={s.disclaimer}>
              If you continue without a password, you will always be able to login by using a secure email
              link
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AuthPasswordModal;
