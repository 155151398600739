import StyledSelect, { DropdownOption } from '@components/shared/dropdown/StyledSelect';
import Modal from '@components/shared/modal/Modal';
import Toggle from '@components/shared/toggle/Toggle';
import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { useModal } from '@shared/hooks/useModal';
import { ClassificationResult } from '@shared/models/document';
import { DocTypeExtended, DocTypeSummary, DocumentCount } from '@shared/models/inbox';
import { useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-type-switch.module.scss';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as LockIcon } from '@svg/lock-icon.svg';
import { Pulsar } from '@uiball/loaders';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  handleConfirm: (docTypeSet: DocTypeExtended, changeAll?: boolean) => void;
  currentDocType: DocTypeSummary;
  suggestions?: ClassificationResult[];
  isMulti?: boolean;
  hasMutations?: boolean;
  documentTypes?: DocumentCount[];
  description?: string;
  topologyType?: string;
}

const DocumentTypeSwitchModal: React.FC<Props> = ({
  suggestions,
  currentDocType,
  handleConfirm,
  isMulti,
  hasMutations,
  documentTypes,
  topologyType = 'bundle',
  description,
}) => {
  const documentTypesFallback = useSelector((state) => state.inbox.documentCounts);

  const [docTypes] = useState(
    documentTypes ?? documentTypesFallback.filter((e) => e.topologyType === 'bundle' || !e.topologyType),
  );

  const { closeModal } = useModal();
  const { t } = useTranslation();
  const docTypeTitle = useMemo(() => {
    if (topologyType === 'bundle') {
      return t('document:typeSwitch.bundleTypeTitle');
    }
    if (topologyType === 'mail') {
      return t('document:typeSwitch.mailTypeTitle');
    }
    return t('document:typeSwitch.docTypeTitle');
  }, [topologyType, t]);

  const docType = useMemo(() => {
    if (topologyType === 'bundle') {
      return t('document:typeSwitch.bundleType');
    }
    if (topologyType === 'mail') {
      return t('document:typeSwitch.mailType');
    }
    return t('document:typeSwitch.docType');
  }, [topologyType, t]);

  const [typeOptions, setTypeOptions] = useState([]);
  const [typeValue, setTypeValue] = useState(null);
  const [subTypeValue, setSubTypeValue] = useState(null);
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const [doesApplyToAll, setDoesApplyToAll] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const handleConfirmSelection = () => {
    setIsApplying(true);
    const set: DocTypeExtended = {
      docType: { id: typeValue.value, name: typeValue.label },
    };

    if (subTypeValue && subTypeValue.value !== 'none') {
      set.subType = { id: subTypeValue.value, name: subTypeValue.label };
    }
    handleConfirm(set, doesApplyToAll);
  };

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    if (docTypes) {
      const filteredTypes = [...docTypes].filter((type) => {
        if (type.id === currentDocType?.docTypeId) {
          if (type?.subTypes.length <= 1) {
            return false;
          }
        }
        return true;
      });
      const test = docTypes.find((e) => e.id === currentDocType?.docTypeId);
      if (test?.isFixed) {
        setIsFixed(true);
        setTypeOptions([{ value: test.id, label: test.name, score: 0 }]);
        return;
      }
      setIsFixed(false);
      let options: DropdownOption[] = filteredTypes
        .filter((e) => !e.isPrivate)
        .filter((e) => !e.isArchived)
        .map((type) => {
          return { value: type.id, label: type.name, score: 0 };
        });
      if (suggestions) {
        options = options.map((t) => {
          const suggestion = suggestions.find((ce) => ce.docTypeId === t.value);
          if (suggestion) {
            return { ...t, score: suggestion.confidence };
          }
          return { ...t };
        });
      }
      options.sort((a, b) => {
        return b.score - a.score;
      });
      setTypeOptions(options);
    }
  }, [currentDocType, docTypes, suggestions]);

  useEffect(() => {
    if (typeValue) {
      const item = docTypes.find((type) => type.id === typeValue.value);
      const filteredSubTypes = [...item.subTypes]
        .filter((subType) => {
          return !(subType.id === currentDocType?.subTypeId && currentDocType?.docTypeId === item.id);
        })
        .filter((e) => !e.isPrivate)
        .filter((e) => !e.isArchived);
      let subTypes = filteredSubTypes.map((type) => {
        return { value: type.id, label: type.name, score: 0 };
      });
      if (suggestions) {
        subTypes = subTypes.map((st) => {
          const suggestion = suggestions.find((ce) => ce.docSubtypeId === st.value);
          if (suggestion) {
            return { ...st, score: suggestion.confidence };
          }
          return { ...st };
        });
        subTypes.sort((a, b) => {
          return b.score - a.score;
        });
      }
      if (subTypes.length === 0) {
        setSubTypeValue(null);
        setSubTypeOptions([]);
      } else {
        setSubTypeOptions([...subTypes]);
      }
    }
  }, [currentDocType, docTypes, suggestions, typeValue]);

  return (
    <Modal>
      <div data-testid="type-selector-modal" className={s.modal}>
        <div className={s.header}>
          <h2 className={s.title}> {docTypeTitle}</h2>
          <CrossIcon onClick={closeModal} className={s.close} />
        </div>
        <div className={s.content}>
          <div className={s.description}>
            {!isMulti && (description ? description : t('document:typeSwitch.docTypeDescription'))}
            {isMulti && (description ? description : t('document:typeSwitch.docTypeDescriptionMulti'))}
          </div>
          <div className={s.group}>
            <span className={s.label}>
              {docType}
              {isFixed && (
                <Tooltip content={t('document:typeSwitch.locked')} alignment={'start'} position={'bottom'}>
                  <LockIcon />
                </Tooltip>
              )}
            </span>
            <StyledSelect
              isLoading={!typeOptions}
              defaultValue={typeOptions[0]}
              value={typeValue}
              onChange={setTypeValue}
              options={typeOptions}
              isDisabled={isFixed}
            />
          </div>
          <div className={clsx(s.group, { [s.group__hidden]: subTypeOptions.length === 0 })}>
            <span className={s.label}> {t('document:typeSwitch.subType')}</span>
            <StyledSelect
              defaultValue={subTypeOptions[0]}
              value={subTypeValue}
              isLoading={!subTypeOptions}
              onChange={setSubTypeValue}
              options={subTypeOptions}
            />
          </div>
          {hasMutations && (
            <div className={clsx(s.group, s.group__horizontal)}>
              <span className={s.label}> {t('document:typeSwitch.changeAllButton')}</span>
              <Toggle setState={() => setDoesApplyToAll(!doesApplyToAll)} state={doesApplyToAll} />
            </div>
          )}

          <div className={s.buttons}>
            <button
              style={{ width: 120, height: 38 }}
              autoFocus
              tabIndex={0}
              data-testid="type-selector-change"
              onClick={() => handleConfirmSelection()}
              className={clsx(s.button, s.button__alt)}
              disabled={isApplying}
            >
              {isApplying ? <Pulsar size={20} color={'white'} /> : t('document:typeSwitch.changeButton')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentTypeSwitchModal;
