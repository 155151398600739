import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { sleep } from '@shared/helpers/helpers';
import { useModal } from '@shared/hooks/useModal.tsx';
import { UrlParams } from '@shared/models/generic';
import { getRawPDF, isMutationSelector } from '@shared/store/documentSlice.ts';
import { patchTopologyPart } from '@shared/store/inboxSlice.ts';
import { labelerSlice } from '@shared/store/labelerSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import { ReactComponent as DocumentIcon } from '@svg/document-icon.svg';
import { ReactComponent as DownloadIcon } from '@svg/download.svg';
import { ReactComponent as EditIcon } from '@svg/edit-icon.svg';
import { ReactComponent as ScissorIcon } from '@svg/scissors.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import useAsyncEffect from 'use-async-effect';
import DownloadModal from '../../shared/download-modal/DownloadModal.tsx';
import DocumentSplitModal from '../split/DocumentSplitModal.tsx';
import DocumentTypeSwitchModal from '../type-selector/DocumentTypeSwitchModal.tsx';
import s from './viewer/labeler-view.module.scss';

interface Props {}

const DocumentLabelerThumbs: React.FC<Props> = () => {
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);
  const pageImagesMap = useSelector((state) => state.document.pageImagesMap);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const documentCounts = useSelector((state) => state.inbox.documentCounts);
  const activePageNo = useSelector((state) => state.labeler.activePageNo);
  const isThumbsVisible = useSelector((state) => state.labeler.isThumbsVisible);
  const { documentDownload, documentTransform } = useSelector((state) => state.inbox.currentInbox.settings);
  const isMutation = useSelector(isMutationSelector);

  const { docId, inboxId }: UrlParams = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { showModal, closeModal } = useModal();
  const { t } = useTranslation();
  const historical = location.pathname.includes('historical');

  useEffect(() => {
    if (activeRef.current) {
      console.log('here', activePageNo);
      const el = activeRef.current as HTMLImageElement;
      el.scrollIntoView({ inline: 'center', behavior: 'smooth', block: 'center' });
    }
  }, [activePageNo]);

  const currentThumbs = pageImagesMap[docId];

  const [delayedActive, setDelayedActive] = useState(false);

  useAsyncEffect(async () => {
    if (isThumbsVisible) {
      sleep(300).then(() => setDelayedActive(true));
    } else {
      setDelayedActive(false);
    }
  }, [isThumbsVisible]);

  const activeRef = useRef(null);

  const handleChangeDocType = (bundleId: string, docTypeId: string, docSubTypeId?: string) => {
    const clone = cloneDeep(activeDocument);
    const bundleIndex = clone.topology.parts.findIndex((e) => e.id === bundleId);
    if (bundleIndex > -1) {
      const item = cloneDeep(clone.topology.parts.find((e) => e.id === bundleId));
      item.docTypeId = docTypeId;
      item.docSubtypeId = docSubTypeId;
      dispatch(
        patchTopologyPart(
          activeDocument.id,
          inboxId,
          { doc_type_id: docTypeId, doc_subtype_id: docSubTypeId },
          item.id,
          isMutation,
        ),
      );
    }
    closeModal();
  };
  const handleDelete = (topologyId: string) => {
    dispatch(patchTopologyPart(activeDocument.id, inboxId, { archived: true }, topologyId, isMutation));
  };

  const handleDownload = async (topologyId: string) => {
    const func = (docId: string) =>
      dispatch(getRawPDF(docId, inboxId, isMutation, topologyId)).then((res) => {
        if (res.status !== 200) return false;
        const a = document.createElement('a');
        const filename = res.headers['content-disposition']?.match(/filename=(.+)/)[1];
        a.href = window.URL.createObjectURL(res.data);
        a.download = filename;
        a.setAttribute('target', '_blank');
        a.setAttribute('id', 'temp_download_url');
        a.click();
        a.remove();
        return true;
      });
    func(activeDocument.id);
    if (activeDocument?.topology.contentReady !== false) return;
    showModal(<DownloadModal handleDownload={func} />);
  };

  const titleFontSize = (title: string) => {
    const textLength = title.length;
    const maxSize = 14;
    const minSize = 12;
    const shrinkStartLength = 12;
    const shrinkEndLength = 25;

    if (textLength <= shrinkStartLength) {
      return maxSize;
    }
    if (textLength > shrinkStartLength && textLength <= shrinkEndLength) {
      const sizeDecrementPerChar = (maxSize - minSize) / (shrinkEndLength - shrinkStartLength);
      return maxSize - (textLength - shrinkStartLength) * sizeDecrementPerChar;
    }
    return minSize;
  };

  return (
    <div data-tour={'thumbs'} className={clsx(s.page_thumbs)}>
      {activeDocument?.topology?.parts && currentThumbs ? (
        <>
          {activeDocument.topology.parts.map((group) => {
            const filteredDocTypes = documentCounts?.filter(
              (e) => e.topologyType === (group.topologyType !== undefined ? group.topologyType : 'document'),
            );

            if (group.archived) return null;

            const active = group.pages
              .filter((e) => !e.archived)
              .find((p) => p.bundlePageNo === activePageNo);
            const doctype = documentCounts?.find((e) => e.id === group.docTypeId);
            let subType;
            if (doctype?.subTypes && group.docSubtypeId !== 'NOSUBTYPE')
              subType = doctype.subTypes.find((e) => e.id === group.docSubtypeId);
            const docTypeDetails = docTypeSettings.find((dt) => dt.docTypeId === group.docTypeId);
            const validTypes = filteredDocTypes?.filter(
              (e) => e.topologyType === group.topologyType && !e.isArchived,
            );
            let isLowConfidence = false;
            let name = group.name;
            if (docTypeDetails && doctype) {
              name = doctype?.name;
              if (subType) name += ` - ${subType.name}`;
              isLowConfidence =
                group.confidence < docTypeDetails.settings.approvalThreshold || !group.confidence;
            }
            return (
              <div className={s.group} key={group.id}>
                <div
                  className={clsx(
                    s.group_header,
                    { [s.visible]: isThumbsVisible },
                    { [s.low_confidence]: isLowConfidence },
                    {
                      [s.failed]:
                        group.docTypeId === '@PB_NOTYPE' ||
                        (group.docTypeId == null && validTypes?.length > 1),
                    },
                    { [s.active]: active && isThumbsVisible && !historical },
                  )}
                >
                  <div className={s.group_wrapper}>
                    <div
                      className={s.name}
                      onClick={() => {
                        const activePages = group.pages.filter((e) => !e.archived);
                        dispatch(labelerSlice.actions.setActivePageNo(activePages[0].bundlePageNo));
                      }}
                    >
                      {!docTypeDetails && (
                        <DocumentIcon style={{ height: 12, marginBottom: 1, marginLeft: -16 }} />
                      )}
                      {name.length > 15 ? (
                        <Tooltip
                          tooltipStyle={{ maxWidth: 180, padding: 10, lineHeight: 1.2 }}
                          position={'top'}
                          content={name}
                        >
                          <span style={{ fontSize: titleFontSize(name) }}>{name}</span>
                        </Tooltip>
                      ) : (
                        <span style={{ fontSize: titleFontSize(name) }}>{name}</span>
                      )}
                    </div>
                    <div className={s.info}>
                      <div className={s.buttons}>
                        {validTypes?.length >= 1 && (
                          <Tooltip
                            tooltipStyle={{ maxWidth: 190 }}
                            position={'bottom'}
                            alignment={'center'}
                            content={
                              group.topologyType === 'mail'
                                ? t('document:thumbs.mailtype')
                                : t('document:thumbs.doctype')
                            }
                          >
                            <button
                              onClick={() => {
                                showModal(
                                  <DocumentTypeSwitchModal
                                    description={t('document:typeSwitch.bundlePartDescription')}
                                    documentTypes={filteredDocTypes}
                                    suggestions={group.alternativeClassificationResults ?? []}
                                    handleConfirm={(e) =>
                                      handleChangeDocType(group.id, e.docType.id, e.subType?.id)
                                    }
                                    currentDocType={{
                                      docTypeId: group.docTypeId,
                                      subTypeId: group.docSubtypeId,
                                    }}
                                    topologyType={group.topologyType}
                                  />,
                                );
                              }}
                            >
                              <EditIcon />
                            </button>
                          </Tooltip>
                        )}
                        {group.pages.filter((e) => !e.archived).length > 1 && documentTransform && (
                          <>
                            {group.transformable && (
                              <Tooltip
                                tooltipStyle={{ maxWidth: 190 }}
                                position={'bottom'}
                                alignment={'center'}
                                content={t('document:thumbs.split')}
                              >
                                <button
                                  onClick={() =>
                                    showModal(
                                      <DocumentSplitModal
                                        inboxId={inboxId}
                                        currentThumbs={currentThumbs}
                                        part={group}
                                        activeDocument={activeDocument}
                                      />,
                                    )
                                  }
                                >
                                  <ScissorIcon />
                                </button>
                              </Tooltip>
                            )}
                            {!group.transformable && (
                              <Tooltip
                                tooltipStyle={{ maxWidth: 190 }}
                                position={'bottom'}
                                alignment={'center'}
                                content={t('document:thumbs.splitNoSupport')}
                              >
                                <button style={{ cursor: 'not-allowed', color: 'rgba(0,0,0,0.3)' }}>
                                  <ScissorIcon />
                                </button>
                              </Tooltip>
                            )}
                          </>
                        )}

                        {documentTransform && activeDocument?.topology.transformable && (
                          <Tooltip
                            tooltipStyle={{ maxWidth: 190 }}
                            position={'bottom'}
                            alignment={'center'}
                            content={t('document:thumbs.delete')}
                          >
                            <button className={s.delete} onClick={() => handleDelete(group.id)}>
                              <TrashIcon />
                            </button>
                          </Tooltip>
                        )}

                        {documentDownload && (
                          <Tooltip
                            tooltipStyle={{ maxWidth: 190 }}
                            position={'bottom'}
                            content={t('document:thumbs.download')}
                          >
                            <button onClick={() => handleDownload(group.id)}>
                              <DownloadIcon />
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={s.group_items}>
                  {group.pages.map((page) => {
                    const img = currentThumbs[page.bundlePageNo]?.thumbUrl;
                    if (page.archived) return null;
                    return (
                      <img
                        key={page.pageNo + activeDocument.id}
                        data-hj-suppress
                        ref={activePageNo === page.bundlePageNo ? activeRef : null}
                        onClick={() => {
                          dispatch(labelerSlice.actions.setActiveEntityPair(null));
                          dispatch(labelerSlice.actions.setActivePageNo(page.bundlePageNo));
                        }}
                        className={clsx(
                          { [s.active]: activePageNo === page.bundlePageNo },
                          { [s.visible]: delayedActive && img },
                        )}
                        src={img}
                        alt=""
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={s.group_items}>
          {activeDocument?.dimensions.map((_, i) => {
            if (!currentThumbs) return;
            const img = currentThumbs[i + 1];
            if (!img) return null;
            return (
              <img
                key={i + 1 + docId}
                data-hj-suppress
                ref={activePageNo === i + 1 ? activeRef : null}
                onClick={() => {
                  dispatch(labelerSlice.actions.setActiveEntityPair(null));
                  dispatch(labelerSlice.actions.setActivePageNo(i + 1));
                }}
                className={clsx({ [s.active]: activePageNo === i + 1 }, { [s.visible]: delayedActive })}
                src={img.thumbUrl}
                onError={() => console.log('image error')}
                alt=""
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DocumentLabelerThumbs;
