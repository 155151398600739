import Checkbox from '@components/shared/checkbox/Checkbox';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog';
import {IClientInbox} from '@shared/helpers/converters/inbox.ts';
import {useModal} from '@shared/hooks/useModal';
import {UserAccount} from '@shared/models/user';
import {deleteUser, patchUser} from '@shared/store/adminSlice';
import {useDispatch, useSelector} from '@shared/store/store';
import {resetPassword} from '@shared/store/userSlice';
import s from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import {ReactComponent as CheckmarkIcon} from '@svg/checkmark-icon.svg';
import {ReactComponent as KeyIcon} from '@svg/key-icon.svg';
import {ReactComponent as ShieldIcon} from '@svg/shield.svg';
import {ReactComponent as TrashIcon} from '@svg/trash-icon.svg';
import clsx from 'clsx';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AdminUserDropdown from './AdminUserDropdown';

interface Props {
  user: UserAccount;
  inboxes: IClientInbox[];
  isSelf: boolean;
  scrollCounter: number;
}

const AdminUserRow: React.FC<Props> = ({ inboxes, user, isSelf }) => {
  const providers = useSelector((state) => state.tenant.providers);

  const dispatch = useDispatch();
  const { showDialog, closeDialog } = useModal();
  const { t } = useTranslation();
  const rowRef = useRef(null);

  const [isAdminChecked, setIsAdminChecked] = useState(user.isAdmin);
  const [selectedIds, setSelectedIds] = useState(null);
  const [resetSent, setResetSent] = useState(false);

  const isPasswordLoginEnabled = useMemo(() => {
    if (providers) {
      return !!providers.find((lp) => lp.type === 'EmailPassword');
    }
      return false;
  }, [providers]);

  const handleCheck = () => {
    if (!isSelf) {
      dispatch(patchUser(user.id, { isAdmin: !isAdminChecked }));
      setIsAdminChecked(!isAdminChecked);
    }
  };
  const handleDelete = () => {
    dispatch(deleteUser(user.id));
  };
  const handleResetPassword = () => {
    if (resetSent) return;
    setResetSent(false);

    dispatch(resetPassword(user.email)).then(() => setResetSent(true));
  };

  useEffect(() => {
    if (inboxes && user) {
      setSelectedIds(
        inboxes
          .filter((i) => {
            return user.inboxes.includes(i.id);
          })
          .map((i) => i.id)
      );
    }
  }, [inboxes, user]);

  return (
    <div data-testid={'admin-user-row'} className={clsx(s.user_row)} ref={rowRef}>
      <div className={s.name}>
        {user.email} {user.isHidden && <ShieldIcon className={s.name_icon} />}
      </div>

      <div className={s.user_row_actions}>
        <AdminUserDropdown
          userId={user.id}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          inboxes={inboxes}
        />
        <div className={s.user_row_admin}>
          <span>{t('admin:users.adminAccess')} : </span>
          <Checkbox disabled={isSelf} checked={isAdminChecked || false} onClick={handleCheck} />
        </div>
        {isPasswordLoginEnabled &&
          (resetSent ? (
            <CheckmarkIcon className={clsx(s.user_row_admin_icon, s.user_row_admin_icon__sent)} />
          ) : (
            <KeyIcon
              onClick={() => {
                showDialog(
                  <ConfirmationDialog
                    dialogType="confirmation"
                    title="Send password reset"
                    confirmText="Send"
                    cancelText={t('document:dialog.cancel')}
                    confirmAction={() => {
                      handleResetPassword();
                      closeDialog();
                    }}
                    text={t('admin:users.resetText')}
                  />
                );
              }}
              className={clsx(s.user_row_admin_icon, s.user_row_admin_icon__key)}
            />
          ))}

        <TrashIcon
          data-testid={'admin-user-row-delete'}
          className={clsx(s.user_row_admin_icon, {
            [s.user_row_admin_icon__disabled]: isSelf,
          })}
          onClick={() => {
            if (!isSelf)
              showDialog(
                <ConfirmationDialog
                  confirmAction={() => {
                    handleDelete();
                    closeDialog();
                  }}
                  text={t('admin:users.deleteText')}
                />
              );
          }}
        />
      </div>
    </div>
  );
};

export default AdminUserRow;
