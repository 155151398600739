import Modal from '@components/shared/modal/Modal.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { Pulsar } from '@uiball/loaders';
import clsx from 'clsx';
import React, { useEffect, ReactNode, useState, useMemo } from 'react';
import s from './content-modal.module.scss';

interface ContentModalProps {
  title: string;
  description?: string | ReactNode;
  children?: ReactNode;
  onClose?: () => void;
  initialFocus?: boolean;
  modalStyles?: React.CSSProperties;
  contentStyles?: React.CSSProperties;
  headerStyles?: React.CSSProperties;
  cancelButtonText?: string;
  cancelButtonAltStyle?: boolean;
  onCancel?: () => void;
  actionButtonText?: string;
  onAction?: () => Promise<any>;
  isActionDisabled?: boolean;
  isDialog?: boolean;
  type?: 'warning' | 'confirmation';
  closeOnActionFinish?: boolean;
}

const ContentModal: React.FC<ContentModalProps> = ({
  title,
  description,
  children,
  onClose,
  initialFocus = false,
  modalStyles,
  contentStyles,
  headerStyles,
  cancelButtonText = 'Cancel',
  cancelButtonAltStyle = true,
  onCancel,
  actionButtonText = 'OK',
  onAction,
  isActionDisabled = false,
  isDialog = false,
  type = 'confirmation',
  closeOnActionFinish = false,
}) => {
  const { closeModal, closeDialog } = useModal();
  const [actionLoading, setActionLoading] = useState(false);

  const closeAction = useMemo(() => {
    if (isDialog) return onClose ?? closeDialog ?? onCancel;
    return onClose ?? closeModal ?? onCancel;
  }, [onClose, closeModal, closeDialog, isDialog]);

  const cancelAction = useMemo(() => {
    if (isDialog) return onCancel ?? closeDialog;
    return onCancel ?? closeModal;
  }, [onCancel, closeModal, closeDialog, isDialog]);

  useEffect(() => {
    if (initialFocus) {
      const element = document.getElementById('close-modal-button');
      if (element) element.focus();
    }
  }, [initialFocus]);

  const handleAction = async () => {
    if (!onAction) return;
    const result = onAction();
    if (result && typeof (result as Promise<any>).then === 'function') {
      setActionLoading(true);
      try {
        await result;
      } catch (error) {
        console.error(error);
      }
      setActionLoading(false);
      if (closeOnActionFinish) {
        closeAction();
      }
    }
  };

  return (
    <Modal isDialog={isDialog}>
      <div className={s.modal} style={modalStyles}>
        <div className={s.header} style={headerStyles}>
          <h2 className={s.title}>{title}</h2>
          <CrossIcon id="close-modal-button" onClick={closeAction} className={s.close} />
        </div>
        <div className={s.content} style={contentStyles}>
          {description && <p className={s.description}>{description}</p>}
          {children}
        </div>
        {(onCancel || onAction) && (
          <div className={clsx(s.footer, { [s.footer__warning]: type === 'warning' })}>
            {onCancel && (
              <button
                disabled={actionLoading}
                onClick={cancelAction}
                className={clsx(s.button, cancelButtonAltStyle && s.button__alt)}
              >
                {cancelButtonText}
              </button>
            )}
            {onAction && (
              <button
                autoFocus
                onClick={handleAction}
                disabled={isActionDisabled || actionLoading}
                className={clsx(s.button)}
              >
                {actionLoading ? <Pulsar size={20} color="white" /> : actionButtonText}
              </button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ContentModal;
