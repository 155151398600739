import { WebhookRow } from '@shared/helpers/converters/endpoint.ts';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React from 'react';
import AdminWebhookRow from '../AdminWebhookRow.tsx';

interface WebhookListProps {
  testId: string;
  label: string;
  description: string;
  items: WebhookRow[];
  deleteRow: (index: number) => void;
  editRow: (index: number, target: 'key' | 'value' | 'valueType' | 'lock' | 'all', value: any) => void;
  addRow: () => void;
  hidden?: boolean;
}

const FormRequestField: React.FC<WebhookListProps> = ({
  label,
  description,
  deleteRow,
  editRow,
  addRow,
  items,
  hidden,
  testId,
}) => {
  if (hidden) return null;

  const handleKeyOnBlur = (rowIndex: number) => {
    const currentItem = cloneDeep(items[rowIndex]);
    const otherItems = cloneDeep(items);
    otherItems.splice(rowIndex, 1);
    const activeItems = otherItems.filter((e) => !e.markedForDelete);

    const duplicate = activeItems.find((e) => e.key === currentItem.key);
    if (duplicate) {
      currentItem.error = {
        key: 'key',
        msg: 'Item with same key already exists.',
      };
    } else {
      const childKeyItems = activeItems.filter((e) => e?.key.includes(currentItem.key));
      let hasActualChildren = false;
      childKeyItems.forEach((childItem) => {
        const childGroups = childItem.key.split(/__/g);
        if (childGroups[0] === currentItem.key) {
          hasActualChildren = true;
        }
      });

      if (hasActualChildren) {
        currentItem.error = {
          key: 'key',
          msg: 'Nested elements with this key are already present.',
        };
      } else {
        currentItem.error = null;
      }
    }

    editRow(rowIndex, 'all', currentItem);
  };

  return (
    <div className={clsx(se.item, se.item__horizontal)} data-testid={`admin-request-group-${testId}`}>
      <div className={se.item_text}>
        <h4>{label}</h4>
        <p>{description}</p>
      </div>
      <div className={se.item_action}>
        {items?.map((item, i) => {
          if (item.markedForDelete) return null;
          return (
            <AdminWebhookRow
              onBlur={() => handleKeyOnBlur(i)}
              deleteRow={deleteRow}
              editRow={editRow}
              itemIndex={i}
              item={item}
              key={item.key}
            />
          );
        })}
        <div data-testid={'webhook-row-add'} onClick={addRow} className={se.webhook_row_button}>
          <PlusIcon />
        </div>
      </div>
    </div>
  );
};
export default FormRequestField;
