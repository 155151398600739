import { auth } from '@shared/store/setup/firebase-setup';
import { RecaptchaVerifier } from 'firebase/auth';
import React, { useEffect } from 'react';

const Recaptcha: React.FC = () => {
  useEffect(() => {
    if (!window['recaptchaVerifier']) {
      window['recaptchaVerifier'] = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
      });
      window['recaptchaVerifier'].render().then((widgetId) => {
        window['recaptchaWidgetId'] = widgetId;
      });
    }
    return () => {
      window['recaptchaVerifier'] = null;
    };
  }, []);

  return <div id="recaptcha-container" />;
};

export default Recaptcha;
