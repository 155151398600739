import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Inbox } from '@shared/models/inbox';
import { Tenant, TenantConfig, TenantProvider } from '@shared/models/tenant';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { doc, getDoc } from 'firebase/firestore';
import { settingsSlice } from './settingsSlice';
import { db } from './setup/firebase-setup';
import { AppThunk } from './store';

interface TenantState {
  inboxes?: Inbox[];
  tenantId?: string;
  isTenantInvalid?: boolean;
  details?: Tenant;
  providers?: TenantProvider[];
}

const initialState: TenantState = {};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setTenantId: (state, action: PayloadAction<string>) => {
      state.tenantId = action.payload;
    },
    setIsTenantInvalid: (state, action: PayloadAction<boolean>) => {
      state.isTenantInvalid = action.payload;
    },
    setDetails: (state, action: PayloadAction<Tenant>) => {
      state.details = action.payload;
    },
    setInboxes: (state, action: PayloadAction<Inbox[]>) => {
      state.inboxes = action.payload;
    },
    setProviders: (state, action: PayloadAction<TenantProvider[]>) => {
      state.providers = action.payload;
    },
  },
});

export const getInboxes = (inboxIds: string[]): AppThunk => {
  return async (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    console.time('start');
    // Create an array of references to the inbox documents
    const refs = inboxIds.map((id) => doc(db, `tenants/${tenantId}/inboxes/${id}`));

    // Fetch all documents in parallel using Promise.all
    const docs = await Promise.all(refs.map((ref) => getDoc(ref)));

    // Process the retrieved documents
    const localInboxes = docs
      .map((res, index) => {
        const inbox = camelcaseKeys(res.data(), { deep: true }) as Inbox;
        if (inbox) {
          inbox.id = inboxIds[index];
          return inbox;
        }
        return null;
      })
      .filter((inbox) => inbox !== null) as Inbox[];

    // Dispatch the processed inboxes

    console.timeEnd('start');
    dispatch(tenantSlice.actions.setInboxes(localInboxes));
  };
};

export const getTenant = (): AppThunk => async (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const ref = doc(db, `tenants/${tenantId}`);

  getDoc(ref)
    .then((e) => {
      const data = e.data();
      if (data.settings.dashboardRange) {
        dispatch(
          settingsSlice.actions.setDashboardDayCount(
            data.settings.dashboardRange < 31 ? data.settings.dashboardRange : 31
          )
        );
      }
      dispatch(tenantSlice.actions.setDetails(camelcaseKeys(data, { deep: true })));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTenantLoginInfo = async (tenantName: string, dispatch) => {
  axios
    .get(`${import.meta.env.VITE_PAPERBOX_LOGIN_URL}/tenants/${tenantName}`, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    })
    .then(async (res) => {
      const data = await res.data;
      if (data.tenant_id) {
        dispatch(tenantSlice.actions.setProviders(data.providers));
        dispatch(
          tenantSlice.actions.setDetails({
            config: data.config as TenantConfig,
            settings: data.settings,
          })
        );
        dispatch(tenantSlice.actions.setTenantId(data.tenant_id));
      }
    })
    .catch(() => {
      dispatch(tenantSlice.actions.setIsTenantInvalid(true));
    });
};

export default tenantSlice;
