import b from '@components/shared/button/button.module.scss';
import { useFloating } from '@floating-ui/react-dom';
import { hexToRgb } from '@shared/helpers/helpers';
import useOutsideClick from '@shared/hooks/useOutsideClick';
import { ExtendedTagType } from '@shared/models/document';
import { useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document.module.scss';
import { ReactComponent as InfoIcon } from '@svg/info-icon.svg';
import { ReactComponent as TagIcon } from '@svg/tag.svg';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  activeTagId?: string;
  handleSelectTag?: (tagId: string) => void;
  tagTypes: ExtendedTagType[];
  position?: 'top' | 'bottom';
  className?: string;
  minimal?: boolean;
}

const DocumentTagDropdown: React.FC<Props> = ({
  activeTagId,
  tagTypes,
  handleSelectTag,
  position,
  className,
  minimal,
}) => {
  const { x, y, refs, reference, floating, strategy, update } = useFloating({
    strategy: 'fixed',
    placement: position ?? 'bottom',
  });
  useOutsideClick(refs.reference, () => setIsOpen(false));
  const [eleWidth, setEleWidth] = useState(100);
  const [isOpen, setIsOpen] = useState(false);
  const { labelingMode } = useSelector((state) => state.inbox.currentInbox.settings);

  const { t } = useTranslation();
  const handleSelect = (tagTypeId) => {
    handleSelectTag(tagTypeId);
    setIsOpen(false);
  };
  const mappedTagType = useMemo(() => {
    if (activeTagId && tagTypes) {
      return tagTypes.find((st) => st.id === activeTagId);
    }
  }, [activeTagId, tagTypes]);

  useEffect(() => {
    const parentEle = refs.reference.current as HTMLDivElement;
    if (isOpen !== null) {
      setEleWidth(parentEle?.clientWidth);
      update();
    }
  }, [update, refs, isOpen]);

  if (!handleSelectTag && !activeTagId) return null;
  return (
    <div ref={reference} className={clsx(s.header_button_dropdown, s.fade, className)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={
          activeTagId
            ? {
                background: mappedTagType?.color,
                color: 'white',
              }
            : {}
        }
        className={clsx({ [s.header_button]: !minimal }, { [s.header_button__minimal]: minimal })}
      >
        <TagIcon />
        {!minimal && (
          <span>{activeTagId ? (mappedTagType?.name ?? activeTagId) : t('document:addLabel')}</span>
        )}
      </button>
      {handleSelectTag && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: position === 'top' ? y - 5 : y + 5,
            left: x ?? 0,
            width: eleWidth ?? 'max-content',
          }}
          className={clsx(s.dropdown, { [s.dropdown__open]: isOpen })}
        >
          {tagTypes
            ?.filter((e) => !e.isArchived)
            .map((tagType) => {
              const rgb = hexToRgb(tagType?.color);
              return (
                <button
                  key={tagType.id}
                  onClick={() => {
                    if (tagType.isDisabled) return;
                    handleSelect(tagType.id);
                  }}
                  style={{
                    background: tagType.isDisabled ? `rgba(${rgb},0.4)` : `rgba(${rgb},1)`,
                    color: 'white',
                  }}
                  className={clsx(s.header_button, {
                    [s.header_button__disabled]: tagType.isDisabled,
                  })}
                >
                  <span>{tagType.isDisabled ? (tagType.disabledReason ?? tagType.name) : tagType.name}</span>

                  {tagType.isDisabled && <InfoIcon className={s.header_button_icon} />}
                  {tagType.isDisabled && (
                    <div className={clsx(b.hover, s.float)}>
                      <div>{t('document:labeling.invalidChecks')}</div>
                      {/*<MinOccurrenceTooltip*/}
                      {/*  minOccurrencesCounter={minOccurrencesCounter}*/}
                      {/*  entityTypes={entityTypes}*/}
                      {/*/>*/}
                    </div>
                  )}
                </button>
              );
            })}
          {!labelingMode && (
            <button
              key={'empty'}
              style={{
                background: 'rgba(49,61,79,0.8)',
                color: 'white',
              }}
              onClick={() => handleSelect(null)}
              className={clsx(s.header_button)}
            >
              <span>No Label</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentTagDropdown;
