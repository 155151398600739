// apiClient.ts
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import { getUserToken } from './helpers';

const apiClient = axios.create();
// No fixed baseURL here—pass it when making requests.

axiosRetry(apiClient, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    console.log(error);
    console.log(error?.response?.status);
    console.log(error?.response);
    return (
      error?.response?.status === 429 || error?.response?.status === 503 || error?.code === 'ERR_NETWORK'
    );
  },
});

apiClient.interceptors.request.use(async (config) => {
  const token = await getUserToken();
  if (token)
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    } as AxiosRequestHeaders;

  return config;
});

export const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }) =>
  async ({ url, method, data, params, headers }: AxiosRequestConfig) => {
    try {
      const result = await apiClient({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError: any) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default apiClient;
