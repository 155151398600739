import ContentModal from '@components/shared/content-modal/ContentModal.tsx';
import Toggle from '@components/shared/toggle/Toggle.tsx';
import { importInboxConfig } from '@shared/store/adminSlice.ts';
import s from '@shared/styles/component/admin/admin-import-modal.module.scss';
import { ReactComponent as ApprovalChecksIcon } from '@svg/admin/approval_checks.svg';
import { ReactComponent as BounceIcon } from '@svg/admin/bounce.svg';
import { ReactComponent as ConnectorIcon } from '@svg/admin/connector.svg';
import { ReactComponent as DocTypeIcon } from '@svg/admin/doctypes.svg';
import { ReactComponent as FieldsIcon } from '@svg/admin/fields.svg';
import { ReactComponent as MetadataIcon } from '@svg/admin/metadata.svg';
import { ReactComponent as SettingsIcon } from '@svg/admin/settings.svg';
import { ReactComponent as TableIcon } from '@svg/admin/table.svg';
import { ReactComponent as WebhookIcon } from '@svg/admin/webhook.svg';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  inboxId: string;
}

interface InboxImportExportState {
  doc_types: any;
  entity_types: any;
  metadata_keys: any;
  action_metadata_keys: any;
  approval_checks: any;
  tables: any;
  settings: any;
  webhooks: any;
  endpoints: any;
  connectors: any;
  metadata: any;
}

// Default initial state for toggles.
const defaultCheckboxState: Record<keyof InboxImportExportState, boolean> = {
  doc_types: true,
  entity_types: true,
  metadata_keys: true,
  action_metadata_keys: true,
  approval_checks: true,
  tables: true,
  settings: true,
  webhooks: true,
  endpoints: true,
  connectors: true,
  metadata: true,
};

const AdminImportModal: React.FC<Props> = ({ inboxId }) => {
  const { t } = useTranslation();
  const [newFile, setNewFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadStep, setUploadStep] = useState(0);
  const [parsedData, setParsedData] = useState<InboxImportExportState | null>(null);
  const [isChecked, setIsChecked] =
    useState<Record<keyof InboxImportExportState, boolean>>(defaultCheckboxState);

  // Maps for display names and icons.
  const nameMap: Record<keyof InboxImportExportState, string> = {
    doc_types: t('admin:inboxes.docTypes'),
    entity_types: t('admin:inboxes.fieldTypes'),
    metadata_keys: t('admin:inboxes.metadataTypes'),
    action_metadata_keys: t('admin:inboxes.actionTypes'),
    approval_checks: t('admin:docType.approvalChecks'),
    tables: t('admin:masterdata.name'),
    settings: t('admin:inboxes.inboxSettings'),
    webhooks: t('admin:webhooks.title'),
    endpoints: 'Komt mee met webhooks',
    connectors: t('admin:connectors.title'),
    metadata: 'altijd meesturen',
  };
  const iconMap: Record<keyof InboxImportExportState, JSX.Element> = {
    doc_types: <DocTypeIcon />,
    entity_types: <FieldsIcon />,
    metadata_keys: <MetadataIcon />,
    action_metadata_keys: <BounceIcon />,
    approval_checks: <ApprovalChecksIcon />,
    tables: <TableIcon />,
    settings: <SettingsIcon />,
    webhooks: <WebhookIcon />,
    endpoints: <WebhookIcon />,
    connectors: <ConnectorIcon />,
    metadata: <MetadataIcon />,
  };

  // Helper to extract items from parsedData for a given key.
  const getItems = useCallback((key: keyof InboxImportExportState, data: InboxImportExportState) => {
    switch (key) {
      case 'doc_types':
      case 'entity_types':
      case 'metadata_keys':
      case 'action_metadata_keys':
      case 'approval_checks':
      case 'webhooks':
      case 'connectors':
        return Object.values(data[key] || {});
      case 'tables':
        return data[key] || [];
      case 'settings':
        return null; // Special case: settings is not an array.
      case 'endpoints':
      case 'metadata':
        return null; // Not rendered.
      default:
        return [];
    }
  }, []);

  // Process file upload.
  const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setNewFile(e.target.files[0]);
      setUploadStep(1);
    }
  }, []);

  // Parse file and initialize check state.
  useEffect(() => {
    if (newFile) {
      const fileReader = new FileReader();
      fileReader.readAsText(newFile, 'UTF-8');
      fileReader.onload = (e) => {
        try {
          const info = JSON.parse(e.target?.result as string) as InboxImportExportState;
          // Clone the default state and adjust based on file content.
          const updatedCheckState: Record<keyof InboxImportExportState, boolean> = {
            ...defaultCheckboxState,
          };

          Object.keys(info).forEach((key) => {
            const k = key as keyof InboxImportExportState;
            const items = getItems(k, info);
            // If there’s nothing to import, mark as false.
            if (items && Array.isArray(items) && items.length === 0) {
              updatedCheckState[k] = false;
            }
          });
          // Handle interdependencies:
          if (!updatedCheckState.webhooks || !updatedCheckState.connectors) {
            updatedCheckState.webhooks = false;
            updatedCheckState.connectors = false;
            updatedCheckState.endpoints = false;
          }
          if (
            !updatedCheckState.tables ||
            !updatedCheckState.entity_types ||
            !updatedCheckState.metadata_keys
          ) {
            updatedCheckState.tables = false;
          }
          setIsChecked(updatedCheckState);
          setParsedData(info);
        } catch (error) {
          console.error('Error parsing file:', error);
          // Handle parse error, e.g. notify user (could be expanded)
        }
      };
    }
  }, [newFile, getItems]);

  // Trigger upload button logic.
  const handleUploadButton = useCallback(() => {
    if (newFile) {
      setUploadStep(2);
    } else if (inputRef.current) {
      inputRef.current.click();
    }
  }, [newFile]);

  // Handle toggling with dependent fields.
  const toggleCheckbox = useCallback((key: keyof InboxImportExportState) => {
    setIsChecked((prev) => {
      let newState = { ...prev, [key]: !prev[key] };

      // Synchronize interdependent keys.
      if (key === 'webhooks') {
        newState = { ...newState, connectors: newState.webhooks, endpoints: newState.webhooks };
      } else if (key === 'connectors' && !newState.connectors) {
        newState = { ...newState, webhooks: newState.connectors, endpoints: newState.connectors };
      } else if (key === 'endpoints' && !newState.endpoints) {
        newState = { ...newState, webhooks: newState.endpoints, connectors: newState.endpoints };
      }

      if (key === 'tables' && newState.tables) {
        newState = { ...newState, entity_types: newState.tables, metadata_keys: newState.tables };
      }
      if ((key === 'entity_types' || key === 'metadata_keys') && newState[key] === false) {
        newState = { ...newState, tables: false };
      }

      return newState;
    });
  }, []);

  // Filter and import selected data.
  const handleImportData = useCallback(async () => {
    if (!parsedData) return;
    const selectedKeys = Object.keys(isChecked).filter(
      (key) => isChecked[key as keyof InboxImportExportState],
    );
    const filteredPayload = Object.fromEntries(
      Object.entries(parsedData).filter(([key]) => selectedKeys.includes(key)),
    );
    return importInboxConfig(inboxId, filteredPayload);
  }, [inboxId, isChecked, parsedData]);

  const handleReset = useCallback(() => {
    setParsedData(null);
    setUploadStep(0);
    setNewFile(null);
  }, []);

  // Split render sections for clarity.
  const section1Keys: (keyof InboxImportExportState)[] = ['settings'];
  const section2Keys: (keyof InboxImportExportState)[] = [
    'doc_types',
    'entity_types',
    'metadata_keys',
    'action_metadata_keys',
    'approval_checks',
  ];
  const section3Keys: (keyof InboxImportExportState)[] = ['connectors', 'webhooks', 'tables'];

  // Render section of toggles.
  const renderSection = (keys: (keyof InboxImportExportState)[]) =>
    keys.map((k) => {
      if (!parsedData || parsedData[k] === undefined) return null;
      const items = getItems(k, parsedData);
      // Set additional title info.
      let additionalTitle = '';
      if (k === 'settings' && parsedData[k]?.settings?.name) {
        additionalTitle = `: ${parsedData[k].settings.name}`;
      } else if (items && Array.isArray(items)) {
        additionalTitle = items.length > 0 ? ` (${items.length})` : '';
      }
      const isEmpty = Array.isArray(items) && items.length === 0;
      return (
        <button
          onClick={() => toggleCheckbox(k)}
          key={k}
          className={s.row}
          style={k === 'settings' ? { gridColumn: '1 / -1' } : {}}
        >
          <div className={s.row_header}>
            <div className={s.title}>
              {iconMap[k]}
              <span>
                {nameMap[k]} {additionalTitle}
              </span>
            </div>
            <Toggle setState={() => null} state={isChecked[k]} disabled={isEmpty} />
          </div>
          {Array.isArray(items) && (
            <div className={clsx(s.list, { [s.list__faded]: !isChecked[k], [s.list__empty]: isEmpty })}>
              {isEmpty ? (
                <div>Nothing to import</div>
              ) : (
                items.map((item: any) => (
                  <span key={item.id} className={s.list_item}>
                    {item.name}
                  </span>
                ))
              )}
            </div>
          )}
        </button>
      );
    });

  return (
    <ContentModal
      modalStyles={{ width: '100%', maxWidth: '90vw', minWidth: uploadStep === 0 ? '500px' : '65vw' }}
      actionButtonText="Import Selected"
      cancelButtonText="Change File"
      onCancel={uploadStep !== 0 ? handleReset : undefined}
      onAction={uploadStep !== 0 ? handleImportData : undefined}
      title="Inbox Configuration Import"
      closeOnActionFinish
    >
      <>
        {uploadStep === 0 && (
          <button onClick={handleUploadButton} data-testid="upload-table" type="button" className={s.button}>
            Select File
          </button>
        )}

        <input
          ref={inputRef}
          onChange={handleFileSelect}
          className={s.file_input}
          accept="application/json"
          type="file"
        />

        {parsedData && (
          <>
            <div className={s.data}>{renderSection(section1Keys)}</div>
            <div className={s.data}>{renderSection(section2Keys)}</div>
            <div className={s.data}>{renderSection(section3Keys)}</div>
          </>
        )}
      </>
    </ContentModal>
  );
};

export default AdminImportModal;
